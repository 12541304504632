import { createReducer } from '@reduxjs/toolkit';
import {
  addAssetTagFailure,
  addAssetTagSuccess,
  addAssetTagsComplete,
  addAssetTagsInit,
  addAssetTagsReset,
} from './actions';

export interface UploadTagsState {
  loading: boolean;
  tagsCount: number;
  totalAssets: number;
  uploadTagsSuccesCount: string[];
  uploadStatusErrorCount: string[];
  uploadTagsComplete: boolean;
}

export const uplodTagsInitialState: UploadTagsState = {
  loading: false,
  tagsCount: 0,
  totalAssets: 0,
  uploadTagsSuccesCount: [],
  uploadStatusErrorCount: [],
  uploadTagsComplete: false,
};

export const UPLOAD_TAGS_REDUCER = createReducer(uplodTagsInitialState, (builder) =>
  builder
    .addCase(addAssetTagsInit, (state, action) => {
      state.tagsCount = action.payload.tags.length;
      state.totalAssets = action.payload.totalAssets;
      state.loading = true;
    })
    .addCase(addAssetTagSuccess, (state, action) => {
      state.uploadTagsSuccesCount = [...state.uploadTagsSuccesCount, ...action.payload.assetIds];
    })
    .addCase(addAssetTagFailure, (state, action) => {
      state.uploadStatusErrorCount.push(action.payload.assetId);
    })
    .addCase(addAssetTagsComplete, (state) => {
      state.uploadTagsComplete = true;
      state.loading = false;
    })
    .addCase(addAssetTagsReset, (state) => {
      state.totalAssets = 0;
      state.tagsCount = 0;
      state.uploadTagsSuccesCount = [];
      state.uploadStatusErrorCount = [];
      state.uploadTagsComplete = false;
    }),
);
