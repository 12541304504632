import { put, takeEvery } from 'typed-redux-saga';
import { selectAllAssetsSuccess, showMaxSelectableAssetsToast } from '../actions';

const handler = function* (action: ReturnType<typeof selectAllAssetsSuccess>) {
  if (action.payload.assetIds.length >= 1000) {
    yield put(showMaxSelectableAssetsToast());
  }
};

export function* selectAllAssetsSuccessEffects() {
  yield takeEvery(selectAllAssetsSuccess, handler);
}
