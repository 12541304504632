import { Collection } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const collectionAdapter = createEntityAdapter<Collection>();

export const collectionSlice = createSlice({
  name: 'collection',
  initialState: collectionAdapter.getInitialState(),
  reducers: {
    collectionsReceived: collectionAdapter.upsertMany,
    collectionUpdated: collectionAdapter.upsertOne,
  },
});
