import {
  EMPTY_SEARCH_PARAMS,
  EnforcedSearchParameters,
  SearchAssetsGroupBy,
  SearchParameters,
  SearchType,
} from '@integration-frontends/integration/core/model';
import { SortOptions } from '@integration-frontends/integration/core/model/entities/common/sorting';
import { createReducer } from '@reduxjs/toolkit';
import {
  assetsListBySectionInitialState,
  AssetsListBySectionState,
  createAssetsListBySectionReducer,
} from '../../common/assets-list/assets-list-by-section-state';
import {
  assetsListInitialState,
  AssetsListState,
  createAssetsListReducer,
} from '../../common/assets-list/assets-list-state';
import {
  changeGroupBy,
  changePageSize,
  clearSearch,
  baseSearch,
  init,
  initSearchFiltersSuccess,
  initSuccess,
  search,
  searchAssetsActions,
  searchError,
  searchSuccess,
  setEnforcedSearchParameters,
  toggleShowAttachments,
  clearError,
} from './actions';

const assetsListReducer = createAssetsListReducer(searchAssetsActions);
const assetsListBySectionReducer = createAssetsListBySectionReducer(searchAssetsActions);

const DEFAULT_PAGE_SIZE = 16;

export interface SearchAssetsState {
  containerId: string;
  loading: boolean;
  error: { error: unknown } | null;
  filtersLoading: boolean;
  searchParams: SearchParameters | null;
  searchType: SearchType | null;
  enforcedSearchParams: EnforcedSearchParameters | null;
  sortOptions: SortOptions;
  listByContainer: AssetsListState;
  listBySection: AssetsListBySectionState;
  pageSize: number;
  initSuccess: boolean;
  showAttachments: boolean;
  groupBy: SearchAssetsGroupBy;
}

export const searchAssetsInitialState: SearchAssetsState = {
  containerId: null,
  loading: true,
  error: null,
  filtersLoading: false,
  searchParams: EMPTY_SEARCH_PARAMS,
  searchType: null,
  enforcedSearchParams: null,
  sortOptions: null,
  listByContainer: assetsListInitialState,
  listBySection: assetsListBySectionInitialState,
  pageSize: DEFAULT_PAGE_SIZE,
  initSuccess: false,
  showAttachments: false,
  groupBy: SearchAssetsGroupBy.Section,
};

export const SEARCH_ASSETS_REDUCER = createReducer(searchAssetsInitialState, (builder) => {
  builder
    .addCase(init, (state, action) => {
      state.initSuccess = false;
      state.filtersLoading = true;
      state.containerId = action.payload.container.id;
    })
    .addCase(initSuccess, (state) => {
      state.initSuccess = true;
    })
    .addCase(initSearchFiltersSuccess, (state) => {
      state.filtersLoading = false;
    })
    .addCase(clearSearch, (state) => {
      state.error = null;
      state.searchParams = null;
      state.loading = false;
    })
    .addCase(search, (state, action) => {
      state.error = null;
      state.searchParams = action.payload.searchParams;
      state.sortOptions = action.payload.sortOptions;
      state.loading = true;
    })
    .addCase(baseSearch, (state, action) => {
      state.error = null;
      state.searchParams = action.payload.searchParams;
      state.searchType = action.payload.searchType;
      state.sortOptions = action.payload.sortOptions;
      state.loading = true;
    })
    .addCase(searchSuccess, (state) => {
      state.error = null;
      state.loading = false;
      state.searchType = null;
    })
    .addCase(searchError, (state, action) => {
      state.error = { error: action.payload.error };
      state.loading = false;
    })
    .addCase(clearError, (state) => {
      state.error = null;
      state.loading = false;
      state.searchType = null;
    })
    .addCase(changePageSize, (state, action) => {
      const { pageSize } = action.payload;
      state.pageSize = pageSize;
    })
    .addCase(toggleShowAttachments, (state) => {
      state.showAttachments = !state.showAttachments;
    })
    .addCase(changeGroupBy, (state, action) => {
      const { groupBy } = action.payload;
      state.groupBy = groupBy;
    })
    .addCase(setEnforcedSearchParameters, (state, action) => {
      const { enforcedSearchParameters } = action.payload;
      state.enforcedSearchParams = enforcedSearchParameters;
    })
    .addMatcher(
      () => true,
      (state, action) => {
        state.listByContainer = assetsListReducer(state.listByContainer, action);
        state.listBySection = assetsListBySectionReducer(state.listBySection, action);
      },
    );
});
