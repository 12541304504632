import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { createIcon } from './create-icon';
import './icon-place-foreground.scss';

export const IconPlaceForegroundInactive = createIcon(
  <div
    className="icon-place-foreground"
    style={{
      filter:
        'invert(84%) sepia(97%) saturate(0%) hue-rotate(181deg) brightness(88%) contrast(83%)',
    }}
  />,
);

export const IconPlaceForegroundActive = createIcon(<div className="icon-place-foreground" />);

export type IconPlaceForegroundProps = HTMLAttributes<HTMLDivElement> & {
  active: boolean;
};

export function IconPlaceForeground({ active }: IconPlaceForegroundProps) {
  return (
    <div className={classNames('flex items-center cursor-pointer, className')}>
      {active ? <IconPlaceForegroundActive /> : <IconPlaceForegroundInactive />}
    </div>
  );
}
