import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
import { Identity } from '@integration-frontends/common/auth/core/model';

export interface DatadogCredentials {
  applicationId: string;
  clientId: string;
}

export interface DatadogRUMConfig {
  enableSessionRecordings: boolean;
  env: Environment;
  datadogCredentials: DatadogCredentials;
  service: string;
}

export type ObservabilityCredentials = DatadogCredentials | unknown;
export type ObservabilityUser = Identity | unknown;

export const OBSERVABILITY_SERVICE_TOKEN = 'OBSERVABILITY_SERVICE_TOKEN';
export interface IObservabilityService<Config = DatadogRUMConfig, User = ObservabilityUser> {
  initObservability: (observabilityConfig: Config) => Promise<void>;
  sendIdentity: (identity: User, environment: Environment) => Promise<void>;
  addError: (error: Error) => Promise<void>;
  setGlobalContextProperty: (key: string, value: unknown) => void;
  stopSessionRecording: () => void;
}
