import { DatadogCredentials } from '../iobservability-service';

export const contentLibraryDD: DatadogCredentials = {
  applicationId: '9d24fdc6-ef7f-4b0b-8358-051ab87cb550',
  clientId: 'puba268d56832d1c54235b01f10174ac2c1',
};

export const integrationFrontendsDD: DatadogCredentials = {
  applicationId: '5c5d6838-8a74-470a-a06c-4ddddad94776',
  clientId: 'pub62b25d6cc48805e222194f4b04dd29d5',
};

// this is a list of services that may trigger DataDog through panel-ui app.tsx
export const datadogPanelAppNames = [
  'panel-ui',
  'contentful',
  'wordpress',
  'office-add-in',
  'figma',
  'contentstack',
  'chrome-extension',
  'panel-ui-sdk',
];
