import { AssetCustomFieldValue } from '@integration-frontends/integration/core/model';
import React from 'react';

export interface AssetCustomFieldsProps {
  customFieldValues: AssetCustomFieldValue[];
}

export function AssetCustomFieldValues({ customFieldValues }: AssetCustomFieldsProps) {
  return (
    <>
      {customFieldValues.map((customFieldValue) => (
        <ul key={customFieldValue.id}>
          <li>
            <span className="mr-md">{customFieldValue.key}</span>
            <span className="font-bold">{customFieldValue.value}</span>
          </li>
        </ul>
      ))}
    </>
  );
}
