import { DI_CONTAINER } from '@integration-frontends/core';
import {
  brandfolderEntityActions,
  collectionEntityActions,
  organizationEntityActions,
} from '@integration-frontends/integration/core/application/common';
import {
  BRANDFOLDER_REPO_TOKEN,
  COLLECTION_REPO_TOKEN,
  IBrandfolderRepo,
  ICollectionRepo,
  IOrganizationRepo,
  IUserSettingsRepo,
  IContainerRepo,
  ORGANIZATION_REPO_TOKEN,
  USER_SETTINGS_REPO_TOKEN,
  CONTAINER_REPO_TOKEN,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  uploadAssetsInit,
  uploadAssetsInitSuccess,
  selectOrganization,
  selectContainer,
  selectSection,
  selectCollection,
} from '../actions';

const handler = function* () {
  const brandfolderRepo: IBrandfolderRepo = DI_CONTAINER.get(BRANDFOLDER_REPO_TOKEN);
  const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);
  const organizationRepo: IOrganizationRepo = DI_CONTAINER.get(ORGANIZATION_REPO_TOKEN);
  const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);

  const userSettingsRepo: IUserSettingsRepo = DI_CONTAINER.get(USER_SETTINGS_REPO_TOKEN);

  const brandfolders = yield call(brandfolderRepo.listBrandfolders);
  const collections = yield call(collectionRepo.listCollections);
  const organizations = yield call(organizationRepo.listOrganizations);
  yield put(brandfolderEntityActions.brandfoldersReceived(brandfolders));
  yield put(collectionEntityActions.collectionsReceived(collections));
  yield put(organizationEntityActions.organizationsReceived(organizations));
  const {
    organizationId,
    brandfolderId,
    collectionId,
    sectionId,
    success: uploadSettingsSuccess,
  } = yield call(userSettingsRepo.getAssetUploadPreference);
  if (uploadSettingsSuccess) {
    const container = yield call(containerRepo.getContainer, brandfolderId);
    yield put(selectOrganization({ id: organizationId }));
    if (container) {
      yield put(selectContainer({ container }));
      yield put(selectSection({ id: sectionId }));
    }
    if (collectionId) yield put(selectCollection({ id: collectionId }));
  }
  yield put(uploadAssetsInitSuccess());
};

export function* initEffects() {
  yield takeEvery(uploadAssetsInit, handler);
}
