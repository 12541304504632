import { SetApiKey } from '@integration-frontends/common/auth/ui';
import { Loader } from '@integration-frontends/common/ui';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { init } from '@integration-frontends/common/app';
import { loadSegment } from '@integration-frontends/common/analytics';
import { environment } from '../environments/environment';
import { AttachmentEditorPage, SelectAttachmentPage } from './ui';
import {
  BFHistory,
  BFStore,
  DI_CONTAINER,
  HISTORY_TOKEN,
  STORE_TOKEN,
} from '@integration-frontends/core';
import { dynamicActivate } from '../../../../i18n';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';

export function App() {
  const history = DI_CONTAINER.get<BFHistory>(HISTORY_TOKEN);
  const store = DI_CONTAINER.get<BFStore>(STORE_TOKEN);

  useEffect(() => {
    store.dispatch(init({ name: 'integration-sfmc' }));
    loadSegment(environment);
    dynamicActivate('en');
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <I18nProvider i18n={i18n}>
        <Provider store={store}>
          <Router history={history}>
            <Suspense
              fallback={
                <div className="flex h-full w-full items-center justify-center">
                  <Loader />
                </div>
              }
            >
              <Routes>
                <Route path="/welcome" element={<SetApiKey />} />
                <Route path="/select-attachment/*" element={<SelectAttachmentPage />} />
                <Route path="/edit-attachment/:attachmentId" element={<AttachmentEditorPage />} />
                <Route path="*" element={<Navigate to={'/select-attachment'} replace />} />
              </Routes>
            </Suspense>
          </Router>
        </Provider>
      </I18nProvider>
    </div>
  );
}

export default App;
