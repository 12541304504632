import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import isString from 'lodash/isString';

export const BF_STANDARD_RADIO_COMPONENT = 'BF_STANDARD_RADIO_COMPONENT';
export type BFStandardRadioProps = {
  label: string;
  value: string;
} & HTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLInputElement>;

function DefaultRadio({ label, ...restProps }: BFStandardRadioProps) {
  return (
    <label style={{ display: 'flex', alignItems: 'center' }} className="min-w-0">
      <input {...restProps} type="radio" style={{ marginRight: 10 }} />
      {isString(label) ? (
        <span className="min-w-0 flex-grow cursor-pointer truncate">{label}</span>
      ) : (
        <>{label}</>
      )}
    </label>
  );
}

export const BFStandardRadio = (props: BFStandardRadioProps) => {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const RadioComponent =
    componentResolver.getComponent(BF_STANDARD_RADIO_COMPONENT) || DefaultRadio;
  return <RadioComponent {...props} />;
};
