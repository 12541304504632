import { DI_CONTAINER } from '@integration-frontends/core';
import {
  IMediaTypeSupportService,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  MediaType,
} from '@integration-frontends/integration/core/model';
import { all } from 'ramda';
import { useCallback, useEffect, useState } from 'react';

export const useMediaTypeSupport = () => {
  const mediaTypeSupportService: IMediaTypeSupportService = DI_CONTAINER.get(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const [exportableMediaTypes, setExportableMediaTypes] = useState([]);
  const [placementMediaTypes, setPlacementMediaTypes] = useState([]);

  const isSupported = useCallback((mediaType: MediaType) => {
    return mediaTypeSupportService.isSupported(mediaType);
  }, []);

  const allSupported = useCallback(
    (mediaTypes: MediaType[]) => {
      return all(isSupported, mediaTypes);
    },
    [isSupported],
  );

  useEffect(() => {
    setExportableMediaTypes(mediaTypeSupportService.getExportableMediaTypes());
    setPlacementMediaTypes(mediaTypeSupportService.getPlacementMediaTypes());
  }, []);

  return { exportableMediaTypes, isSupported, allSupported, placementMediaTypes };
};
