import { BFInput, BFLabel } from '@integration-frontends/common/ui';
import { LinkOptions, setLink } from '@integration-frontends/integration/core/application';
import React from 'react';
import { Trans } from '@lingui/macro';

export interface LinkProps {
  options: LinkOptions;
  onChange: (link: LinkOptions) => void;
}

export function Link({ options, onChange }: LinkProps) {
  return (
    <div className="flex w-full flex-col">
      <BFLabel>
        <Trans>Image Link</Trans>
      </BFLabel>
      <BFInput
        type="text"
        value={options.link}
        onChange={(e) => onChange(setLink(e.target.value, options))}
      />
    </div>
  );
}
