import { BFLabel, BFSelect } from '@integration-frontends/common/ui';
import {
  createCustomCropOptions,
  createFreeformCropOptions,
  createSocialMediaCropOptions,
  CropOptions,
  CropType,
  setAssetType,
  setCustomDimension,
  setSocialMedia,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import {
  getCropTypeOptions,
  getCustomImageSizeOptions,
  getSocialMediaImageSizeOptions,
  getSocialMediaOptions,
  Option,
} from './model';
import {
  CustomDimension,
  PlatformAssetType,
  SocialMediaPlatform,
} from '@integration-frontends/integration/core/model';
import { Trans } from '@lingui/macro';

export interface CropperOptionsProps {
  options?: CropOptions;
  onChange?: (options: CropOptions) => void;
}

export function CropperOptions({ options, onChange }: CropperOptionsProps) {
  function toOptionElement(option: Option) {
    return (
      <option key={option.value} value={option.value}>
        {option.translatedLabel}
      </option>
    );
  }

  function selectCropType(cropType: CropType) {
    switch (cropType) {
      case CropType.Custom:
        onChange(createCustomCropOptions());
        break;
      case CropType.Freeform:
        onChange(createFreeformCropOptions());
        break;
      case CropType.SocialMedia:
        onChange(createSocialMediaCropOptions());
    }
  }

  return (
    <div className="gap-md flex w-full flex-col">
      <div>
        <BFLabel>
          <Trans>Crop Type</Trans>
        </BFLabel>
        <BFSelect
          options={getCropTypeOptions().map(({ translatedLabel, value }) => ({
            label: translatedLabel,
            value,
          }))}
          data-cy="crop-type-select"
          value={options?.type}
          className="w-full"
          onOptionChange={(option) => selectCropType(option.value as CropType)}
        />
      </div>

      {options?.type === CropType.SocialMedia && (
        <>
          <div>
            <BFLabel>
              <Trans>Social Media</Trans>
            </BFLabel>
            <BFSelect
              options={getSocialMediaOptions().map(({ translatedLabel, value }) => ({
                label: translatedLabel,
                value,
              }))}
              className="w-full"
              value={options.socialMedia}
              onOptionChange={(option) =>
                onChange(setSocialMedia(options, option.value as SocialMediaPlatform))
              }
            >
              {getSocialMediaOptions().map(toOptionElement)}
            </BFSelect>
          </div>

          {options.socialMedia && (
            <div>
              <BFLabel>
                <Trans>Image Size</Trans>
              </BFLabel>
              <BFSelect
                options={getSocialMediaImageSizeOptions(options.socialMedia).map(
                  ({ translatedLabel, value }) => ({
                    label: translatedLabel,
                    value,
                  }),
                )}
                className="w-full"
                value={options.assetType}
                onOptionChange={(option) =>
                  onChange(setAssetType(options, option.value as PlatformAssetType))
                }
              >
                {}
              </BFSelect>
            </div>
          )}
        </>
      )}

      {options?.type === CropType.Custom && (
        <div>
          <BFLabel>
            <Trans>Image Size</Trans>
          </BFLabel>
          <BFSelect
            options={getCustomImageSizeOptions().map(({ translatedLabel, value }) => ({
              label: translatedLabel,
              value,
            }))}
            className="w-full"
            onOptionChange={(option) =>
              onChange(setCustomDimension(options, option.value as CustomDimension))
            }
          />
        </div>
      )}
    </div>
  );
}
