import { DI_CONTAINER } from '@integration-frontends/core';
import { ASSET_REPO_TOKEN, IAssetRepo } from '@integration-frontends/integration/core/model';
import { call } from 'redux-saga/effects';
import { put, takeEvery } from 'typed-redux-saga';
import {
  addAssetTagSuccess,
  contentLibraryAddAssetTagsComplete,
  contentLibraryAddAssetTagsInit,
} from '../actions';

export function* handler(action: ReturnType<typeof contentLibraryAddAssetTagsInit>) {
  const { assetIds, collectionId, tags, source, onAddAssetTagError } = action.payload;
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);

  try {
    yield call(assetRepo?.bulkAddAssetTags, assetIds, tags, collectionId, 'en', source);
    yield put(addAssetTagSuccess({ assetIds }));
  } catch (e) {
    if (e.length && e.some((err) => err.detail === 'Tag limit exceeded')) {
      onAddAssetTagError('createTagsExceedLength');
    } else {
      onAddAssetTagError('createTags');
    }
  }

  yield put(contentLibraryAddAssetTagsComplete());
}

export function* contentLibraryAddAssetTagsInitEffects() {
  yield takeEvery(contentLibraryAddAssetTagsInit, handler);
}
