import { datadogRum } from '@datadog/browser-rum';
import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
import { DatadogCredentials } from '../iobservability-service';

type InitDatadogRUMOptions = {
  enableSessionRecordings: boolean;
  env: Environment;
  datadogCredentials: DatadogCredentials;
  service: string;
};

export const initDatadogRUM = ({
  enableSessionRecordings,
  env,
  datadogCredentials,
  service,
}: InitDatadogRUMOptions): void => {
  const gitCommitHash = process.env.GIT_COMMIT_HASH || 'Unknown GIT_COMMIT_HASH';

  if (env === Environment.Production) {
    datadogRum.init({
      applicationId: datadogCredentials.applicationId,
      clientToken: datadogCredentials.clientId,
      defaultPrivacyLevel: 'mask',
      env: env,
      service: service,
      silentMultipleInit: true,
      site: 'datadoghq.com',
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      useSecureSessionCookie: true,
      useCrossSiteSessionCookie: true,
      version: gitCommitHash,
    });

    if (enableSessionRecordings) {
      datadogRum.startSessionReplayRecording();
    }
  }
};
