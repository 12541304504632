export enum FeatureFlags {
  ContentLibraryRouteEnabled = 44839,
}

export enum FeaftureFlags_V2 {
  IsContentViewCuffEnabled = 'ENABLE_CONTENT_VIEW_CUFF',
  ReviewsInFileLibrary = 'REVIEWS_IN_FILE_LIBRARY',
}

export enum EAPFeatureFlags_V2 {
  EAPContentLibraryEnabled = 'EAP_CONTENT_LIBRARY_IN_WORKSPACES',
}
