/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import { BFStandardToastComponent } from './bf-standard-toast-base';
import {
  SdsToastManager,
  Toast,
  ToastAction,
  ToastActions,
  ToastContent,
  ToastDismissButton,
  ToastLink,
  ToastPortal,
  useInteractiveToast,
} from '@smartsheet/sds-toast-ui';
import { sdsToastDataClientDIKey } from '@smartsheet/sds-toast-state-service';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { diContainer } from '@smartsheet/cufflink';
import { SDSThemeProvider } from '@smartsheet/lodestar-core';

export const BFStandardToastSmartsheet: BFStandardToastComponent = ({
  toastProps,
  toastContentProps,
  linkProps,
  toastActionProps,
  toastDismissButtonProps,
}) => {
  const manager: SdsToastManager = diContainer.get(sdsToastDataClientDIKey);
  const { toastActions, getToastProps, getToastPortalProps } = useInteractiveToast({
    manager,
  });
  const { _: translate } = useLingui();
  const removeToast = () => {
    toastActions.remove();
    if (toastDismissButtonProps) toastDismissButtonProps.dismissToast();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      toastActions.queue();
    });
    return () => clearTimeout(timeout);
  }, [toastActions]);

  return (
    <ToastPortal {...getToastPortalProps()}>
      <SDSThemeProvider
        themeKey="ursa-light"
        innerWrapperStyles={{
          position: 'unset',
          width: 'unset',
          height: 'unset',
          display: 'unset',
        }}
      >
        <Toast type={toastProps.type} {...getToastProps()}>
          <ToastContent>
            {toastContentProps && toastContentProps?.children}
            {linkProps && <ToastLink href={linkProps.linkHref}>{linkProps.linkText}</ToastLink>}
            {translate(toastProps.message)}
          </ToastContent>
          <ToastActions>
            {toastActionProps && (
              <ToastAction {...toastActionProps}>{toastActionProps.actionText}</ToastAction>
            )}
            <ToastDismissButton
              aria-label={t`Close notification`}
              onClick={() => {
                removeToast();
              }}
            />
          </ToastActions>
        </Toast>
      </SDSThemeProvider>
    </ToastPortal>
  );
};
