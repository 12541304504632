import {
  PagedResultsSortOrder,
  PaginationType,
} from '@integration-frontends/common/brandfolder-api';
import { DEFAULT_PAGE_SIZE } from './common';

export interface AssetsListPagination {
  loading: boolean;
  currentPage: number;
  pageAssetIds: string[][];
  pageSize: number;
  totalCount: number;
  totalPages: number | null;
  nextPage: number;
  perPage?: number;
  recordCount?: number;
  from?: number;
  to?: number;
  offset?: number;
  previousPage?: number;
  moreExist?: boolean;
  sortOrder?: PagedResultsSortOrder;
  nextCursor?: string;
  nextCursorParam?: string;
  paginationType?: PaginationType;
}

export function initAssetsListPagination(
  props: Partial<AssetsListPagination> = {},
): AssetsListPagination {
  return {
    loading: true,
    currentPage: 1,
    pageAssetIds: [],
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: null,
    totalPages: null,
    nextPage: null,
    perPage: null,
    recordCount: null,
    from: null,
    to: null,
    offset: null,
    previousPage: null,
    moreExist: null,
    sortOrder: null,
    nextCursor: null,
    nextCursorParam: null,
    paginationType: null,
    ...props,
  };
}

export function getPageCount(state: AssetsListPagination): number {
  return Math.ceil(state.totalCount / state.pageSize);
}

export function getPageFloor(state: AssetsListPagination): number {
  return state.currentPage * state.pageSize - state.pageSize + 1;
}

export function getPageCeil(state: AssetsListPagination): number {
  return Math.min(state.currentPage * state.pageSize, state.totalCount);
}

export function hasPreviousPage(state: AssetsListPagination): boolean {
  return state.currentPage > 1;
}

export function hasNextPage(state: AssetsListPagination): boolean {
  return state.currentPage < getPageCount(state);
}

export function incrementCurrentPage(state: AssetsListPagination): AssetsListPagination {
  switch (state?.paginationType) {
    case 'cursor': {
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    }
    default: {
      return {
        ...state,
        currentPage: Math.min(state.currentPage + 1, getPageCount(state)),
      };
    }
  }
}

export function decrementCurrentPage(state: AssetsListPagination): AssetsListPagination {
  return {
    ...state,
    currentPage: Math.max(state.currentPage - 1, 1),
  };
}
