import { put, takeEvery, call } from 'redux-saga/effects';
import { uploadFailure } from '../actions';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import { getObservabilityService } from '@integration-frontends/core';

const handler = function* (action: ReturnType<typeof uploadFailure>) {
  const { error } = action.payload;

  yield call(getObservabilityService()?.addError, new Error(error));
  yield put(
    createNotification({ message: error, location: 'topLevel', type: NotificationType.Error }),
  );
};

export function* uploadFailureEffects() {
  yield takeEvery(uploadFailure, handler);
}
