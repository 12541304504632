import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const bulkRemoveAssetsFromCollectionsInit = createAction(
  'BULK_REMOVE_ASSETS_FROM_COLLECTIONS_INIT',
  withPayloadType<{ collectionId: string; assetIds: string[] }>(),
);
export const bulkRemoveAssetsFromCollectionsSuccess = createAction(
  'BULK_REMOVE_ASSETS_FROM_COLLECTIONS_SUCCESS',
  withPayloadType<{ assetIds: string[] }>(),
);
export const deleteError = createAction('DELETE_ERROR');
