import { Identity, IIdentityStore } from '@integration-frontends/common/auth/core/model';
import { IDENTITY_MOCK } from './identity-mock';

export class IdentityStoreMock implements IIdentityStore {
  get(): Promise<Identity> {
    return Promise.resolve(IDENTITY_MOCK);
  }

  set(identity: Identity): Promise<void> {
    return Promise.resolve(undefined);
  }
}
