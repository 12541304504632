import { StateSelector } from '@integration-frontends/common/utils/redux';
import { Collection } from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { collectionAdapter } from '../entities';

export function createCollectionSelectors(stateSelector: StateSelector<EntityState<Collection>>) {
  const baseSelectors = collectionAdapter.getSelectors(stateSelector);

  const collectionsByBrandfolderId = (brandfolderId: string) =>
    createSelector(baseSelectors.selectAll, (collections) =>
      collections.filter((collection) => collection.brandfolderId === brandfolderId),
    );

  const collectionById = (collectionId: string) =>
    createSelector(
      (state) => baseSelectors.selectById(state, collectionId),
      (collection: Collection) => collection,
    );

  return {
    ...baseSelectors,
    byBrandfolderId: collectionsByBrandfolderId,
    byCollectionId: collectionById,
  };
}
