import { ursaColors } from '@smartsheet/design-tokens';
import React, { FC } from 'react';
import { BFStandardDividerProps } from './bf-standard-divider';

export const BFStandardDividerSmartsheet: FC<BFStandardDividerProps> = ({
  style,
  ...restProps
}) => {
  return <hr style={{ color: ursaColors.slate75 }} {...restProps} />;
};
