import { createSelector } from 'reselect';
import { DownloadAssetsState } from './reducer';

const selectLoading = (state: DownloadAssetsState) => state.loading;

export function createDownloadAssetsSelectors(
  rootStateSelector: (rootState) => DownloadAssetsState,
) {
  return {
    loading: createSelector(rootStateSelector, selectLoading),
  };
}
