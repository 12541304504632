/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { StateSelector } from '@integration-frontends/common/utils/redux';
import { containerTagAdapter } from '@integration-frontends/integration/core/application/common/entities-state/entities';
import { ContainerTag } from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';

export function createContainerTagSelectors(
  stateSelector: StateSelector<EntityState<ContainerTag>>,
) {
  return containerTagAdapter.getSelectors(stateSelector);
}
