import React from 'react';
import './notifications.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface NotificationsContainerProps {
  location: string;
  timer?: number;
  top?: number;
  bottom?: number;
  placement?: string;
}

export function NotificationsContainer({
  location,
  timer = 3000,
  top = 0,
  bottom = 0,
  placement = 'below',
}: NotificationsContainerProps) {
  const style = placement === 'below' ? { top } : { bottom };
  return (
    <section className="notifications-container" style={style}>
      <ToastContainer
        autoClose={false}
        enableMultiContainer
        containerId={location}
        className="notification box-shadow"
      />
    </section>
  );
}
