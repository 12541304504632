import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from 'reselect';
import { IntegrationEntitiesState } from '../model';
import { createAssetSelectors } from './asset';
import { createAssetCustomFieldValueSelectors } from './asset-custom-field-value';
import { createAssetTagSelectors } from './asset-tag';
import { createAttachmentSelectors } from './attachment';
import { createBrandfolderSelectors } from './brandfolder';
import { createCollectionSelectors } from './collection';
import { createContainerSelectors } from './container';
import { createContainerCustomFieldSelectors } from './container-custom-field';
import { createContainerCustomFieldValueSelectors } from './container-custom-field-value';
import { createContainerFileTypeAggregateSelectors } from './container-file-type-aggregate';
import { createContainerTagSelectors } from './container-tag';
import { createLabelSelectors } from './label';
import { createOrganizationSelectors } from './organization';
import { createSearchFilterSelectors } from './search-filter';
import { createSectionSelectors } from './section';

export type IntegrationEntitiesStateSelector = StateSelector<IntegrationEntitiesState>;

export const createIntegrationEntitiesSelectors = (
  stateSelector: IntegrationEntitiesStateSelector,
) => {
  const attachmentSelectors = createAttachmentSelectors(
    createSelector(stateSelector, (state) => state.attachments),
  );
  const assetSelectors = createAssetSelectors(
    createSelector(stateSelector, (state) => state.assets),
    attachmentSelectors,
  );
  const brandfolderSelectors = createBrandfolderSelectors(
    createSelector(stateSelector, (state) => state.brandfolders),
  );
  const collectionSelectors = createCollectionSelectors(
    createSelector(stateSelector, (state) => state.collections),
  );
  const containerCustomFieldsSelectors = createContainerCustomFieldSelectors(
    createSelector(stateSelector, (state) => state.containerCustomFields),
  );

  const containerCustomFieldValuesSelectors = createContainerCustomFieldValueSelectors(
    createSelector(stateSelector, (state) => state.containerCustomFieldValues),
  );
  const containerFileTypeAggregateSelectors = createContainerFileTypeAggregateSelectors(
    createSelector(stateSelector, (state) => state.containerFileTypeAggregates),
  );
  const containerTagSelectors = createContainerTagSelectors(
    createSelector(stateSelector, (state) => state.containerTags),
  );
  const organizationSelectors = createOrganizationSelectors(
    createSelector(stateSelector, (state) => state.organizations),
  );
  const sectionSelectors = createSectionSelectors(
    createSelector(stateSelector, (state) => state.sections),
  );

  return {
    asset: assetSelectors,
    assetCustomFieldValue: createAssetCustomFieldValueSelectors(
      createSelector(stateSelector, (state) => state.assetCustomFieldValues),
    ),
    assetTag: createAssetTagSelectors(createSelector(stateSelector, (state) => state.assetTags)),
    attachment: attachmentSelectors,
    brandfolder: brandfolderSelectors,
    collection: collectionSelectors,
    container: createContainerSelectors(
      assetSelectors,
      brandfolderSelectors,
      collectionSelectors,
      sectionSelectors,
      containerCustomFieldsSelectors,
      containerCustomFieldValuesSelectors,
      containerFileTypeAggregateSelectors,
      containerTagSelectors,
      organizationSelectors,
    ),
    containerCustomFields: containerCustomFieldsSelectors,
    containerCustomFieldValues: containerCustomFieldValuesSelectors,
    containerFileTypeAggregates: containerFileTypeAggregateSelectors,
    containerTags: containerTagSelectors,
    label: createLabelSelectors(createSelector(stateSelector, (state) => state.labels)),
    organization: organizationSelectors,
    searchFilter: createSearchFilterSelectors(
      createSelector(stateSelector, (state) => state.searchFilters),
    ),
    section: sectionSelectors,
  };
};

export type IntegrationEntitiesSelectors = ReturnType<typeof createIntegrationEntitiesSelectors>;
