import {
  BFButton,
  BFIconButton,
  BFPopover,
  ButtonSize,
  ButtonType,
  IconFilters,
  IconFiltersActive,
  IconLabel,
  IconSize,
  IconViewOptions,
} from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  containerSelectors,
  ISelectAttachmentOptions,
  labelEntitySelectors,
  searchAssetsSelectors,
  searchFilterEntitySelectors,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import {
  Collection,
  filterCount,
  hasFilters,
  ResourceBaseSortableProperty,
  SearchAssetsGroupBy,
  SearchFilter,
  SearchParameters,
  Section,
  setPinnedSearchFilter,
  setQueryFilter,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAttachmentSelectorNavigation } from '../../../navigation';
import { ViewOptionsDropDown } from '../../view-options/view-options-dropdown';
import { CollectionsSelectorContent, CollectionsSelectorToggle } from '../collections-selector';
import { PinsSelectorToggle } from '../pins-selector-toggle';
import { SearchInput } from '../search';
import { PinnedFiltersList } from '../search/pinned-filters-list';
import { SectionsSelectorContent, SectionsSelectorToggle } from '../sections-selector';
import './show-page-controls.scss';

enum ScrollDirections {
  up = 'up',
  down = 'down',
}

interface ShowPageControlsProps {
  containerId: string;
  selectedSection: Section;
  onSectionSelect: (section: Section) => void;
  onCollectionSelect: (collection: Collection) => void;
  onSetShowAdvancedFiltersView: (state) => void;
  showAdvancedFiltersView: boolean;
  onSetShowSearchView: (state) => void;
  showSearchView: boolean;
  search: (
    searchParams?: SearchParameters,
    sortOptions?: SortOptions<ResourceBaseSortableProperty>,
  ) => void;
  sortOptions: SortOptions<ResourceBaseSortableProperty>;
  onApplyFilters: (searchParams: SearchParameters, sortOptions: SortOptions) => void;
  responsive?: boolean;
  showAttachments: boolean;
  labelsClick: () => void;
  scrollDirection: ScrollDirections;
}

export function ShowPageControls({
  containerId,
  selectedSection,
  onSectionSelect,
  onCollectionSelect,
  onSetShowAdvancedFiltersView,
  showAdvancedFiltersView,
  onSetShowSearchView,
  showSearchView,
  search,
  sortOptions,
  onApplyFilters,
  responsive,
  showAttachments,
  labelsClick,
  scrollDirection,
}: ShowPageControlsProps) {
  const navigation = useAttachmentSelectorNavigation();
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, navigation.containerId),
  );
  const container = useSelector(searchAssetsSelectors.container);
  const searchFilters = useSelector(searchFilterEntitySelectors.byContainer(container));
  const searchParams = useSelector(searchAssetsSelectors.searchParams);
  const [sectionsSelectorOpen, setSectionsSelectorOpen] = useState(false);
  const [collectionsSelectorOpen, setCollectionsSelectorOpen] = useState(false);
  const [pinsSelectorOpen, setPinsSelectorOpen] = useState(false);
  const hasLabels = useSelector(labelEntitySelectors.containerHasLabels(selectedContainer));
  const groupBy = useSelector(searchAssetsSelectors.groupBy);
  const { hiddenSections = [], enforcedSearchParameters }: ISelectAttachmentOptions =
    DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);

  function onSectionSelectHandler(section: Section) {
    onSectionSelect(section);
  }

  function onCollectionSelectHandler(collection: Collection) {
    onCollectionSelect(collection);
  }

  const pinnedSearchFilterSelectHandler = useCallback(
    (searchFilter: SearchFilter) => {
      search(setPinnedSearchFilter(searchFilter, searchParams));
    },
    [searchParams],
  );

  const enforcedCollectionOrSection = () => {
    return (
      enforcedSearchParameters &&
      (searchParams?.query?.includes('collection:') || searchParams?.query?.includes('section:'))
    );
  };

  return (
    <section className="show-page-controls-wrapper" id="showPageControlsWrapper">
      <div className="show-page-controls-top relative">
        {!enforcedCollectionOrSection() && (
          <div className="show-page-controls-selector-list-toggle">
            <h2 className="selected-container-title">{selectedContainer?.name}</h2>{' '}
            <BFPopover
              button={
                <CollectionsSelectorToggle
                  id="collections-selector-toggle"
                  containerId={navigation.containerId}
                  open={collectionsSelectorOpen}
                  aria-label="collections selector button"
                />
              }
              panel={
                <CollectionsSelectorContent
                  id="collections-selector-content"
                  containerId={containerId}
                  onCollectionSelect={onCollectionSelectHandler}
                />
              }
              offset={[-27, 7]}
              zIndex={75}
              panelClass="show-page-controls-selector-list-content collections-list"
            />
          </div>
        )}
      </div>
      <div className="show-page-controls-center">
        <div className="filter-button-wrapper">
          {hasFilters(setQueryFilter(null, searchParams)) && (
            <BFButton
              data-testid="filters-btn"
              className="gap-xs row filters-btn flex w-max items-center justify-center"
              buttonType={ButtonType.ActiveDark}
              size={ButtonSize.Small}
              onClick={() => onSetShowAdvancedFiltersView(true)}
              ariaLabel={`${filterCount(searchParams)} filters selected. Open Filters`}
              hasIcons={true}
            >
              {filterCount(searchParams) > 0 && (
                <p className="filters-button-count">{filterCount(searchParams)}</p>
              )}
              <IconFiltersActive className="icon-filters-active" iconSize={IconSize.Small} />
            </BFButton>
          )}
          {!hasFilters(setQueryFilter(null, searchParams)) && (
            <BFIconButton
              data-testid="filters-btn"
              id="filters-btn"
              className="gap-xs row flex items-center justify-center"
              buttonType={ButtonType.Secondary}
              size={ButtonSize.Small}
              style={{ width: 40 }}
              onClick={() => onSetShowAdvancedFiltersView(!showAdvancedFiltersView)}
              iconElement={<IconFilters iconSize={IconSize.Small} />}
              ariaLabel="Open Filters"
            />
          )}
        </div>
        {searchFilters.length > 0 && (
          <BFPopover
            button={<PinsSelectorToggle open={pinsSelectorOpen} id="pins-selector-toggle" />}
            panel={
              <PinnedFiltersList
                id="pinned-filters-list"
                container={container}
                onSearchFilterSelect={pinnedSearchFilterSelectHandler}
              />
            }
            offset={[70, 7]}
            zIndex={71}
            panelClass="show-page-controls-selector-list-content"
          />
        )}
        <SearchInput className="show-page-controls-search-input" />
      </div>
      <div className="show-page-controls-bottom relative">
        <div className="show-page-controls-selector-list-toggle">
          <div className="show-page-controls-bottom-left inline flex">
            {!enforcedCollectionOrSection() && groupBy === SearchAssetsGroupBy.Section && (
              <BFPopover
                button={
                  <SectionsSelectorToggle
                    id="view-sections-toggle"
                    open={sectionsSelectorOpen}
                    selectedSection={selectedSection}
                  />
                }
                panel={
                  <SectionsSelectorContent
                    id="view-sections-content"
                    containerId={containerId}
                    selectedSection={selectedSection}
                    onSectionSelect={onSectionSelectHandler}
                    hiddenSections={hiddenSections}
                  />
                }
                offset={[15, 7]}
                zIndex={70}
                panelClass="show-page-controls-selector-list-content"
              />
            )}
            {hasLabels && (
              <BFIconButton
                id="view-sections-button"
                className="text-primary border-bf-light-gray hover:border-bf-dark-gray row flex items-center justify-center rounded border bg-white transition ease-in-out"
                iconElement={<IconLabel iconSize={IconSize.Medium} />}
                size={ButtonSize.Small}
                onClick={labelsClick}
                data-testid="labels-btn"
                ariaLabel="open labels"
              />
            )}
          </div>
          <div className="relative flex items-center">
            <BFPopover
              id="view-options-popover"
              button={
                <BFIconButton
                  id="view-options-button"
                  className="text-primary border-bf-light-gray hover:border-bf-dark-gray row flex items-center justify-center rounded border bg-white transition ease-in-out"
                  data-testid="view-options-btn"
                  buttonType={ButtonType.Secondary}
                  size={ButtonSize.Small}
                  iconElement={<IconViewOptions iconSize={IconSize.Small} />}
                  ariaLabel="open view options"
                />
              }
              panel={
                <ViewOptionsDropDown
                  id="view-options-dropdown"
                  search={search}
                  containerId={containerId}
                  searchParams={searchParams}
                  sortOptions={sortOptions}
                  onApply={onApplyFilters}
                  responsive={responsive}
                  showAdvancedFiltersView={showAdvancedFiltersView}
                  showAttachments={showAttachments}
                />
              }
              offset={[-130, 5]}
              zIndex={70}
              closeOnClick={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
