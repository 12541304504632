import { HoverTooltip, TooltipLooks, TooltipPositions } from '@brandfolder/react';
import { IconViewOnlyEnabled } from '@integration-frontends/common/ui/icons/icon-view-only';
import { useComponentResolver } from '@integration-frontends/core';
import { useTranslate } from '@integration-frontends/core/translation/use-translate';
import React from 'react';

export const ICON_VIEW_ONLY_COMPONENT_KEY = 'ICON_VIEW_ONLY_COMPONENT';

function DefaultIconViewOnly() {
  const { translate } = useTranslate();

  return (
    <HoverTooltip
      look={TooltipLooks.Primary}
      position={TooltipPositions.Left}
      text={translate('View only')}
    >
      <IconViewOnlyEnabled />
    </HoverTooltip>
  );
}

export function IconViewOnly() {
  const Component = useComponentResolver(ICON_VIEW_ONLY_COMPONENT_KEY) || DefaultIconViewOnly;

  return <Component />;
}
