import {
  BFAnchor,
  BFButton,
  BFHR,
  BFPanel,
  ButtonSize,
  IconClose,
} from '@integration-frontends/common/ui';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import {
  Container,
  ContainerCustomField,
  ContainerFileTypeAggregates,
  ContainerTag,
  DEFAULT_SORT_OPTIONS,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React from 'react';
import {
  INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  IntegrationUiStylingOptions,
} from '../../../styling-options';
import './advanced-filters-component.scss';
import {
  AssetStatusFilter,
  CustomFieldFiltersContainer,
  OrientationFiltersContainer,
  PrioritizedCustomFieldsFiltersContainer,
  SkuFilterContainer,
  TopFileTypesFiltersContainer,
  TopTagsFiltersContainer,
  UploadDateFilterContainer,
} from './components';
import { Trans } from '@lingui/macro';

export interface AdvancedFiltersProps {
  container: Container;
  containerCustomFields: ContainerCustomField[];
  searchParams: SearchParameters;
  searchParamsChanged: (params: SearchParameters) => void;
  sortOptions: SortOptions;
  onClose: () => void;
  onApply: (searchParams: SearchParameters, sortOptions: SortOptions) => void;
  onClear: () => void;
  responsive?: boolean;
  showAdvancedFiltersView?: boolean;
  tags: ContainerTag[];
  fileTypeAggregates: ContainerFileTypeAggregates[];
  dirty: boolean;
}

export const ADVANCED_FILTERS_COMPONENT_KEY = 'ADVANCED_FILTERS';

export function AdvancedFiltersComponent({
  container,
  containerCustomFields,
  searchParams = {},
  searchParamsChanged = noop,
  sortOptions = DEFAULT_SORT_OPTIONS,
  onClose = noop,
  onApply = noop,
  onClear = noop,
  responsive,
  showAdvancedFiltersView,
  tags,
  fileTypeAggregates,
  dirty,
}: AdvancedFiltersProps) {
  const stylingOptions = useDependencyInjector<IntegrationUiStylingOptions>(
    INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  );

  const bodyElement = (
    <>
      <div
        className="advanced-filters-header px-xl flex items-center justify-between"
        style={{ flex: '0 0 60px', backgroundColor: stylingOptions?.backgroundColor }}
      >
        <IconClose className="cursor-pointer" onClick={onClose} />
        <span className="font-bold">
          <Trans>Filter Results</Trans>
        </span>
        <BFAnchor data-testid="clear-btn" onClick={onClear}>
          <Trans>Clear</Trans>
        </BFAnchor>
      </div>

      <BFHR />

      {containerCustomFields.length > 0 && (
        <>
          <PrioritizedCustomFieldsFiltersContainer
            customFields={containerCustomFields}
            searchParams={searchParams}
            onChange={searchParamsChanged}
          />
          <BFHR />
        </>
      )}

      <AssetStatusFilter searchParams={searchParams} onChange={searchParamsChanged} />

      <BFHR />

      <TopTagsFiltersContainer
        searchParams={searchParams}
        tags={tags}
        onChange={searchParamsChanged}
      />

      <BFHR />

      <TopFileTypesFiltersContainer
        fileTypeAggregates={fileTypeAggregates}
        searchParams={searchParams}
        onChange={searchParamsChanged}
      />

      <BFHR />

      <CustomFieldFiltersContainer
        containerId={container.id}
        searchParams={searchParams}
        onChange={searchParamsChanged}
      />

      <BFHR />

      <OrientationFiltersContainer searchParams={searchParams} onChange={searchParamsChanged} />

      <BFHR />

      <SkuFilterContainer searchParams={searchParams} onChange={searchParamsChanged} />

      <BFHR />

      <UploadDateFilterContainer searchParams={searchParams} onChange={searchParamsChanged} />

      {dirty && (
        <div className="bg-bf-white sticky bottom-0 z-10 rounded-b-md opacity-95">
          <BFHR />
          <div className="flex items-center justify-center">
            <BFButton
              data-testid="apply-btn"
              className="my-md"
              size={ButtonSize.Small}
              style={{ width: 168 }}
              onClick={() => onApply(searchParams, sortOptions)}
            >
              <Trans>Apply Filters</Trans>
            </BFButton>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div
      id="advanced-filters"
      data-testid="advanced-filters"
      className={classNames('h-full w-full', {
        'advanced-filters-overlay-responsive': responsive,
        'advanced-filters-overlay': !responsive,
        open: showAdvancedFiltersView,
      })}
      style={{ backgroundColor: stylingOptions?.backgroundColor }}
    >
      {responsive ? (
        bodyElement
      ) : (
        <div className="h-full w-full overflow-y-auto">
          <BFPanel
            className="bg-bf-white my-md left-0 right-0 mx-auto flex flex-col rounded-md"
            style={{ width: 360, top: 20 }}
          >
            {bodyElement}
          </BFPanel>
        </div>
      )}
    </div>
  );
}
