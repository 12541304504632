import { Loader, ScrollingContainerRefContext } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  containerSelectors,
  ISelectAttachmentOptions,
  searchAssetsSelectors,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  selectAttachmentSelectors,
  SelectionType,
  showPageEntered,
  showPageScrollStopped,
} from '@integration-frontends/integration/core/application';
import {
  ResourceBaseSortableProperty,
  SearchParameters,
  Section,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import noop from 'lodash/noop';
import OverlayScrollbars from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react/dist/types/OverlayScrollbarsComponent';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageContainer, PageContent, PageScrollContainer } from '../../../common/layout/page';
import { NotificationsContainer } from '../../../common/notifications/notifications';
import { AttachmentSelectorFeature } from '../../../features';
import { MultiSelectOverlay } from '../../../multi-select-overlay';
import { useFeatures } from '../../common/use-features';
import { useAttachmentSelectorNavigation } from '../../navigation';
import { AdvancedFiltersContainer } from '../advanced-filters';
import { LabelPathList } from '../labels/label-path-list';
import { MultiSelectModal } from '../multi-select-modal';
import { LabelsSelectorView } from './labels-selector-view';
import { ShowPageNav } from './nav/nav';
import { SearchResults } from './search/search-results';
import { useSearch } from './search/use-search';
import { ShowPageControls } from './show-page-controls/show-page-controls';

// TODO: Need to refactor this component ~PP

export interface ShowPageProps {
  containerSelect: (containerId: string) => void;
}

export function ShowPage({ containerSelect = noop }: ShowPageProps) {
  const { containerId } = useParams<{ containerId: string }>();
  const dispatch = useDispatch();
  let showAttachments;
  enum ScrollDirections {
    up = 'up',
    down = 'down',
  }
  const { search, sortOptions } = useSearch();
  const searchParams = useSelector(searchAssetsSelectors.searchParams);
  const pageLoading = useSelector(selectAttachmentSelectors.pageLoading);
  const container = useSelector((state) => containerSelectors.selectById(state, containerId));
  const searchLoading = useSelector(searchAssetsSelectors.loading);
  const selectedAttachments = useSelector(selectAttachmentSelectors.selectedAttachments);
  const initialScrollPosition = useSelector(selectAttachmentSelectors.showPageScrollPosition);
  const { showAttachmentsToggleDisabled }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );
  const selectAttachmentSelectionType = useSelector(selectAttachmentSelectors.selectionType);
  selectAttachmentSelectionType === SelectionType.Multi && showAttachmentsToggleDisabled
    ? (showAttachments = true)
    : (showAttachments = useSelector(searchAssetsSelectors.showAttachments));
  const [selectedSection, setSelectedSection] = useState<Section>(null);
  const [showAdvancedFiltersView, setShowAdvancedFiltersView] = useState<boolean>(false);
  const [showSearchView, setShowSearchView] = useState<boolean>(false);
  const [showLabelsView, setShowLabelsView] = useState<boolean>(false);
  const [modalActive, setModalActive] = useState(false);
  const [yScrollPos, setYScrollPos] = useState<number>(0);
  const [multiSelectFooterOpen, setMultiSelectFooterOpen] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirections>(ScrollDirections.up);
  const navigation = useAttachmentSelectorNavigation();
  const containerRef = useRef();
  const pageContainerRef = useRef<OverlayScrollbarsComponent>();
  const showPageControlsElement = document.getElementById('showPageControlsWrapper');
  const showPageControlsHeight = showPageControlsElement ? showPageControlsElement.offsetHeight : 0;
  const showPageNavElement = document.getElementById('showPageNav');
  const multiSelectOverlayElement = document.getElementById('multi-select-overlay');
  const showPageNavHeight = showPageNavElement ? showPageNavElement.offsetHeight : 0;
  const { hasFeature } = useFeatures();
  const responsiveFeature = hasFeature(AttachmentSelectorFeature.Responsive);

  useEffect(() => {
    dispatch(
      showPageEntered({
        containerId,
        searchParams,
        sortOptions,
      }),
    );
  }, [containerId]);

  useEffect(() => {
    containerSelect(containerId);
  }, [container?.id]);

  useEffect(() => {
    if (selectedAttachments.length > 0) {
      setMultiSelectFooterOpen(true);
    } else {
      setMultiSelectFooterOpen(false);
    }
  }, [selectedAttachments]);

  const onApplyFilters = useCallback(
    (searchParams: SearchParameters, sortOptions: SortOptions<ResourceBaseSortableProperty>) => {
      setShowAdvancedFiltersView(false);
      search(searchParams, sortOptions);
    },
    [],
  );

  const onScrollStop: OverlayScrollbars.Options['callbacks']['onScrollStop'] = useCallback(
    (e: UIEvent) => {
      const scrollElement = e.target as Element;
      dispatch(showPageScrollStopped({ scrollPosition: scrollElement.scrollTop }));
    },
    [],
  );

  const handleScrollDown = () => {
    setScrollDirection(ScrollDirections.down);
  };

  const handleScrollUp = () => {
    setScrollDirection(ScrollDirections.up);
  };

  window.addEventListener('resize', function () {
    if (scrollDirection === ScrollDirections.down) {
      handleScrollUp();
    }
  });

  const currentYScrollPos = (): number => {
    let currYScrollPos: number;
    if (pageContainerRef.current) {
      currYScrollPos = pageContainerRef.current.osInstance().scroll().position.y;
    }
    return currYScrollPos;
  };

  useEffect(() => {
    const _currentYScrollPos: number = Math.round(currentYScrollPos());
    const showPageHeight = document.getElementById('show-page')?.offsetHeight;
    const showPageBodyHeight = document.getElementById('show-page-body')?.offsetHeight;

    const bottomOfScroll = showPageBodyHeight - showPageHeight;
    const scrollAtBottom = _currentYScrollPos >= bottomOfScroll;
    const scrollAtTop = _currentYScrollPos === 0;
    // This value is put in place because  the height of the show-page's scroll can sometimes be too short to fill the page with the control header hidden.
    // The 20 is extra buffer room to make sure the user doesn't get stuck
    const tooShortToHideControls =
      showPageBodyHeight - (showPageHeight + showPageControlsHeight) < 20;

    if ((scrollAtTop || _currentYScrollPos < yScrollPos) && !scrollAtBottom) {
      handleScrollUp();
    } else if (_currentYScrollPos > yScrollPos && !tooShortToHideControls) {
      handleScrollDown();
    }
    setYScrollPos(_currentYScrollPos);
  }, [currentYScrollPos]);

  useEffect(() => {
    if (pageContainerRef.current) {
      pageContainerRef.current.osInstance().scroll(initialScrollPosition);
    }
  }, []);

  function toggleModal() {
    setModalActive(!modalActive);
  }

  // TODO: make this into its own component
  const showPageLanding = (
    <ScrollingContainerRefContext.Provider value={responsiveFeature ? null : containerRef}>
      {showLabelsView ? (
        <LabelsSelectorView onClose={() => setShowLabelsView(false)} />
      ) : (
        <div
          style={{
            visibility: location.pathname.includes('asset-details') ? 'hidden' : 'visible',
          }}
          className="show-page flex h-full flex-col"
        >
          <PageContent
            id="show-page-body"
            className="show-page-body"
            style={{
              paddingBottom: multiSelectFooterOpen ? multiSelectOverlayElement?.offsetHeight : '0',
            }}
          >
            <>
              {searchParams && searchParams.labelFilter && (
                <LabelPathList searchParams={searchParams} />
              )}
              <div className="relative">
                <NotificationsContainer location="show-page" />
                <div>
                  {searchLoading ? (
                    <Loader />
                  ) : (
                    <SearchResults
                      containerId={containerId}
                      searchParams={searchParams}
                      selectedSection={selectedSection}
                      showAttachments={showAttachments}
                    />
                  )}
                </div>
              </div>
              {selectAttachmentSelectionType === SelectionType.Multi && <MultiSelectOverlay />}
              {modalActive && selectAttachmentSelectionType === SelectionType.Multi && (
                <MultiSelectModal
                  prompt={`You have ${selectedAttachments.length} assets selected within ${container.name}.`}
                  subText={`You will lose this selection if you leave this page.`}
                  toggleModal={toggleModal}
                />
              )}
            </>
          </PageContent>
        </div>
      )}
    </ScrollingContainerRefContext.Provider>
  );

  return (
    <PageContainer data-testid="show-page">
      {pageLoading ? (
        <Loader />
      ) : (
        <>
          <ShowPageNav />
          {showAdvancedFiltersView && (
            <PageContent fullWidth={true}>
              <AdvancedFiltersContainer
                containerId={containerId}
                searchParams={searchParams}
                sortOptions={sortOptions}
                onClose={() => setShowAdvancedFiltersView(false)}
                onApply={onApplyFilters}
                responsive={responsiveFeature}
                showAdvancedFiltersView={showAdvancedFiltersView}
              />
            </PageContent>
          )}
          <div
            style={{
              height: '100%',
              position: 'relative',
            }}
          >
            <ShowPageControls
              containerId={containerId}
              selectedSection={selectedSection}
              onSectionSelect={setSelectedSection}
              onCollectionSelect={(collection) => navigation.goToShowPage(collection.id)}
              onSetShowAdvancedFiltersView={() =>
                setShowAdvancedFiltersView(!showAdvancedFiltersView)
              }
              showAdvancedFiltersView={showAdvancedFiltersView}
              onSetShowSearchView={() => setShowSearchView(!showSearchView)}
              showSearchView={showSearchView}
              search={search}
              sortOptions={sortOptions}
              onApplyFilters={onApplyFilters}
              responsive={responsiveFeature}
              showAttachments={showAttachments}
              labelsClick={() => setShowLabelsView(!showLabelsView)}
              scrollDirection={scrollDirection}
            />

            <div
              id="show-page"
              className="relative"
              style={{
                height: `calc(100% - ${showPageControlsHeight}px - ${showPageNavHeight}px)`,
              }}
            >
              <PageScrollContainer
                ref={pageContainerRef}
                scrollOptions={{ callbacks: { onScrollStop } }}
                id="show-page-landing"
                className="relative h-full"
              >
                {showPageLanding}
              </PageScrollContainer>
            </div>
          </div>
        </>
      )}
    </PageContainer>
  );
}
