import { ButtonLooks } from '@brandfolder/react';
import { BFStandardButtonSmartsheet } from './bf-standard-button-smartsheet';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import React, {
  ButtonHTMLAttributes,
  FC,
  ForwardedRef,
  ReactElement,
  ReactNode,
  forwardRef,
} from 'react';

export type BFStandardButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'large' | 'medium';
  children?: ReactNode;
  iconBefore?: ReactElement;
  iconAfter?: ReactElement;
  look?:
    | ButtonLooks.Primary
    | ButtonLooks.Secondary
    | ButtonLooks.Danger
    | ButtonLooks.White
    | 'canvas-empty';
  ref: ForwardedRef<HTMLButtonElement>;
};

export type BFStandardButtonComponent = FC<BFStandardButtonProps>;
export const BF_STANDARD_BUTTON_COMPONENT = 'BF_STANDARD_BUTTON';

export const BFStandardButton = forwardRef<HTMLButtonElement, BFStandardButtonProps>(
  ({ children, ...restProps }, ref) => {
    const componentResolver = DI_CONTAINER.get<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
    const ButtonComponent =
      componentResolver.getComponent<BFStandardButtonComponent>(BF_STANDARD_BUTTON_COMPONENT) ||
      BFStandardButtonSmartsheet;

    return <ButtonComponent children={children} {...restProps} ref={ref} />;
  },
);
