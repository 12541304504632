import {
  IntegrationEntitiesSelectors,
  SearchAssetsSelectors,
  SelectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import { enteredOrgLandingPageEffects } from '@integration-frontends/integration/core/application/select-attachment/state/effects/entered-org-landing-page';
import { showPageEffects } from '@integration-frontends/integration/core/application/select-attachment/state/effects/show-page';
import { all, fork } from 'redux-saga/effects';
import { initiateAttachmentActionClickedEffects } from './attachment-action-clicked';
import { attachmentSelectedEffects } from './attachment-selected';
import { endAttachmentSelectionEffects } from './end-attachment-selection';
import { enteredAssetDetailsEffects } from './entered-asset-details';
import { goToAttachmentDetailsEffects } from './entered-attachment-details';
import { goToAssetDetailsEffects } from './go-to-asset-details';
import { initEffects } from './init';
import { selectAllAttachmentsInitEffects } from './select-all-attachments-init';

export function* selectAttachmentEffects(
  entitiesSelectors: IntegrationEntitiesSelectors,
  selectAttachmentSelectors: SelectAttachmentSelectors,
  searchAssetsSelectors: SearchAssetsSelectors,
) {
  yield all([
    fork(initiateAttachmentActionClickedEffects),
    fork(attachmentSelectedEffects),
    fork(endAttachmentSelectionEffects),
    fork(enteredAssetDetailsEffects),
    fork(enteredOrgLandingPageEffects),
    fork(goToAttachmentDetailsEffects, entitiesSelectors),
    fork(goToAssetDetailsEffects),
    fork(initEffects, selectAttachmentSelectors),
    fork(showPageEffects, selectAttachmentSelectors, searchAssetsSelectors),
    fork(selectAllAttachmentsInitEffects),
  ]);
}
