import { BFImage } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Attachment,
  AttachmentVariant,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export interface AttachmentImageProps {
  attachment: Attachment | AttachmentVariant;
  alt?: string;
  useFullSize?: boolean;
  responsive?: boolean;
}

export function AttachmentThumbnailImage({
  alt,
  attachment,
  responsive,
  useFullSize = false,
  ...props
}: AttachmentImageProps & HTMLAttributes<HTMLImageElement>) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const thumbnailUrl = attachment?.thumbnailUrl;
  const fullSizeUrl = attachment?.url;
  return (
    <BFImage
      alt={alt}
      imageData={attachment}
      src={useFullSize ? fullSizeUrl : thumbnailUrl}
      thumbnailUrl={thumbnailUrl}
      fallbackUrl={bfWebsiteService.getFileThumbnailUrl(attachment?.extension)}
      fullSizeUrl={fullSizeUrl}
      className={classNames('w-full h-full')}
      responsive={responsive}
      {...props}
    />
  );
}
