import { withFormControlStyles } from '@integration-frontends/common/ui/forms/form-controls/common';
import React, { HTMLAttributes, SelectHTMLAttributes } from 'react';
import { COMPONENT_RESOLVER_TOKEN, ComponentResolver } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import { MultiSelect } from './multi-select-dropdown';
import './select.scss';

export const MULTI_SELECT_COMPONENT_KEY = 'MULTI_SELECT';

export enum BFMultiSelectSize {
  Small = 's',
  Medium = 'm',
}

export type BFMultiSelectOption = {
  value: string | number;
  label?: string;
};

export type BFMultiSelectProps = {
  options: BFMultiSelectOption[];
  selected: BFMultiSelectOption[];
  onChange: any;
  isClearable?: boolean;
  size?: BFMultiSelectSize;
  selectedOptions?: BFMultiSelectOption[];
  getOptionKey: (opton: BFMultiSelectOption) => string | number;
  onClear?: () => void;
  selectedValues?: string[];
  zIndex?: number;
  panelStyleOptions?: HTMLAttributes<HTMLElement>['style'];
  buttonStyleOptions?: HTMLAttributes<HTMLButtonElement>['style'];
} & Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
  Omit<SelectHTMLAttributes<HTMLElement>, 'onChange' | 'size'>;

function DefaultComponent({
  options,
  placeholder,
  selected,
  onChange,
  disabled,
  isClearable,
  onClear,
  selectedValues,
  zIndex,
  panelStyleOptions,
  buttonStyleOptions,
  ...restProps
}: BFMultiSelectProps) {
  return (
    <div {...restProps}>
      <MultiSelect
        id='multi-select'
        options={options}
        className="multi-select"
        onChange={onChange}
        value={selectedValues}
        placeholder={placeholder}
        isDisabled={disabled}
        isClearable={isClearable}
        onClear={onClear}
        selected={selected}
        zIndex={zIndex}
        buttonStyleOptions={buttonStyleOptions}
        panelStyleOptions={panelStyleOptions}
      />
    </div>
  );
}

export const BFMultiSelect = withFormControlStyles<BFMultiSelectProps>((props) => {
  const componentResolver = useDependencyInjector<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
  const Component = componentResolver.getComponent(MULTI_SELECT_COMPONENT_KEY) || DefaultComponent;
  return <Component {...props} />;
});
