import { inject, injectable } from 'inversify';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
} from '@integration-frontends/common/brandfolder-api';

import { GET_API_KEY_TOKEN, IGetAuthToken } from './model';

@injectable()
export class RepoBase {
  @inject(BRANDFOLDER_API_TOKEN)
  protected brandfolderApi: BrandfolderApi;
  @inject(GET_API_KEY_TOKEN)
  protected getApiKey: IGetAuthToken;
}
