import { BrandfolderGlobalStyles } from '@brandfolder/react';
import {
  selectAttachmentInit,
  selectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useResolvedPath } from 'react-router-dom';
import { IntegrationPageLoader } from '../common/page-loader/page-loader';
import { OrganizationLandingPage } from './components';
import { AssetDetailsPage } from './components/asset-details-page';
import {
  ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT,
  AttachmentSelectorView,
  useAttachmentSelectorNavigation,
} from './navigation';
import './attachment-selector.scss';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { ShowPage } from './components/show-page';
import { SectionUploadPageContainer } from './components/section-upload-page';

export interface AttachmentSelectorProps {
  assetSelect?: (assetId: string) => void;
  containerSelect?: (containerId: string) => void;
}

function AttachmentSelectorComponent({ assetSelect, containerSelect }: AttachmentSelectorProps) {
  const dispatch = useDispatch();
  const initSuccess = useSelector(selectAttachmentSelectors.initSuccess);
  const navigation = useAttachmentSelectorNavigation();

  useEffect(() => {
    dispatch(selectAttachmentInit());
  }, []);

  if (!initSuccess) {
    return <IntegrationPageLoader />;
  }

  return (
    <Routes>
      <Route
        path={navigation.getRoute(AttachmentSelectorView.ContainerSelector)}
        element={<OrganizationLandingPage />}
      />

      <Route
        path={navigation.getRoute(AttachmentSelectorView.AssetDetailsNoContainer)}
        element={<AssetDetailsPage assetSelect={assetSelect} />}
      />

      <Route
        path={`${navigation.getRoute(AttachmentSelectorView.ShowPage)}/*`}
        element={
          <div className="h-full relative flex flex-col">
            <ShowPage containerSelect={containerSelect} />

            <Routes>
              <Route
                path={navigation.getRoute(AttachmentSelectorView.AssetDetails)}
                element={
                  <div id="asset-details-container">
                    <AssetDetailsPage assetSelect={assetSelect} />
                  </div>
                }
              />

              <Route
                path={navigation.getRoute(AttachmentSelectorView.SectionUploadPage)}
                element={
                  <div id="section-upload-container">
                    <SectionUploadPageContainer />
                  </div>
                }
              />
            </Routes>
          </div>
        }
      />
    </Routes>
  );
}

export function AttachmentSelector({ ...props }: AttachmentSelectorProps) {
  const path = useResolvedPath('.');

  return (
    <ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT.Provider value={{ navigationRootPath: path }}>
      <BrandfolderGlobalStyles />
      <AttachmentSelectorComponent {...props} />
    </ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT.Provider>
  );
}
