import { call, all } from 'typed-redux-saga';
import { bulkRemoveAssetsFromCollectionsEffects } from './bulk-remove-assets-from-collections';
import { bulkRemoveAssetsFromCollectionsSucessEffects } from './bulk-remove-assets-from-collections-success';

export function* deleteAssetsEffects() {
  yield all([
    call(bulkRemoveAssetsFromCollectionsEffects),
    call(bulkRemoveAssetsFromCollectionsSucessEffects),
  ]);
}
