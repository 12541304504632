import { datadogRum } from '@datadog/browser-rum';
import { Identity } from '@integration-frontends/common/auth/core/model';
import { Environment } from '@integration-frontends/apps/common/utils/environment-types';

export interface DataDogUser {
  id?: string | undefined;
  [key: string]: unknown;
}

export const sendIdentityDatadog = (identity: Identity, env: Environment) => {
  const user: DataDogUser = {
    id: identity?.userId,
  };

  if (env === Environment.Production) {
    datadogRum.setUser(user);
  }
};
