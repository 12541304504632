import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  brandfolderEntityActions,
  collectionEntityActions,
  searchAssetsActions,
  SearchAssetsSelectors,
  SelectAttachmentSelectors,
  showPageDataLoaded,
  showPageEntered,
} from '@integration-frontends/integration/core/application';
import { getRehydratedState } from '@integration-frontends/integration/core/application/select-attachment/state/effects/common/get-rehydrated-state';
import {
  BRANDFOLDER_REPO_TOKEN,
  COLLECTION_REPO_TOKEN,
  Container,
  CONTAINER_REPO_TOKEN,
  IBrandfolderRepo,
  ICollectionRepo,
  IContainerRepo,
  ResourceType,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { call, takeEvery } from 'redux-saga/effects';
import { all, put, take } from 'typed-redux-saga';

function* handler(action: ReturnType<typeof showPageEntered>) {
  try {
    const { containerId, searchParams, sortOptions } = action.payload;
    const brandfolderRepo: IBrandfolderRepo = DI_CONTAINER.get(BRANDFOLDER_REPO_TOKEN);
    const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);
    const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);
    const container = yield call(containerRepo.getContainer, containerId);

    yield initSearch(container, searchParams, sortOptions);

    if (container.type === ResourceType.BRANDFOLDER) {
      yield put(brandfolderEntityActions.brandfoldersReceived([container]));
      const collections = yield call(collectionRepo.listCollections);
      yield put(collectionEntityActions.collectionsReceived(collections));
    } else {
      const brandfolder = yield call(brandfolderRepo.getBrandfolder, container.brandfolderId);

      yield put(brandfolderEntityActions.brandfoldersReceived([brandfolder]));
      yield put(collectionEntityActions.collectionsReceived([container]));
    }

    yield put(showPageDataLoaded());
    yield put(pageLoaded());
  } catch (e) {
    yield put(pageLoadError({ error: e }));
  }
}

function* initSearch(
  container: Container,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
) {
  yield put(searchAssetsActions.init({ container }));
  yield take(searchAssetsActions.initSuccess);
  yield put(searchAssetsActions.search({ searchParams, sortOptions }));
}

export function* enteredShowPageEffects(
  selectAttachmentSelectors: SelectAttachmentSelectors,
  searchAssetsSelectors: SearchAssetsSelectors,
) {
  const [showPageEnteredAction, rehydratedState] = yield all([
    take(showPageEntered),
    call(getRehydratedState),
  ]);

  if (
    rehydratedState &&
    !selectAttachmentSelectors.pageLoading(rehydratedState) &&
    !searchAssetsSelectors.loading(rehydratedState)
  ) {
    // use cached data if the persisted state had finished loading
    yield put(showPageDataLoaded());
  } else {
    yield handler(showPageEnteredAction);
  }

  yield takeEvery(showPageEntered, handler);
}
