export const UPLOAD_ASSETS_OPTIONS_TOKEN = 'UPLOAD_ASSETS_OPTIONS';

export interface IUploadAssetsOptions {
  showAddMore?: boolean;
  showTags?: boolean;
  showCustomFields?: boolean;
  showUploadAssetsButton?: boolean;
  multipleAssetUpload?: boolean;
  tagsOrigin?: TagsOrigin;
}

export enum TagsOrigin {
  internal = 'INTERNAL',
  external = 'EXTERNAL',
}
