import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { AnchorHTMLAttributes } from 'react';
import { useDispatch } from 'react-redux';
import { enterToClick } from './accessibilityInteractions';
export const linkClicked = createAction(
  'LINK_CLICKED',
  withPayloadType<{ external: boolean; href: string }>(),
);

export interface BFAnchorProps {
  external?: boolean;
}

export function BFAnchor({
  className,
  external,
  onClick = noop,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement> & BFAnchorProps) {
  const dispatch = useDispatch();

  function click(e) {
    external && dispatch(linkClicked({ external: true, href: props.href }));
    onClick(e);
  }

  return (
    <a
      tabIndex={0}
      className={classNames(
        'link',
        'cursor-pointer',
        className,
      )}
      {...props}
      onKeyDown={enterToClick}
      onClick={click}
    />
  );
}
