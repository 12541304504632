export enum ResourceType {
  ATTACHMENT = 'attachments',
  ATTACHMENT_VARIANT = 'attachment_variant',
  BRANDFOLDER = 'brandfolders',
  COLLECTION = 'collections',
  CUSTOM_FIELD = 'custom_field_keys',
  CUSTOM_FIELD_VALUE = 'custom_field_values',
  CUSTOM_FIELD_VALUE_OPTION = 'custom_field_value_option',
  GENERIC_FILE = 'generic_files',
  LABEL = 'labels',
  ORGANIZATION = 'organizations',
  PERMISSION_LEVEl = 'permission_levels',
  SEARCH_FILTER = 'search_filters',
  SECTION = 'sections',
  TAG = 'tags',
  TEXT = 'texts',
}

export interface ResourceBase {
  id: string;
  name?: string;
  slug?: string;
  type: ResourceType;
  cardImage?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  position: number;
}

export function positionComparator(r1: ResourceBase, r2: ResourceBase): number {
  return r1.position - r2.position;
}

export function hasCardImage(resource: ResourceBase): boolean {
  return resource.cardImage && resource.cardImage.length > 0;
}
