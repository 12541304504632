import React, { HTMLAttributes } from 'react';
import './root-container.scss';

export function RootContainer({ style, ...restProps }: HTMLAttributes<any>) {
  return (
    <div
      className="integration-frontends-root-container"
      style={{ ...style, width: '100%', height: '100%' }}
      {...restProps}
    />
  );
}
