import { MediaType } from '../../common/media';

export const MEDIA_TYPE_SUPPORT_SERVICE_TOKEN = 'MEDIA_TYPE_SUPPORT_SERVICE';
export interface IMediaTypeSupportService {
  // Non-allowed media types are not shown on the show page. "null" means all media types are allowed.
  getAllowedMediaTypes: () => MediaType[] | null;
  getExportableMediaTypes: () => MediaType[];
  getPlacementMediaTypes: () => MediaType[];
  // Unsupported media types are shown on the show page but cannot be interacted with.
  isSupported: (mediaType: MediaType) => boolean;
}
