import {
  brandfoldersMockData,
  Container,
  ContainerCustomField,
  containerCustomFieldsMockData,
  ContainerFileTypeAggregates,
  containerFileTypeAggregatesMockData,
  ContainerTag,
  containerTagsMockData,
  CustomFieldValueOption,
  customFieldValueOptionMockData,
  IContainerRepo,
} from '@integration-frontends/integration/core/model';

export class ContainerRepoMock implements IContainerRepo {
  getContainer(containerId: string): Promise<Container> {
    return Promise.resolve(brandfoldersMockData[0]);
  }

  getContainerCustomFields(container: Container): Promise<ContainerCustomField[]> {
    return Promise.resolve(
      containerCustomFieldsMockData.map((customField) => ({
        ...customField,
        containerId: container.id,
      })),
    );
  }

  getContainerCustomFieldValues(container: Container): Promise<CustomFieldValueOption[]> {
    return Promise.resolve(customFieldValueOptionMockData);
  }

  getContainerFileTypeAggregates(container: Container): Promise<ContainerFileTypeAggregates[]> {
    return Promise.resolve(
      containerFileTypeAggregatesMockData.map((fileTypeAggregate) => ({
        ...fileTypeAggregate,
        containerId: container.id,
      })),
    );
  }

  getContainerTags(container: Container): Promise<ContainerTag[]> {
    return Promise.resolve(
      containerTagsMockData.map((tag) => ({ ...tag, containerId: container.id })),
    );
  }

  getContainerFiletypeAggregations(container: Container): Promise<ContainerFileTypeAggregates[]> {
    return Promise.resolve(
      containerFileTypeAggregatesMockData.map((fileTypeAggregate) => ({
        ...fileTypeAggregate,
        containerId: container.id,
      })),
    );
  }

  getContainerTagAggregations(container: Container): Promise<ContainerTag[]> {
    return Promise.resolve(
      containerTagsMockData.map((tag) => ({ ...tag, containerId: container.id })),
    );
  }
}
