import { LoggingStateService, LogLevel, LogActionId } from '@smartsheet/logging-state-service';
import { injectable } from 'inversify';
import { OBSERVABILITY_STATE_SERVICE_TOKEN } from './iobservability-state-service';
import { diContainer } from '@smartsheet/cufflink';

@injectable()
export class ObservabilityStateServiceStrategy {
  private loggingService: LoggingStateService;
  constructor() {
    this.loggingService = diContainer.get(OBSERVABILITY_STATE_SERVICE_TOKEN);
  }

  warn(
    message: string,
    exception?: Error,
    contextMap?: Map<string, string>,
    appName?: string,
    appVersion?: string,
    featureArea?: string,
    fileName?: string,
    lineNumber?: number,
  ): void {
    this.loggingService.warn(
      message,
      exception,
      contextMap,
      appName,
      appVersion,
      featureArea,
      fileName,
      lineNumber,
    );
  }

  error(
    message: string,
    exception?: Error,
    contextMap?: Map<string, string>,
    appName?: string,
    appVersion?: string,
    featureArea?: string,
    fileName?: string,
    lineNumber?: number,
  ): void {
    this.loggingService.error(
      message,
      exception,
      contextMap,
      appName,
      appVersion,
      featureArea,
      fileName,
      lineNumber,
    );
  }

  log(
    logLevel: LogLevel,
    message: string,
    exception?: Error,
    contextMap?: Map<string, string>,
    appName?: string,
    appVersion?: string,
    featureArea?: string,
    fileName?: string,
    lineNumber?: number,
  ): void {
    this.loggingService.log(
      logLevel,
      message,
      exception,
      contextMap,
      appName,
      appVersion,
      featureArea,
      fileName,
      lineNumber,
    );
  }

  logClientEvent(controlType: string, controlId: string, actionId: LogActionId): void {
    this.loggingService.logClientEvent(controlType, controlId, actionId);
  }

  logEventWithoutPrivateInfo(
    objectID: number,
    actionID: LogActionId,
    parm1String?: string,
    parm1Int?: number,
    eventMetadata?: Map<string, unknown>,
  ): void {
    this.loggingService.logEventWithoutPrivateInfo(
      objectID,
      actionID,
      parm1String,
      parm1Int,
      eventMetadata,
    );
  }
}

export function getObservabilityStateService() {
  return diContainer.get<LoggingStateService>(OBSERVABILITY_STATE_SERVICE_TOKEN);
}
