import {
  addToRefs,
  arrowKeyNavigate,
  BFButton,
  ButtonSize,
  ButtonType,
  enterToClick,
  IconCaretDown,
  IconSize,
} from '@integration-frontends/common/ui';
import {
  containerSelectors,
  searchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import { positionComparator, Section } from '@integration-frontends/integration/core/model';
import { createSelector } from 'reselect';
import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { SectionViewModel } from '../../../model';
import './sections-selector.scss';
import { Trans } from '@lingui/macro';

export interface SectionsSelectorToggleProps {
  open: boolean;
  selectedSection: Section;
}

export function SectionsSelectorToggle({
  open,
  selectedSection,
  ...props
}: SectionsSelectorToggleProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="pr-xs flex cursor-pointer items-center" {...props}>
      <BFButton
        data-testid="sections-selector"
        buttonType={ButtonType.Secondary}
        className="flex items-center justify-between"
        style={{ width: '160px' }}
        size={ButtonSize.Small}
        ariaLabel="open sections selector"
        iconAfter={<IconCaretDown className="icon-caret-down-primary" iconSize={IconSize.Small} />}
      >
        {selectedSection ? (
          <span className="sections-selector-toggle-label">
            {selectedSection.name}
            {selectedSection.assetCount ? ` (${selectedSection.assetCount})` : ''}
          </span>
        ) : (
          <span className="sections-selector-toggle-label">
            <Trans>All Sections</Trans>
          </span>
        )}
      </BFButton>
    </div>
  );
}

export interface SectionsSelectorContentProps {
  containerId: string;
  onSectionSelect: (section: Section) => void;
  selectedSection?: Section;
  id?: string;
  hiddenSections?: string[];
}

export function SectionsSelectorContent({
  containerId,
  onSectionSelect,
  selectedSection,
  id,
  hiddenSections,
}: SectionsSelectorContentProps) {
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const [focusedItem, setFocusedItem] = useState(0);
  const itemRefs = useRef([]);

  const sections: SectionViewModel[] = useSelector(
    createSelector(
      searchAssetsSelectors.isSearching,
      searchAssetsSelectors.listBySectionSelectors.pagination,
      containerSelectors.sections(selectedContainer),
      (isSearching, pagination, containerSections) => {
        return containerSections.map((section) => ({
          ...section,
          selectable:
            (isSearching ? !!pagination.sections[section.id]?.totalCount : true) &&
            !hiddenSections.includes(section.name),
        }));
      },
    ),
  ).sort(positionComparator);

  useEffect(() => {
    if (itemRefs.current.length) {
      itemRefs.current[focusedItem].focus();
    }
  });

  const handleOnKeyPress = (e) => {
    enterToClick(e);
    arrowKeyNavigate(e, focusedItem, itemRefs.current, setFocusedItem);
  };

  return (
    <div id={id} className="flex flex-col">
      <div
        className={classNames('section-list-item cursor-pointer', { selected: !selectedSection })}
        onClick={() => onSectionSelect(null)}
      >
        <span className="w-full">
          <Trans>All Sections</Trans>
        </span>
      </div>

      {sections.map(
        (section) =>
          section.selectable && (
            <div
              key={section.id}
              data-testid="sections-selector-option"
              className={classNames('section-list-item background-hover cursor-pointer', {
                selected: selectedSection && selectedSection.id === section.id,
              })}
              ref={(ref) => addToRefs(ref, itemRefs.current)}
              onClick={() => onSectionSelect(section)}
              tabIndex={0}
              onKeyDown={handleOnKeyPress}
            >
              <span className="w-full">
                {section.name}
                {section.assetCount ? ` (${section.assetCount})` : ''}
              </span>
            </div>
          ),
      )}
    </div>
  );
}
