import {
  Brandfolder,
  BrandfolderCustomField,
  BrandfolderFileTypeAggregates,
  brandfoldersMockData,
  BrandfolderTag,
  containerCustomFieldsMockData,
  containerFileTypeAggregatesMockData,
  containerTagsMockData,
  CustomFieldValueOption,
  customFieldValueOptionMockData,
  IBrandfolderRepo,
} from '@integration-frontends/integration/core/model';

export class BrandfolderRepoMock implements IBrandfolderRepo {
  getBrandfolder: IBrandfolderRepo['getBrandfolder'] = () => {
    return Promise.resolve(brandfoldersMockData[0]);
  };

  getBrandfolderCustomFields(brandfolderId: string): Promise<BrandfolderCustomField[]> {
    return Promise.resolve(
      containerCustomFieldsMockData.map((customField) => ({ ...customField, brandfolderId })),
    );
  }

  getBrandfolderCustomFieldValues(brandfolderId: string): Promise<CustomFieldValueOption[]> {
    return Promise.resolve(customFieldValueOptionMockData);
  }

  getBrandfolderFileTypeAggregates(
    brandfolderId: string,
  ): Promise<BrandfolderFileTypeAggregates[]> {
    return Promise.resolve(
      containerFileTypeAggregatesMockData.map((fileTypeAggregate) => ({
        ...fileTypeAggregate,
        brandfolderId,
      })),
    );
  }

  getBrandfolderTags(brandfolderId: string): Promise<BrandfolderTag[]> {
    return Promise.resolve(containerTagsMockData.map((tag) => ({ ...tag, brandfolderId })));
  }

  listBrandfolders(): Promise<Brandfolder[]> {
    return Promise.resolve(brandfoldersMockData);
  }
}
