import { BannerType, createBanner } from '@integration-frontends/common/notifications';
import { put } from 'typed-redux-saga';

export function* createViolationBanner(violation: string) {
  yield put(
    createBanner({
      id: crypto.randomUUID(),
      type: BannerType.Error,
      bannerContent: {
        contentText: violation,
      },
    }),
  );
}
