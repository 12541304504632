import { BFInput, BFSelect, BFSelectSize, InputSize } from '@integration-frontends/common/ui';
import {
  ResizingOptions,
  setHeight,
  setWidth,
} from '@integration-frontends/integration/core/application';
import {
  Attachment,
  AttachmentVariant,
  getImageMediaTypes,
  getMediaTypesExtensions,
  getVideoMediaTypes,
  isImageType,
  isVideoType,
  KnownMediaType,
} from '@integration-frontends/integration/core/model';
import { t } from '@lingui/macro';
import React from 'react';
import { useMediaTypeSupport } from '../../../common/use-media-type-support';
import { FlexSpacer } from '../../common/layout/flex-spacer';
import { FormControl } from '../../common/layout/form';

export interface AttachmentOptionsComponentProps {
  attachment: Attachment | AttachmentVariant;
  selectedMediaType: KnownMediaType;
  userResizingOptions: ResizingOptions;
  onResize: (resizingOptions: ResizingOptions) => void;
  placement: boolean;
  onMediaTypeChange?: (mediaType: KnownMediaType) => void;
}

export function AttachmentOptionsComponent({
  attachment,
  placement,
  onMediaTypeChange,
  onResize,
  selectedMediaType,
  userResizingOptions,
}: AttachmentOptionsComponentProps) {
  const { exportableMediaTypes, placementMediaTypes } = useMediaTypeSupport();
  const isImage = isImageType(attachment.mediaType);
  const exportMediaTypes: KnownMediaType[] = isImage
    ? getImageMediaTypes(placement ? placementMediaTypes : exportableMediaTypes)
    : isVideoType(attachment.mediaType)
    ? getVideoMediaTypes(exportableMediaTypes)
    : [];

  return (
    <FlexSpacer direction={'vertical'}>
      {!!onMediaTypeChange && (
        <FlexSpacer>
          <div className="grow">
            <FormControl label={t`File Type`} labelFor="file-type-listbox">
              <BFSelect
                id="file-type-listbox"
                size={BFSelectSize.Medium}
                value={selectedMediaType}
                {...(!isImage && { placeholder: selectedMediaType })}
                data-testid="file-type-select"
                onOptionChange={(opt) => opt && onMediaTypeChange(opt.value as KnownMediaType)}
                style={{ width: '100%' }}
                disabled={!isImage}
                options={exportMediaTypes.map((mediaType) => ({
                  value: mediaType,
                  label: getMediaTypesExtensions([mediaType])?.[0].toLocaleUpperCase(),
                }))}
              />
            </FormControl>
          </div>
          <div className="grow" />
        </FlexSpacer>
      )}

      <FlexSpacer>
        <FlexSpacer direction={'vertical'} size={'xs'} className="grow">
          <FormControl label={t`Width`}>
            <BFInput
              size={InputSize.Medium}
              data-testid="width-input"
              className="w-full"
              type="number"
              disabled={!isImage}
              value={Math.round(userResizingOptions?.newDimensions.width) || ''}
              onChange={(e) => onResize(setWidth(userResizingOptions, parseInt(e.target.value)))}
            />
          </FormControl>
        </FlexSpacer>

        <FlexSpacer direction={'vertical'} size={'xs'} className="grow">
          <FormControl label={t`Height`}>
            <BFInput
              size={InputSize.Medium}
              data-testid="height-input"
              className="w-full"
              type="number"
              disabled={!isImage}
              value={Math.round(userResizingOptions?.newDimensions.height) || ''}
              onChange={(e) => onResize(setHeight(userResizingOptions, parseInt(e.target.value)))}
            />
          </FormControl>
        </FlexSpacer>
      </FlexSpacer>
    </FlexSpacer>
  );
}
