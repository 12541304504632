import {
  Container,
  filterByBrandfolder,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { createSelector } from 'reselect';
import { createAssetSelectors } from './asset';
import { createBrandfolderSelectors } from './brandfolder';
import { createCollectionSelectors } from './collection';
import { createContainerCustomFieldSelectors } from './container-custom-field';
import { createContainerCustomFieldValueSelectors } from './container-custom-field-value';
import { createContainerFileTypeAggregateSelectors } from './container-file-type-aggregate';
import { createContainerTagSelectors } from './container-tag';
import { createOrganizationSelectors } from './organization';
import { createSectionSelectors } from './section';

export function createContainerSelectors(
  assetSelectors: ReturnType<typeof createAssetSelectors>,
  brandfolderSelectors: ReturnType<typeof createBrandfolderSelectors>,
  collectionSelectors: ReturnType<typeof createCollectionSelectors>,
  sectionSelectors: ReturnType<typeof createSectionSelectors>,
  containerCustomFieldSelectors: ReturnType<typeof createContainerCustomFieldSelectors>,
  containerCustomFieldValueSelectors: ReturnType<typeof createContainerCustomFieldValueSelectors>,
  containerFileTypeAggregateSelectors: ReturnType<typeof createContainerFileTypeAggregateSelectors>,
  containerTagSelectors: ReturnType<typeof createContainerTagSelectors>,
  organizationSelectors: ReturnType<typeof createOrganizationSelectors>,
) {
  const containerCustomFieldsByContainerId = (containerId: string) =>
    createSelector(containerCustomFieldSelectors.selectAll, (customFields) =>
      customFields.filter((field) => field.containerId === containerId),
    );

  const containerFileTypeAggregatesByContainerId = (containerId: string) =>
    createSelector(containerFileTypeAggregateSelectors.selectAll, (aggregates) =>
      aggregates.filter((aggregate) => aggregate.containerId === containerId),
    );

  const containerTagsByContainerId = (containerId: string) =>
    createSelector(containerTagSelectors.selectAll, (tags) =>
      tags.filter((tag) => tag.containerId === containerId),
    );

  const selectAllContainers = createSelector(
    brandfolderSelectors.selectAll,
    collectionSelectors.selectAll,
    (brandfolders, collections) => [...brandfolders, ...collections],
  );

  const selectContainerById = (state: any, id: string) => {
    return selectAllContainers(state)?.find((c) => c.id === id);
  };

  const selectContainersByOrganizationId = (organizationId: string) =>
    createSelector(
      selectAllContainers,
      brandfolderSelectors.selectEntities,
      (containers, brandfoldersById) =>
        organizationId
          ? containers.filter(
              (container) =>
                organizationId ===
                (container.type === ResourceType.BRANDFOLDER
                  ? container.organizationId
                  : brandfoldersById[container.brandfolderId].organizationId),
            )
          : [],
    );

  const selectBrandfoldersByOrganizationId = (organizationId: string) =>
    createSelector(
      selectAllContainers,
      brandfolderSelectors.selectEntities,
      (containers, brandfoldersById) => {
        return organizationId
          ? containers.filter((container) => {
              return (
                container.type === ResourceType.BRANDFOLDER &&
                brandfoldersById[container.id].organizationId === organizationId
              );
            })
          : [];
      },
    );

  const containerSectionsSelector = (container: Container) =>
    createSelector(sectionSelectors.selectAll, (sections) => {
      if (!container) return [];

      return sections.filter(
        filterByBrandfolder(
          container.type === ResourceType.BRANDFOLDER ? container.id : container.brandfolderId,
        ),
      );
    });

  const containerAssetsSelector = (container: Container) =>
    createSelector(
      assetSelectors.selectAll,
      containerSectionsSelector(container),
      (assets, sections) =>
        assets.filter((asset) => sections.find((section) => section.id === asset.sectionId)),
    );

  const containerOrganizationSelector = (container: Container) =>
    createSelector(
      organizationSelectors.selectEntities,
      brandfolderSelectors.selectEntities,
      (orgEntities, brandfolderEntities) => {
        if (!container) return null;

        if (container.type === ResourceType.COLLECTION) {
          return orgEntities[brandfolderEntities[container.brandfolderId]?.organizationId];
        }

        return orgEntities[container.organizationId];
      },
    );

  return {
    selectAll: selectAllContainers,
    selectById: selectContainerById,
    byOrganizationId: selectContainersByOrganizationId,
    brandfolders: selectBrandfoldersByOrganizationId,
    assets: containerAssetsSelector,
    sections: containerSectionsSelector,
    organization: containerOrganizationSelector,
    customFields: {
      ...containerCustomFieldSelectors,
      byContainerId: containerCustomFieldsByContainerId,
    },
    customFieldValues: {
      ...containerCustomFieldValueSelectors,
    },
    fileTypeAggregates: {
      ...containerFileTypeAggregateSelectors,
      byContainerId: containerFileTypeAggregatesByContainerId,
    },
    tags: {
      ...containerTagSelectors,
      byContainerId: containerTagsByContainerId,
    },
  };
}
