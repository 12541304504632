import { getResponseListDataOrDefault } from '@integration-frontends/common/brandfolder-api';
import {
  Container,
  ISearchFilterRepo,
  ResourceType,
  SearchFilter,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { mapSearchFilter } from './model';
import { RepoBase } from './repo-base';

@injectable()
export class SearchFilterRepo extends RepoBase implements ISearchFilterRepo {
  listContainerSearchFilters = async (container: Container): Promise<SearchFilter[]> => {
    if (!container) return [];

    const results = await (container.type === ResourceType.BRANDFOLDER
      ? this.brandfolderApi.listBrandfolderSearchFilters(await this.getApiKey(), container.id)
      : this.brandfolderApi.listCollectionSearchFilters(await this.getApiKey(), container.id));

    return getResponseListDataOrDefault(results).map(mapSearchFilter(container.id));
  };
}
