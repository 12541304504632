import { put, select, takeEvery } from 'typed-redux-saga';
import { cancelAssetUpload, cancelAssetUploads } from '../../actions';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { contentLibraryUploadAssetsSelectors } from '@integration-frontends/integration/core/application/selectors';
import { updateBanner } from '@integration-frontends/common/notifications';
import { plural } from '@lingui/macro';

export function* cancelAssetUploadEffects() {
  yield takeEvery([cancelAssetUpload, cancelAssetUploads], handler);
}

const handler = function* () {
  const uploadSequenceId = yield select(contentLibraryUploadAssetsSelectors.uploadSequenceId);
  const unresolvedAssetUploads = yield select(
    contentLibraryUploadAssetsSelectors.unresolvedAssetUploads,
  );
  const unresolvedAssetUploadsCount = unresolvedAssetUploads.length;
  const message = plural(unresolvedAssetUploadsCount, {
    one: '# unresolved upload',
    other: '# unresolved uploads',
  });

  yield put(
    updateBanner({
      id: uploadSequenceId,
      bannerContent: {
        contentText: message,
      },
    }),
  );
};
