import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import {
  ASSET_REPO_TOKEN,
  IAssetRepo,
  USER_SETTINGS_REPO_TOKEN,
  IUserSettingsRepo,
  ISharingService,
  SHARING_SERVICE_TOKEN,
} from '@integration-frontends/integration/core/model';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { upload, uploadSuccess, uploadFailure } from '../actions';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';
import { sendSegmentAction } from '@integration-frontends/common/analytics';

const handler = function* (action: ReturnType<typeof upload>) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const userSettingsRepo: IUserSettingsRepo = DI_CONTAINER.get(USER_SETTINGS_REPO_TOKEN);
  const sharingService: ISharingService = DI_CONTAINER.get(SHARING_SERVICE_TOKEN);
  const {
    organizationId,
    container,
    sectionId,
    assets,
    source,
    savePreferences,
    tags = [],
    externalMedia,
    customFields = null,
  } = action.payload;
  if (savePreferences) {
    yield call(userSettingsRepo.setAssetUploadPreference, organizationId, container, sectionId);
  }
  let newAsset;
  if (externalMedia?.url) {
    try {
      new URL(externalMedia.url);
      try {
        newAsset = yield call(
          assetRepo.createExternalMedia,
          container,
          sectionId,
          source,
          externalMedia,
        );
      } catch (error) {
        yield call(getObservabilityService()?.addError, error);
        yield put(uploadFailure({ error: 'Upload failed' }));
      }
    } catch (error) {
      yield call(getObservabilityService()?.addError, error);
      yield put(uploadFailure({ error: 'URL entered is not a valid URL' }));
    }
  } else {
    const files = yield all(
      assets.map((asset) =>
        call(async (url: string) => {
          const response = await fetch(url);
          return response.blob().then((blob) => new File([blob], asset.name, { type: blob.type }));
        }, asset.url),
      ),
    );
    try {
      newAsset = yield call(assetRepo.create, container, sectionId, assets[0].name, files, source);
    } catch (error) {
      yield call(getObservabilityService()?.addError, error);
      yield put(uploadFailure({ error: 'Upload failed' }));
    }
  }
  if (newAsset) {
    yield put(sendSegmentAction({ event: 'uploadSuccess' }));
    const shareLink = yield call(sharingService.getAssetShareUrl, container, newAsset.data[0]);
    yield put(uploadSuccess({ shareLink: shareLink, asset: newAsset.data[0] }));
    if (tags?.length) {
      try {
        yield call(
          assetRepo.bulkAddAssetTags,
          newAsset.data.map((asset) => asset.id),
          tags.map((tag) => tag),
        );
      } catch (error) {
        yield call(getObservabilityService()?.addError, error);
        yield put(
          createNotification({
            message: 'Failed to add tags',
            location: 'uploadForm',
            type: NotificationType.Error,
          }),
        );
      }
    }
    if (customFields?.length) {
      try {
        yield call(
          assetRepo.addAssetCustomFields,
          newAsset.data.map((asset) => asset.id),
          container,
          customFields,
        );
      } catch (error) {
        yield call(getObservabilityService()?.addError, error);
        yield put(
          createNotification({
            message: 'Failed to add custom fields',
            location: 'uploadForm',
            type: NotificationType.Error,
          }),
        );
      }
    }
  }
};

export function* uploadEffects() {
  yield takeEvery(upload, handler);
}
