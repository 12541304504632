import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import { upload, initialAssetUpload, endUploadSequence } from '../../actions';
import {
  Collection,
  ISectionRepo,
  Section,
  SECTION_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { FileUpload } from '../../reducer';
import {
  contentLibraryUploadAssetsSelectors,
  entitiesSelectors,
  sectionEntitySelectors,
  uploadAssetsSelectors,
} from '../../../../selectors';
import { getViolations } from './get-violations';
import { createViolationBanner } from './create-violation-banner';
import { uploadAsset, handleUploadProgressBanner, handleUploadComplete } from './common';

export function* uploadEffects() {
  yield takeEvery(upload, handler);
}

const handler = function* (action: ReturnType<typeof upload>) {
  const { fileUploads } = action.payload;

  const collectionId = yield select(uploadAssetsSelectors.selectedCollectionId);
  const collection = yield select(entitiesSelectors.collection.byCollectionId(collectionId));

  const violations = getViolations(fileUploads);

  if (violations.length) {
    yield all(violations.map((violation) => call(createViolationBanner, violation)));
    const assetUploads = yield select(contentLibraryUploadAssetsSelectors.assetUploads);
    if (!assetUploads.length) {
      yield put(endUploadSequence());
    }
    return;
  }

  yield* handleUploadProgressBanner({ numAssetsToAddToProgress: fileUploads.length });

  const sections: Section[] = Object.values(yield select(sectionEntitySelectors.selectEntities));

  yield all(
    fileUploads.map((fileUpload) => {
      return call(function* () {
        const sectionId = yield* getSectionId(fileUpload.file, sections);
        yield handleUploadAsset(collection, sectionId, fileUpload);
      });
    }),
  );

  const isUploadOngoing = yield select(contentLibraryUploadAssetsSelectors.isUploadOngoing);
  if (!isUploadOngoing) {
    yield* handleUploadComplete();
  }
};

// eslint-disable-next-line require-yield
function* getSectionId(file: File, sections: Section[]) {
  const mimeType =
    file.type.split('/')[0] === 'image' || file.type.split('/')[0] === 'video'
      ? 'image'
      : 'document';
  const sectionName = mimeType === 'image' ? 'Images' : 'Documents';
  const section = sections.find((item) => item.name === sectionName);
  return section?.id;
}

function* handleUploadAsset(collection: Collection, sectionId: string, fileUpload: FileUpload) {
  const assetUploadId = crypto.randomUUID();
  yield put(
    initialAssetUpload({ assetUploadId, collectionId: collection.id, sectionId, fileUpload }),
  );
  yield uploadAsset(assetUploadId);
}
