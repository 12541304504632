import { assetCustomFieldValueEntitySelectors } from '@integration-frontends/integration/core/application';
import { Asset } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageSection, PageSectionContent } from '../../../common/layout/page';
import { AssetCustomFieldValues } from '../../common/asset-custom-field-values';
import { t } from '@lingui/macro';

export interface CustomFieldsProps {
  asset: Asset;
}

export function CustomFields({ asset }: CustomFieldsProps) {
  const customFieldValues = useSelector(assetCustomFieldValueEntitySelectors.byAssetId(asset.id));

  if (customFieldValues.length <= 0) return null;
  return (
    <PageSection title={t`Custom Fields`}>
      <PageSectionContent>
        <AssetCustomFieldValues customFieldValues={customFieldValues} />
      </PageSectionContent>
    </PageSection>
  );
}
