import { CropType } from '@integration-frontends/integration/core/application';
import {
  AssetStatus,
  AttachmentOrientation,
  CustomDimension,
  Platform,
  PlatformAssetType,
  SortOptions,
} from '@integration-frontends/integration/core/model';

// static labels translation keys
export const ALL_ASSETS_HEADER_KEY = 'allAssetsHeader';
export const ASSET_STATUS_LABEL_KEY = 'assetStatus';
export const ATTACH_LOADING_LABEL_KEY = 'attachLoading';
export const ATTACH_ROW_SUCCESS_LABEL_KEY = 'attachRowSuccess';
export const ATTACH_SHEET_SUCCESS_LABEL_KEY = 'attachSheetSuccess';
export const ATTACH_ROW_SUCCESS_NOTIFICATION_KEY = 'attachRowSuccessNotification';
export const ATTACH_SHEET_SUCCESS_NOTIFICATION_KEY = 'attachSheetSuccessNotification';
export const ATTACH_TO_ROW_LABEL_KEY = 'attachToRow';
export const ATTACH_TO_SHEET_LABEL_KEY = 'attachToSheet';
export const BRANDFOLDER_KEY = 'brandfolder';
export const BRANDFOLDERS_KEY = 'brandfolders';
export const ORGANIZATION_SECTION_BANNER_KEY = 'organizationSectionBanner';
export const BRANDFOLDERS_AND_COLLECTIONS_KEY = 'brandfoldersAndCollections';
export const CLEAR_FILTERS_KEY = 'clearFilters';
export const COPY_LABEL_KEY = 'copy';
export const COPY_LOADING_LABEL_KEY = 'copyLoading';
export const COPY_SUCCESS_LABEL_KEY = 'copySuccess';
export const CROP_HEIGHT_LABEL_KEY = 'cropHeight';
export const CROP_TYPE_LABEL_KEY = 'cropType';
export const CROP_WIDTH_LABEL_KEY = 'cropWidth';
export const CUSTOM_FIELDS_LABEL_KEY = 'customFields';
export const HELP_KEY = 'help';
export const HOME_PAGE_LABEL_KEY = 'homePage';
export const IMAGE_LABEL_LABEL_KEY = 'imageLabel';
export const IMAGE_LINK_LABEL_KEY = 'imageLink';
export const IMAGE_SIZE_LABEL_KEY = 'imageSize';
export const IMAGE_WIDTH_LABEL_KEY = 'imageWidth';
export const IMAGE_HEIGHT_LABEL_KEY = 'imageHeight';
export const LOCK_ASPECT_RATIO_LABEL_KEY = 'lockAspectRatio';
export const LOG_OUT_LABEL_KEY = 'logOut';
export const MULTI_SELECT_ATTACH_TO_ROW_KEY = 'multiSelectAttachToRow';
export const MULTI_SELECT_ATTACH_TO_SHEET_KEY = 'multiSelectAttachToSheet';
export const MULTI_SELECT_FILES_CURRENTLY_SELECTED_KEY = 'multiSelectFilesCurrentlySelected';
export const MULTI_SELECT_ATTACHING_KEY = 'multiSelectAttaching';
export const MULTI_SELECT_ATTACHED_TO_ROW = 'multiSelectAttachedToRow';
export const MULTI_SELECT_ATTACHED_TO_SHEET = 'multiSelectAttachedToSheet';
export const MY_BRANDFOLDERS_KEY = 'myBrandfolders';
export const OPEN_IN_BRANDFOLDER_LABEL_KEY = 'openInBrandfolder';
export const ORGANIZATIONS_KEY = 'organizations';
export const PLACE_TO_ROW_LABEL_KEY = 'placeToRow';
export const PLACE_TO_SHEET_LABEL_KEY = 'placeToSheet';
export const PLACE_BACKGROUND_LABEL = 'placeBackground';
export const PLACE_FOREGROUND_LABEL = 'placeForeground';
export const PLACE_LOADING_LABEL_KEY = 'placeLoading';
export const PLACE_SUCCESS_LABEL_KEY = 'placeSuccess';
export const TRY_AGAIN_KEY = 'tryAgain';
export const SEARCH_ERROR_KEY = 'searchError';
export const SEARCH_PLACEHOLDER_KEY = 'searchPlaceholder';
export const SEARCH_TERMS_KEY = 'searchTerms';
export const SELECT_LABEL_KEY = 'select';
export const SELECTIONS_KEY = 'selections';
export const SELECT_STATUS_LABEL_KEY = 'selectStatus';
export const SEND_FEEDBACK_KEY = 'sendFeedback'
export const SOCIAL_MEDIA_LABEL_KEY = 'socialMedia';
export const UPLOAD_KEY = 'upload';
export const UPLOAD_ASSETS_LABEL_KEY = 'uploadAssets';
export const VISIT_IN_BRANDFOLDER_KEY = 'visitInBrandfolder';

// dynamic labels translation keys
export const getCustomDimensionKey = (customDimension: CustomDimension) =>
  `customDimensions-${customDimension}`;
export const getCropTypeKey = (cropType: CropType) => `cropTypes-${cropType}`;
export const getPlatformKey = (platform: Platform) => `platforms-${platform}`;
export const getPlatformAssetTypeKey = (platform: Platform, assetType: PlatformAssetType) =>
  `platformAssetTypes-${platform}-${assetType}`;
export const getAssetStatusKey = (status: AssetStatus) => `assetStatuses-${status}`;
export const getAttachmentOrientationKey = (orientation: AttachmentOrientation) =>
  `attachmentOrientations-${orientation}`;
export const getSortOptionsKey = (options: SortOptions) =>
  `sortableFields-${options.field}_${options.direction}`;
