import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
} from '@integration-frontends/common/auth/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { Store } from '@reduxjs/toolkit';
import { diContainer } from '@smartsheet/cufflink';
import {
  ContentLibraryStateService,
  contentLibraryStateServiceDIKey,
} from '@smartsheet/content-library-state-service';
import { push } from 'redux-first-history';
import { getAppRoutes } from '@integration-frontends/core/app-routes';

export const FAILURE_CALLBACK_TOKEN = 'FAILURE_CALLBACK';

export interface IFailureCallback {
  (): Promise<string>;
}

export const createFailureCallback = (store: Store<unknown>): (() => Promise<string>) => {
  let inProgress = false;

  return async () => {
    const contentLibraryStateService = diContainer.get<ContentLibraryStateService>(
      contentLibraryStateServiceDIKey,
    );
    if (inProgress) {
      return await new Promise((resolve) => {
        const listener = (e: CustomEvent) => {
          window.removeEventListener('set-identity', listener);
          resolve(e.detail);
        };
        window.addEventListener('set-identity', listener);
      });
    } else {
      let token: string;
      inProgress = true;
      const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);
      try {
        const authResponse = await authService.refreshAuthentication();
        token = authResponse.jwt.token;
        const userPermissions = authResponse.workspace.user;
        contentLibraryStateService.setBearerToken(token);
        contentLibraryStateService.setUserPermissions(userPermissions);
      } catch (error) {
        store.dispatch(push(getAppRoutes().AUTH_ERROR));
      }
      inProgress = false;
      const identityEvent = new CustomEvent('set-identity', { detail: token });
      window.dispatchEvent(identityEvent);
      return token;
    }
  };
};

export function getRefreshAuthenticationPath() {
  const regex = /\/workspaces\/([a-zA-Z0-9]+)\//;
  const match = window.location.pathname.match(regex);
  const workspaceId = (match && match[1]) || '';
  return `/2.0/content-integration/v1/workspaces/${workspaceId}/authenticate`;
}
