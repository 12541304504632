import { put, select, takeEvery } from 'typed-redux-saga';
import { showMaxSelectableAssetsToast, toggleAssetSelection } from '../actions';
import { selectAssetSelectors } from '../../../selectors';
import { DEFAULT_SELECTED_ASSETS_LIMIT } from '../reducer';

const handler = function* () {
  const selectedAssetsCount = yield select(selectAssetSelectors.selectedAssetsCount);

  if (selectedAssetsCount === DEFAULT_SELECTED_ASSETS_LIMIT) {
    yield put(showMaxSelectableAssetsToast());
  }
};

export function* toggleAssetSelectionEffects() {
  yield takeEvery(toggleAssetSelection, handler);
}
