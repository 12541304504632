import { DI_CONTAINER } from '@integration-frontends/core';
import {
  organizationEntityActions,
  SelectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import { getRehydratedState } from '@integration-frontends/integration/core/application/select-attachment/state/effects/common/get-rehydrated-state';
import {
  IOrganizationRepo,
  ORGANIZATION_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import { put, takeEvery } from 'redux-saga/effects';
import { all, call, take } from 'typed-redux-saga';
import {
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '../../select-attachment-options';
import { selectAttachmentInit, selectAttachmentInitSuccess } from '../actions';

const handler = function* () {
  const organizationRepo: IOrganizationRepo = DI_CONTAINER.get(ORGANIZATION_REPO_TOKEN);

  const organizations = yield call(organizationRepo.listOrganizations);
  yield put(organizationEntityActions.organizationsReceived(organizations));
  yield dispatchSuccess();
};

function* dispatchSuccess() {
  const selectAttachmentOptions: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );

  yield put(selectAttachmentInitSuccess({ selectAttachmentOptions: selectAttachmentOptions }));
}

export function* initEffects(selectAttachmentSelectors: SelectAttachmentSelectors) {
  const [, rehydratedState] = yield all([take(selectAttachmentInit), call(getRehydratedState)]);

  // checking if the persisted state had successfully loaded data
  const initSuccess = rehydratedState && selectAttachmentSelectors.initSuccess(rehydratedState);

  if (initSuccess) {
    yield dispatchSuccess();
  } else {
    yield handler();
  }

  yield takeEvery(selectAttachmentInit, handler);
}
