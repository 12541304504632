import { createSelector } from 'reselect';
import { DeleteAssetsState } from './reducer';

const selectLoading = (state: DeleteAssetsState) => state.loading;

export function createDeleteAssetsSelectors(
  rootStateSelector: (rootState) => DeleteAssetsState,
) {
  return {
    loading: createSelector(rootStateSelector, selectLoading),
  };
}
