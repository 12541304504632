import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import {
  AssetsListResultSet,
  Container,
  PagedResults,
} from '@integration-frontends/integration/core/model';

export function createAssetsListActions(namespace: string) {
  return {
    listInit: createAction(`${namespace}_ASSETS_LIST_INITIALIZED`),
    nextPage: createAction(
      `${namespace}_ASSETS_LIST_NEXT_PAGE`,
      withPayloadType<{ container: Container }>(),
    ),
    nextPageBase: createAction(
      `${namespace}_ASSETS_LIST_NEXT_PAGE_BASE`,
      withPayloadType<{ container: Container }>(),
    ),
    previousPage: createAction(
      `${namespace}_ASSETS_LIST_PREVIOUS_PAGE`,
      withPayloadType<{ container: Container }>(),
    ),
    previousPageBase: createAction(
      `${namespace}_ASSETS_LIST_PREVIOUS_PAGE_BASE`,
      withPayloadType<{ container: Container }>(),
    ),
    pageLoaded: createAction(
      `${namespace}_ASSETS_LIST_PAGE_LOADED`,
      withPayloadType<{ results: PagedResults<AssetsListResultSet> }>(),
    ),
    toggleShowAttachment: createAction(`${namespace}_ASSETS_LIST_TOGGLE_SHOW_ATTACHMENT`),
  };
}
