import { takeEvery } from 'redux-saga/effects';
import { addFilesProcessing, updateAsset } from '../actions';
import { put } from 'typed-redux-saga';
import { handleProcessFile } from './upload';

const handler = function* (action: ReturnType<typeof updateAsset>) {
  const { assetId } = action.payload;
  yield put(addFilesProcessing({ assetIds: [assetId] }));
  yield handleProcessFile(assetId);
};

export function* updateAssetsEffects() {
  yield takeEvery(updateAsset, handler);
}
