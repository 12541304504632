import { createSelector } from 'reselect';
import { AppRootState, AppState } from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const selectAppState: StateSelector<AppState> = (state: AppRootState) => state.app;
export const selectInitSuccess = createSelector(selectAppState, (state) => state.success);
export const selectInitLoading = createSelector(selectAppState, (state) => state.loading);
export const selectName = createSelector(selectAppState, (state) => state.name);
export const selectRehydrated = createSelector(selectAppState, (state) => state.rehydrated);
export const selectHeaderBackgroundUrl = createSelector(selectAppState, (state) => state.headerBackgroundUrl);
