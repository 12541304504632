import { SearchParameters } from '@integration-frontends/integration/core/model';
import React from 'react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import { FILTERS_DISPLAY_COMPONENT_KEY, FiltersDisplay } from './filters-display';
import { useSearch } from '../search/use-search';

export interface FiltersDisplayContainerProps {
  searchParams: SearchParameters;
}

export function FiltersDisplayContainer({ searchParams }: FiltersDisplayContainerProps) {
  const { clear } = useSearch();
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const FiltersDisplayComponent =
    componentResolver.getComponent(FILTERS_DISPLAY_COMPONENT_KEY) || FiltersDisplay;

  return <FiltersDisplayComponent searchParams={searchParams} onClear={clear} />;
}
