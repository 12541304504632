import { put, takeEvery } from 'redux-saga/effects';
import { searchRefresh } from '../../../search-assets';
import { uploadSuccess } from '../actions';

const handler = function* () {
  yield put(searchRefresh());
};

export function* uploadSuccessEffects() {
  yield takeEvery(uploadSuccess, handler);
}
