import { pageLoaded, selectRehydrated } from '@integration-frontends/common/app';
import { SearchAssetsGroupBy } from '@integration-frontends/integration/core/model';
import { put, take, takeEvery } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';
import { search, searchAssetsActions } from '../../../../search-assets';
import { showPageSearch } from '../../actions';

function* handler(action: ReturnType<typeof showPageSearch>) {
  const { pageSize = 16, groupBy = SearchAssetsGroupBy.Section } =
    action.payload.searchParams || {};
  yield put(searchAssetsActions.changePageSize({ pageSize }));
  yield put(searchAssetsActions.changeGroupBy({ groupBy: groupBy as SearchAssetsGroupBy }));
  yield put(search(action.payload));
}

export function* showPageSearchEffects() {
  const showPageSearchAction = yield take(showPageSearch);
  const isRehydrated = yield select(selectRehydrated);

  // ignore first search if using cached data
  if (!isRehydrated) {
    yield handler(showPageSearchAction);
  }

  yield put(pageLoaded());
  yield takeEvery(showPageSearch, handler);
}
