import { injectable } from "inversify";

export const ANALYTICS_SERVICE_TOKEN = 'ANALYTICS_SERVICE_TOKEN';

export interface IAnalyticsService {
    trackEvent: (eventId: string, properties?: Record<string, unknown>) => void;
}

@injectable()
export class AnalyticsServiceStrategy implements IAnalyticsService {
    trackEvent(eventId: string, properties?: Record<string, unknown>): void {
        console.log(eventId, properties);
    }
}