import {
  AbsoluteDimensions,
  Dimensions,
  getAspectRatio,
  RelativeDimensions,
} from '@integration-frontends/integration/core/model';
import { mergeRight } from 'ramda';

export interface Resizable<T = AbsoluteDimensions | RelativeDimensions> {
  initialDimensions: T;
  newDimensions: T;
  lockAspectRatio: boolean;
}

export function setWidth<T extends Resizable>(props: T, width: number): T {
  return mergeRight(props, {
    newDimensions: {
      width,
      height: props.lockAspectRatio
        ? width / getAspectRatio(props.initialDimensions)
        : props.newDimensions?.height,
    },
  }) as T;
}

export function setHeight<T extends Resizable>(props: T, height: number): T {
  return mergeRight(props, {
    newDimensions: {
      width: props.lockAspectRatio
        ? height * getAspectRatio(props.initialDimensions)
        : props.newDimensions?.width,
      height,
    },
  }) as T;
}

export function setDimensions<T extends Resizable>(props: T, dimensions: Dimensions): T {
  return mergeRight(props, {
    newDimensions: dimensions,
  }) as T;
}

export function setLockAspectRatio<T extends Resizable>(props: T, lockAspectRatio: boolean): T {
  return mergeRight(props, {
    lockAspectRatio,
  }) as T;
}
