import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Asset,
  Attachment,
  IMediaTypeSupportService,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
} from '@integration-frontends/integration/core/model';
import React, { useState } from 'react';
import { AttachmentDragAndDrop } from '../../../../common/attachment-drag-and-drop';
import { ThumbnailOverlay } from '../../thumbnail-overlay';
import { AssetThumbnailBase } from './asset-thumbnail-base';
import { AssetThumbnailMenu } from './asset-thumbnail-menu';
import { AssetThumbnailProps } from './asset-thumbnail-props';

export function DraggableAssetThumbnail(props: AssetThumbnailProps) {
  const mediaTypeSupportService = DI_CONTAINER.get<IMediaTypeSupportService>(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );

  return (
    <AttachmentDragAndDrop
      attachment={props.attachments.find((att) =>
        mediaTypeSupportService.isSupported(att.mediaType),
      )}
      style={{ padding: 15, margin: '0 -10px 0 -10px' }}
    >
      <AssetThumbnailBase {...props} overlay={<Overlay {...props} />} />
    </AttachmentDragAndDrop>
  );
}

interface OverlayProps {
  asset: Asset;
  attachments: Attachment[];
  containerId: string;
}

function Overlay({ asset, containerId }: OverlayProps) {
  const [menuFocus, setMenuFocus] = useState(false);

  return (
    <ThumbnailOverlay
      className={`asset-overlay ${menuFocus && 'show'}`}
      topElement={
        <AssetThumbnailMenu
          asset={asset}
          containerId={containerId}
          onFocus={() => setMenuFocus(true)}
          onBlur={() => setMenuFocus(false)}
        />
      }
    />
  );
}
