/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { put, select, takeEvery } from 'typed-redux-saga';
import { clearPinnedUploads } from '../../actions';
import {
  search,
  baseSearch,
} from '@integration-frontends/integration/core/application/search-assets';
import { contentLibraryUploadAssetsSelectors } from '@integration-frontends/integration/core/application/selectors';
import {
  Banner,
  closeBanner,
  selectOpenBanners,
} from '@integration-frontends/common/notifications';

export function* clearPinnedUploadsEffects() {
  yield takeEvery([search, baseSearch], handler);
}

const handler = function* () {
  const pinnedUploads = yield select(contentLibraryUploadAssetsSelectors.pinnedUploads);
  const openBanners: Banner[] = yield select(selectOpenBanners);
  const openClearFilterBanner = openBanners.find(
    (banner) => banner.id.startsWith('clear-filters-banner-') && !banner.closed,
  );

  if (pinnedUploads.length) {
    yield put(clearPinnedUploads());
  }
  if (openClearFilterBanner) {
    yield put(closeBanner({ id: openClearFilterBanner.id }));
  }
};
