import { StandardTextfield, StandardTextfieldProps } from '@brandfolder/react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import React, { FC, forwardRef } from 'react';

export const BF_STANDARD_TEXTFIELD_COMPONENT = 'BF_STANDARD_TEXTFIELD_COMPONENT';
export type BFStandardTextfieldProps = Omit<StandardTextfieldProps, 'label'> & {
  label?: StandardTextfieldProps['label'];
  variant?: "box" | "underline";
};

export const BFStandardTextfield: FC<BFStandardTextfieldProps> = forwardRef((props, ref) => {
  const componentResolver = DI_CONTAINER.get<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
  const TextfieldComponent: FC<BFStandardTextfieldProps> =
    componentResolver.getComponent(BF_STANDARD_TEXTFIELD_COMPONENT) || StandardTextfield;

  return <TextfieldComponent {...props} ref={ref}/>;
});
