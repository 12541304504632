import { DI_CONTAINER } from '@integration-frontends/core';
import { sectionEntityActions, containerTagsActions } from '@integration-frontends/integration/core/application/common';
import {
  ISectionRepo,
  ResourceType,
  SECTION_REPO_TOKEN,
  IBrandfolderRepo,
  BRANDFOLDER_REPO_TOKEN,
  ICollectionRepo,
  COLLECTION_REPO_TOKEN,
  IContainerRepo,
  CONTAINER_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import { call, put, takeEvery } from 'redux-saga/effects';
import { selectContainer } from '../actions';

const handler = function* (action: ReturnType<typeof selectContainer>) {
  const sectionRepo: ISectionRepo = DI_CONTAINER.get(SECTION_REPO_TOKEN);
  const brandfolderRepo: IBrandfolderRepo = DI_CONTAINER.get(BRANDFOLDER_REPO_TOKEN);
  const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);
  const collectionRepo: ICollectionRepo = DI_CONTAINER.get(COLLECTION_REPO_TOKEN);
  const {container} = action.payload;
  const sections = yield call(
    container.type === ResourceType.BRANDFOLDER
      ? sectionRepo.listBrandfolderSections
      : sectionRepo.listCollectionSections,
    container.id,
  );

  const tags = yield call(containerRepo.getContainerTags, container);
  yield put(containerTagsActions.containerTagsReceived(tags));
  yield put(sectionEntityActions.sectionsReceived(sections));
};

export function* containerSelectedEffects() {
  yield takeEvery(selectContainer, handler);
}
