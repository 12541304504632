import { AttachmentPlacementDetailsContainer, keyToClick } from '@integration-frontends/common/ui';
import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import {
  attachmentEntitySelectors,
  containerSelectors,
} from '@integration-frontends/integration/core/application';
import {
  Asset,
  Attachment,
  positionComparator,
} from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IconViewOnly } from '../../../../common/icons';
import { AttachmentOptionsMenu } from '../../../common/attachment-options-menu';
import './asset-attachments.scss';
import { AttachmentThumbnail } from './attachment-thumbnail';
import { t } from '@lingui/macro';

export interface AssetAttachmentsProps {
  asset: Asset;
  containerId: string;
  onAttachmentClick: (attachment: Attachment) => void;
  selectedAttachmentId?: string;
}

export function AssetAttachments({
  asset,
  containerId,
  onAttachmentClick,
  selectedAttachmentId,
}: AssetAttachmentsProps) {
  const container = useSelector((state) => containerSelectors.selectById(state, containerId));
  const attachments = useSelector(attachmentEntitySelectors.byAssetId(asset.id)).sort(
    positionComparator,
  );

  const attachmentClicked = useCallback(
    (attachment: Attachment) => {
      onAttachmentClick(attachment);
    },
    [asset],
  );

  return (
    <ul>
      {attachments.map((attachment) => (
        <li
          data-cy="attachment"
          data-testid="attachment"
          key={attachment.id}
          className="gap-lg mb-md flex justify-between"
        >
          <div
            className="gap-lg flex flex-1 cursor-pointer items-center"
            onClick={() => attachmentClicked(attachment)}
            onKeyDown={keyToClick}
            tabIndex={0}
          >
            <AttachmentThumbnail
              asset={asset}
              attachment={attachment}
              selected={attachment.id === selectedAttachmentId && asset.attachmentCount > 1}
            />

            <div className="flex items-center">
              <div className="flex flex-col justify-center">
                <span className="asset-attachment-name font-bold">{attachment.name}</span>
                <span className="text-primary text-sm leading-snug">
                  {attachment.extension.toLocaleUpperCase()} &#8901;{' '}
                  {(attachment.sizeInBytes / 1000).toFixed(1)} {t`KB`}
                </span>
                <span className="text-primary text-sm leading-snug">
                  {attachment.dimensions.width} x {attachment.dimensions.height} px &#8901;{' '}
                  {attachment.updatedAt && attachment.updatedAt.toString() !== 'Invalid Date' &&
                    format(new Date(attachment.updatedAt), DEFAULT_DATE_FORMAT)}
                </span>
                <AttachmentPlacementDetailsContainer
                  attachmentID={attachment.id}
                  assetID={asset.id}
                />
              </div>
            </div>
          </div>

          {asset?.availability === 'view_only' && (
            <div className="flex items-center justify-center">
              <IconViewOnly />
            </div>
          )}

          <div className="flex items-center justify-self-end">
            <AttachmentOptionsMenu
              asset={asset}
              attachment={attachment}
              container={container}
              buttonTransparent={true}
            />
          </div>
        </li>
      ))}
    </ul>
  );
}
