import { ButtonLooks, ButtonSizes, FontIcons } from '@brandfolder/react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';

export const BF_STANDARD_ICON_BUTTON = 'BF_STANDARD_ICON_BUTTON';
export type BFStandardIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: FontIcons.Filters;
  look: ButtonLooks.Primary | ButtonLooks.Secondary | 'canvas-empty';
  size: ButtonSizes.Large | ButtonSizes.Medium;
};
export type BFStandardIconButtonComponent = ForwardRefExoticComponent<
  BFStandardIconButtonProps & RefAttributes<HTMLButtonElement>
>;

export const BFStandardIconButton = forwardRef<HTMLButtonElement, BFStandardIconButtonProps>(
  (props, ref) => {
    const componentResolver = DI_CONTAINER.get<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
    const IconButtonComponent =
      componentResolver.getComponent<BFStandardIconButtonComponent>(BF_STANDARD_ICON_BUTTON);

    return <IconButtonComponent ref={ref} {...props} />;
  },
);
