import { BFStorage } from '@integration-frontends/core/storage';

export enum StorageMethods {
  GetItem = 'getItem',
  SetItem = 'setItem',
}

const myLocalStorage = {
  storage: {},
  getItem(key: string): string | null {
    return this.storage[key] || null;
  },

  setItem(key: string, value: string): string {
    return (this.storage[key] = value);
  },
};

export const useStorage = (func: StorageMethods, key: string, value?: string): string | null => {
  try {
    return window.localStorage[func](key, value) || null;
  } catch (error) {
    console.log(error);
    return myLocalStorage[func](key, value);
  }
};

export const safeLocalStorage: BFStorage = {
  getItem(key: string): Promise<unknown> {
    return Promise.resolve(useStorage(StorageMethods.GetItem, key));
  },
  async removeItem(key: string): Promise<void> {
    useStorage(StorageMethods.SetItem, key, null);
  },
  async setItem(key: string, item: string): Promise<void> {
    useStorage(StorageMethods.SetItem, key, item);
  },
};
