import { mergeRight } from 'ramda';
import { PartialDeep } from 'type-fest';

export interface LinkOptions {
  link: string;
}

export function createLinkOptions(overrides: PartialDeep<LinkOptions> = {}): LinkOptions {
  const defaults: LinkOptions = { link: '' };
  return mergeRight(defaults, overrides);
}

export function setLink(link: string, options: LinkOptions): LinkOptions {
  return mergeRight(options, { link });
}
