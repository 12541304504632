import {
  Banner,
  BannerAction,
  BannerActions,
  BannerActionSeparator,
  BannerContent,
  BannerDismissButton,
  BannerLink,
} from '@smartsheet/lodestar-core';
import React from 'react';
import { BFStandardBannerComponent } from './bf-standard-banner-base';
import { useLingui } from '@lingui/react';

export const BFStandardBannerSmartsheet: BFStandardBannerComponent = ({
  bannerProps,
  contentProps,
  linkProps,
  actionsProps,
  actionProps,
  actionSeperatorProps,
  dismissButtonProps,
}) => {
  const { _: translate } = useLingui();
  // criteria to display the content sections
  const displayBannerContent = contentProps?.contentText || linkProps?.linkText;
  const displayBannerLink = linkProps?.linkText;

  // criteria to display the actions sections
  const displayBannerActions = dismissButtonProps?.['aria-label'] || actionProps?.actionText;
  const displayBannerAction = actionProps?.actionText;
  const displayBannerActionSeperator =
    dismissButtonProps?.['aria-label'] && actionProps?.actionText;
  const displayBannerDismissButton = dismissButtonProps?.['aria-label'];

  return (
    <Banner {...bannerProps}>
      {displayBannerContent && (
        <BannerContent {...contentProps}>
          {translate(contentProps?.contentText)}
          {displayBannerLink && (
            <BannerLink {...linkProps} href={linkProps?.linkHref}>
              {linkProps?.linkText}
            </BannerLink>
          )}
        </BannerContent>
      )}
      {displayBannerActions && (
        <BannerActions {...actionsProps}>
          {displayBannerAction && (
            <BannerAction {...actionProps}>{translate(actionProps?.actionText)}</BannerAction>
          )}
          {displayBannerActionSeperator && <BannerActionSeparator {...actionSeperatorProps} />}
          {displayBannerDismissButton && <BannerDismissButton {...dismissButtonProps} />}
        </BannerActions>
      )}
    </Banner>
  );
};
