import { createReducer } from '@reduxjs/toolkit';
import {
  bulkRemoveAssetsFromCollectionsInit,
  bulkRemoveAssetsFromCollectionsSuccess,
  deleteError,
} from './actions';

export interface DeleteAssetsState {
  loading: boolean;
}

export const deleteAssetsInitialState: DeleteAssetsState = {
  loading: false,
};

export const DELETE_ASSETS_REDUCER = createReducer(deleteAssetsInitialState, (builder) =>
  builder
    .addCase(bulkRemoveAssetsFromCollectionsInit, (state) => {
      state.loading = true;
    })
    .addCase(bulkRemoveAssetsFromCollectionsSuccess, (state) => {
      state.loading = false;
    })
    .addCase(deleteError, (state) => {
      state.loading = false;
    }),
);
