import { sendSegmentAction } from '@integration-frontends/common/analytics';
import {
  ANALYTICS_SERVICE_TOKEN,
  DI_CONTAINER,
  IAnalyticsService,
} from '@integration-frontends/core';
import {
  ASSET_REPO_TOKEN,
  AssetsListResultSet,
  Container,
  IAssetRepo,
  ListOptions,
  PagedResults,
  ResourceBaseSortableProperty,
  SearchParameters,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { prop } from 'ramda';
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { assetEntityActions } from '../../../common';
import {
  baseSearch,
  searchAssetsActions,
  searchError,
  searchRefresh,
  searchSuccess,
} from '../actions';
import { SearchAssetsSelectors } from '../selectors';

const DEFAULT_SORT_OPTIONS: SortOptions = {
  direction: SortDirection.Asc,
  field: ResourceBaseSortableProperty.CreatedAt,
};

export const SEARCH_DEBOUNCE = 500;

const handler = function* (
  action: ReturnType<typeof baseSearch>,
  searchAssetsSelectors: SearchAssetsSelectors,
) {
  let searchParams: SearchParameters;
  const { searchParams: searchParamsFromPayload, sortOptions } = action.payload;
  searchParams = { ...searchParamsFromPayload };
  const analyticsService: IAnalyticsService = DI_CONTAINER.get(ANALYTICS_SERVICE_TOKEN);

  const pageSize = yield select(searchAssetsSelectors.pageSize);
  const container = yield select(searchAssetsSelectors.container);

  if (searchParams.query) {
    analyticsService.trackEvent('SearchExecute');
  }

  yield handleByContainer(container, searchParams, pageSize, sortOptions);
};

const handleByContainer = function* (container, searchParams, pageSize, sortOptions) {
  const results: PagedResults<AssetsListResultSet> = yield call(
    fetchContainerAssets,
    container,
    searchParams,
    pageSize,
    sortOptions,
  );

  // all pagination methods should return a recordCount
  const searchFailed = results?.recordCount === undefined;

  if (searchFailed) {
    yield put(searchError({ error: new Error('Search failed') }));
  } else {
    const { assets } = results.data;

    yield put(searchAssetsActions.listInit());
    yield put(searchAssetsActions.pageLoaded({ results }));

    yield put(assetEntityActions.assetsReceived(assets));

    yield put(sendSegmentAction({ event: 'searchAssetsSuccess' }));
    yield put(searchSuccess({ resultIds: assets.map(prop('id')) }));
  }
};

function fetchContainerAssets(
  container: Container,
  searchParams: SearchParameters,
  pageSize: number,
  sortOptions: SortOptions,
) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const options: ListOptions = {
    searchParams,
    pagination: { perPage: pageSize },
    sort: sortOptions || DEFAULT_SORT_OPTIONS,
  };

  return assetRepo.listContainerAssetsBase(container, options);
}

export function* baseSearchEffects(searchAssetsSelectors: SearchAssetsSelectors) {
  yield takeLatest(baseSearch, function* (action) {
    yield delay(SEARCH_DEBOUNCE);

    while (true) {
      try {
        yield handler(action, searchAssetsSelectors);
      } catch (error) {
        yield put(searchError({ error }));
      }

      yield take(searchRefresh);
    }
  });
}
