import { Tag } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React from 'react';
import './tags.scss';

export interface TagsProps {
  tags: Tag[];
}

export function Tags({ tags }: TagsProps) {
  return (
    <ul className="flex flex-wrap gap-sm">
      {tags.map((tag) => (
        <li key={tag.id}>
          <span
          key={tag.id}
          className={classNames(
            'tag',
            'flex items-center',
            'px-sm',
            'border border-bf-dark-gray rounded',
            {
              'border-dashed': tag.autoGenerated,
            },
          )}
          style={{ height: 28 }}
        >
          {tag.name}
          </span>
        </li>
      ))}
    </ul>
  );
}
