import { DI_CONTAINER } from '@integration-frontends/core';
import {
  clearSearch,
  ISelectAttachmentOptions,
  searchAssetsSelectors,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  showPageSearch,
} from '@integration-frontends/integration/core/application';
import {
  ResourceBaseSortableProperty,
  SearchParameters,
  SortDirection,
} from '@integration-frontends/integration/core/model';
import { SortOptions } from '@integration-frontends/integration/core/model/entities/common/sorting';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_SORT_OPTIONS: SortOptions = {
  direction: SortDirection.Desc,
  field: ResourceBaseSortableProperty.CreatedAt,
};

export function useSearch() {
  const dispatch = useDispatch();
  const [urlParams, setUrlParams] = useSearchParams();
  const searchParamsFromRoute = urlParams.get('searchParams');
  const sortOptionsFromRoute = urlParams.get('sortOptions');
  const { customSortOptions }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );
  const searchParamsFromState = useSelector(searchAssetsSelectors.searchParams);
  const sortOptionsFromState = useSelector(searchAssetsSelectors.sortOptions);

  function getSearchParams() {
    return searchParamsFromState || JSON.parse(searchParamsFromRoute)
      ? JSON.parse(searchParamsFromRoute)
      : {};
  }

  function getSortOptions() {
    return (
      sortOptionsFromState ||
      (sortOptionsFromRoute
        ? JSON.parse(sortOptionsFromRoute)
        : customSortOptions || DEFAULT_SORT_OPTIONS)
    );
  }

  function search(searchParams?: SearchParameters, sortOptions?: SortOptions) {
    searchParams = searchParams || getSearchParams();
    sortOptions = sortOptions || getSortOptions();

    dispatch(showPageSearch({ searchParams, sortOptions }));
    setUrlParams(
      new URLSearchParams({
        searchParams: JSON.stringify(searchParams),
        sortOptions: JSON.stringify(sortOptions),
      }),
    );
  }

  function clear() {
    dispatch(clearSearch({ executeSearch: true }));
    setUrlParams(new URLSearchParams({}));
  }

  return {
    searchParams: getSearchParams(),
    sortOptions: getSortOptions(),
    search,
    clear,
  };
}
