import {
  BFSearchInput,
  BFStandardCheckbox,
  BFToggleSwitch,
  InputSize,
} from '@integration-frontends/common/ui';
import {
  ContainerTag,
  hasTagFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React, { useState } from 'react';
import {
  CheckboxListContainer,
  CheckboxListItemContainer,
  SectionContent,
  SectionHeader,
} from '../../common';
import { Trans, t } from '@lingui/macro';
import { orderBy } from 'lodash';

export interface TopTagsFiltersComponentProps {
  searchParams: SearchParameters;
  tags: ContainerTag[];
  onTagToggle: (tag: ContainerTag) => void;
  onOperatorChange: (operator: SearchParameters['tagFilters']['operator']) => void;
}

export function TopTagsFiltersComponent({
  searchParams,
  tags,
  onTagToggle,
  onOperatorChange,
}: TopTagsFiltersComponentProps) {
  const [search, setSearch] = useState('');

  const sortedTags = orderBy(tags, ['name']);

  return (
    <fieldset data-testid="top-tags-filters">
      <SectionHeader>
        <div className="flex justify-between">
          <legend className="text-primary">
            <Trans>Top Tags</Trans>
          </legend>

          <div className="flex items-center">
            <span>
              <Trans>Any</Trans>
            </span>
            <BFToggleSwitch
              className="mx-sm"
              checked={searchParams?.tagFilters?.operator === 'AND'}
              onChange={() =>
                onOperatorChange(searchParams?.tagFilters?.operator === 'AND' ? 'OR' : 'AND')
              }
            />
            <span>
              <Trans>All</Trans>
            </span>
          </div>
        </div>
      </SectionHeader>

      <SectionContent className="pb-lg">
        <CheckboxListContainer className="mb-xl">
          {sortedTags
            .filter((tag) => search === '' || tag.name.toLowerCase().includes(search.toLowerCase()))
            .slice(0, 10)
            .map((tag) => (
              <CheckboxListItemContainer key={tag.id}>
                <div
                  data-testid="tag-filter"
                  className="gap-xs flex cursor-pointer justify-between"
                  onClick={() => onTagToggle(tag)}
                >
                  <div className="flex min-w-0 items-center">
                    <BFStandardCheckbox
                      value={tag.id}
                      id={tag.id}
                      className="flex-grow"
                      checked={hasTagFilter(searchParams, tag)}
                      style={{ marginRight: 10 }}
                      label={tag.name}
                      readOnly
                    />
                  </div>
                </div>
              </CheckboxListItemContainer>
            ))}
        </CheckboxListContainer>

        <BFSearchInput
          className="w-full"
          size={InputSize.Small}
          placeholder={t`Search Tags`}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </SectionContent>
    </fieldset>
  );
}
