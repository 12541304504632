import { all, fork } from 'redux-saga/effects';
import { uploadFailureEffects } from './upload-failure';
import {
  cancelAssetUploadEffects,
  uploadEffects,
  retryAssetUploadEffects,
  uppyUploadSuccessEffects,
  clearPinnedUploadsEffects,
} from './upload';
import { updateAssetsEffects } from './update-assets';
import { uppyUploadFilesAddedEffects } from './upload/uppy-upload-files-added';

export function* contentLibraryUploadAssetsEffects() {
  yield all([
    fork(uploadEffects),
    fork(uppyUploadSuccessEffects),
    fork(uppyUploadFilesAddedEffects),
    fork(clearPinnedUploadsEffects),
    fork(retryAssetUploadEffects),
    fork(updateAssetsEffects),
    fork(uploadFailureEffects),
    fork(cancelAssetUploadEffects),
  ]);
}
