import { createSelector } from 'reselect';
import { ContentLibraryUploadAssetsState } from './reducer';
import { some, filter } from 'lodash';

const selectLoading = (state: ContentLibraryUploadAssetsState) => state.loading;
const assetUploads = (state: ContentLibraryUploadAssetsState) => state.assetUploads;
const assetUploadById = (assetUploadId: string) =>
  createSelector(assetUploads, (assetUploads) =>
    assetUploads.find((assetUpload) => assetUpload.id === assetUploadId),
  );
const currentAssetUploadIndex = (state: ContentLibraryUploadAssetsState) =>
  state.currentAssetUploadIndex;
const currentAssetsInUpload = (state: ContentLibraryUploadAssetsState) =>
  state.currentAssetsInUpload;
const currentAssetsNotInUpload = (state: ContentLibraryUploadAssetsState) =>
  state.assetUploads.filter((assetUpload) => !state.currentAssetsInUpload.includes(assetUpload.id));
const isUploadOngoing = (state: ContentLibraryUploadAssetsState) =>
  some(
    state.assetUploads,
    (assetUpload) => assetUpload.status === 'initialUpload' || assetUpload.status === 'retrying',
  );
const onGoingAssetUploads = (state: ContentLibraryUploadAssetsState) =>
  filter(
    state.assetUploads,
    (assetUpload) => assetUpload.status === 'initialUpload' || assetUpload.status === 'retrying',
  );
const successfulAssetUploads = (state: ContentLibraryUploadAssetsState) =>
  filter(state.assetUploads, (assetUpload) => assetUpload.status === 'success');
const failedAssetUploads = (state: ContentLibraryUploadAssetsState) =>
  filter(state.assetUploads, (assetUpload) => assetUpload.status === 'failed');
const unresolvedAssetUploads = (state: ContentLibraryUploadAssetsState) =>
  filter(
    state.assetUploads,
    (assetUpload) =>
      assetUpload.statusHistory.includes('failed') &&
      assetUpload.statusHistory[assetUpload.statusHistory.length - 1] !== 'cancelled' &&
      assetUpload.statusHistory[assetUpload.statusHistory.length - 1] !== 'success',
  );
const successfulAssetUploadRetries = (state: ContentLibraryUploadAssetsState) =>
  filter(
    state.assetUploads,
    (assetUpload) =>
      assetUpload.statusHistory.includes('retrying') && assetUpload.status === 'success',
  );
const assetUploadsToDisplayInUploadManager = (state: ContentLibraryUploadAssetsState) => {
  const currentAssetInUploadArray = currentAssetsInUpload(state);
  return [...successfulAssetUploadRetries(state), ...unresolvedAssetUploads(state)].filter(
    (assetUpload) => {
      const isInCurrentAssets = currentAssetInUploadArray.includes(assetUpload.id);
      const isRetrying = assetUpload.statusHistory.includes('retrying');
      // We don't want to display assets that are currently uploading unless they are retrying
      return !isInCurrentAssets || (isInCurrentAssets && isRetrying);
    },
  );
};
const openManagerAfterUpload = (state: ContentLibraryUploadAssetsState) =>
  state.openManagerAfterUpload;
const successfulAssetUploadInSequence = (state: ContentLibraryUploadAssetsState) =>
  state.successfulAssetUploadInSequence;
const selectUploadManagerOpen = (state: ContentLibraryUploadAssetsState) => state.uploadManagerOpen;
const selectUploadSequenceId = (state: ContentLibraryUploadAssetsState) => state.uploadSequenceId;
const successfulAssetUploadInUpload = (state: ContentLibraryUploadAssetsState) =>
  filter(
    state.assetUploads,
    (assetUpload) =>
      assetUpload.status === 'success' && state.currentAssetsInUpload.includes(assetUpload.id),
  );
const selectFilesProcessing = (state: ContentLibraryUploadAssetsState) => state.filesProcessing;
const selectPinnedUploads = (state: ContentLibraryUploadAssetsState) => state.pinnedUploads;
const selectUploadExceedsPlanLimitError = (state: ContentLibraryUploadAssetsState) =>
  state.uploadExceedsFileLimitError;
const selectUploadExceedsPlanStorageLimitError = (state: ContentLibraryUploadAssetsState) =>
  state.uploadExceedsPlanStorageLimitError;
const selectUppyReadyForUpload = (state: ContentLibraryUploadAssetsState) =>
  state.filesAddedSuccess;

export function createContentLibraryUploadAssetSelectors(
  stateSelector: (rootState) => ContentLibraryUploadAssetsState,
) {
  const baseSelectors = {
    loading: createSelector(stateSelector, selectLoading),
    assetUploads: createSelector(stateSelector, assetUploads),
    assetUploadById: (assetUploadId: string) =>
      createSelector(stateSelector, assetUploadById(assetUploadId)),
    currentAssetUploadIndex: createSelector(stateSelector, currentAssetUploadIndex),
    currentAssetsInUpload: createSelector(stateSelector, currentAssetsInUpload),
    currentAssetsNotInUpload: createSelector(stateSelector, currentAssetsNotInUpload),
    assetUploadsToDisplayInUploadManager: createSelector(
      stateSelector,
      assetUploadsToDisplayInUploadManager,
    ),
    isUploadOngoing: createSelector(stateSelector, isUploadOngoing),
    onGoingAssetUploads: createSelector(stateSelector, onGoingAssetUploads),
    successfulAssetUploads: createSelector(stateSelector, successfulAssetUploads),
    failedAssetUploads: createSelector(stateSelector, failedAssetUploads),
    unresolvedAssetUploads: createSelector(stateSelector, unresolvedAssetUploads),
    successfulAssetUploadRetries: createSelector(stateSelector, successfulAssetUploadRetries),
    uploadManagerOpen: createSelector(stateSelector, selectUploadManagerOpen),
    uploadSequenceId: createSelector(stateSelector, selectUploadSequenceId),
    openManagerAfterUpload: createSelector(stateSelector, openManagerAfterUpload),
    successfulAssetUploadInSequence: createSelector(stateSelector, successfulAssetUploadInSequence),
    successfulAssetUploadInUpload: createSelector(stateSelector, successfulAssetUploadInUpload),
    filesProcessing: createSelector(stateSelector, selectFilesProcessing),
    pinnedUploads: createSelector(stateSelector, selectPinnedUploads),
    uploadExceedsFileLimitError: createSelector(stateSelector, selectUploadExceedsPlanLimitError),
    uploadExceedsPlanStorageLimitError: createSelector(
      stateSelector,
      selectUploadExceedsPlanStorageLimitError,
    ),
    uppyReadyForUpload: createSelector(stateSelector, selectUppyReadyForUpload),
  };

  return {
    ...baseSelectors,
    isFileProcessing: (assetId: string) =>
      createSelector(baseSelectors.filesProcessing, (filesProcessing) => {
        return filesProcessing.includes(assetId);
      }),
  };
}
