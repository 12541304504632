import { combineReducers } from '@reduxjs/toolkit';
import { ENTITIES_STATE_REDUCER, IntegrationEntitiesState } from './common/entities-state';
import { PLACE_ATTACHMENT_REDUCER, PlaceAttachmentState } from './place-attachment/state';

import { SEARCH_ASSETS_REDUCER, SearchAssetsState } from './search-assets/state';
import { SELECT_ATTACHMENT_REDUCER, SelectAttachmentState } from './select-attachment/state';
import { UPLOAD_ASSETS_REDUCER, UploadAssetsState } from './upload-assets/state';
import { AppState } from '@integration-frontends/common/app';
import { NotificationsState } from '@integration-frontends/common/notifications';
import { DOWNLOAD_ASSETS_REDUCER, DownloadAssetsState } from './download-assets';
import {
  CONTENT_LIBRARY_UPLOAD_ASSETS_REDUCER,
  ContentLibraryUploadAssetsState,
} from './content-library-upload-assets';
import { UPLOAD_TAGS_REDUCER, UploadTagsState } from './upload-tags/state/reducer';
import { SELECT_ASSET_REDUCER, SelectAssetState } from './select-assets/state/reducer';
import { DeleteAssetsState, DELETE_ASSETS_REDUCER } from './delete-assets';

export interface IntegrationRootState {
  app: AppState;
  notifications: NotificationsState;
  integrationApplication: {
    entities: IntegrationEntitiesState;
    downloadAssets: DownloadAssetsState;
    placeAttachment: PlaceAttachmentState;
    selectAttachment: SelectAttachmentState;
    selectAsset: SelectAssetState;
    searchAssets: SearchAssetsState;
    uploadAssets: UploadAssetsState;
    contentLibraryUploadAssets: ContentLibraryUploadAssetsState;
    uploadTags: UploadTagsState;
    deleteAssets: DeleteAssetsState;
  };
}

export function getIntegrationApplicationReducer() {
  return {
    integrationApplication: combineReducers({
      entities: ENTITIES_STATE_REDUCER,
      downloadAssets: DOWNLOAD_ASSETS_REDUCER,
      placeAttachment: PLACE_ATTACHMENT_REDUCER,
      selectAttachment: SELECT_ATTACHMENT_REDUCER,
      selectAsset: SELECT_ASSET_REDUCER,
      searchAssets: SEARCH_ASSETS_REDUCER,
      uploadAssets: UPLOAD_ASSETS_REDUCER,
      contentLibraryUploadAssets: CONTENT_LIBRARY_UPLOAD_ASSETS_REDUCER,
      uploadTags: UPLOAD_TAGS_REDUCER,
      deleteAssets: DELETE_ASSETS_REDUCER,
    }),
  };
}
