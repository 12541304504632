/**
 * React hook for translating strings.
 */

// TODO: integrate with translation service

export function useTranslate() {
  return {
    translate: (str: string) => str, // temporary implementation
  };
}
