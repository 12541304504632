import { mergeRight } from 'ramda';
import { FunctionComponent } from 'react';
import { DI_CONTAINER } from './di-container';

export const COMPONENT_RESOLVER_TOKEN = 'COMPONENT_RESOLVER';

export class ComponentResolver {
  private components: Record<string, FunctionComponent<any>> = {};

  constructor(overrides: Partial<Record<string, FunctionComponent<any>>> = {}) {
    this.components = mergeRight(this.components, overrides);
  }

  getComponent<T extends FunctionComponent<unknown>>(key: string): T {
    return this.components[key] as T;
  }
}

export function useComponentResolver<T>(key: string) {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  return componentResolver.getComponent(key) as FunctionComponent<T>;
}
