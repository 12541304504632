import { BFLabel, BFSelect, BFToggleSwitch } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ISelectAttachmentOptions,
  searchAssetsActions,
  searchAssetsSelectors,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import {
  SearchAssetsGroupBy,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import {
  ResourceBaseSortableProperty,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model/entities/common/sorting';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionContent } from '../advanced-filters/common';
import { SortingSelector } from '../advanced-filters/components/sorting-selector/sorting-selector';
import './view-options.scss';
import { Trans } from '@lingui/macro';

const DEFAULT_SORT_OPTIONS: SortOptions = {
  direction: SortDirection.Asc,
  field: ResourceBaseSortableProperty.Position,
};

export interface ViewOptionsDropDownProps {
  search;
  containerId;
  searchParams: SearchParameters;
  sortOptions: SortOptions<ResourceBaseSortableProperty>;
  onApply: (searchParams: SearchParameters, sortOptions: SortOptions) => void;
  responsive?: boolean;
  showAdvancedFiltersView?: boolean;
  showAttachments: boolean;
  id?: string;
}

export function ViewOptionsDropDown({
  search,
  sortOptions = DEFAULT_SORT_OPTIONS,
  searchParams = {},
  onApply,
  showAttachments,
  id,
}: ViewOptionsDropDownProps) {
  const { showAttachmentsToggleDisabled = false }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );
  const [localSortOptions, setLocalSortOptions] = useState(sortOptions);
  const [selectedAssetNumber, setSelectedAssetNumber] = useState(16);
  const pageSize = useSelector(searchAssetsSelectors.pageSize);
  const groupBy = useSelector(searchAssetsSelectors.groupBy);
  const dispatch = useDispatch();

  function sortOptionsChanged(sortOptions: SortOptions<ResourceBaseSortableProperty>) {
    setLocalSortOptions(sortOptions);
    search(searchParams, sortOptions);
  }

  function onSetSelectedAssetNumber(pageSize: number) {
    setSelectedAssetNumber(pageSize);
    dispatch(searchAssetsActions.changePageSize({ pageSize }));
    onApply({ ...searchParams, pageSize }, sortOptions);
  }

  function onChangeGroupBy() {
    if (groupBy === SearchAssetsGroupBy.Section) {
      dispatch(searchAssetsActions.changeGroupBy({ groupBy }));
      onApply({ ...searchParams, groupBy: SearchAssetsGroupBy.Container }, sortOptions);
    } else {
      dispatch(searchAssetsActions.changeGroupBy({ groupBy }));
      onApply({ ...searchParams, groupBy: SearchAssetsGroupBy.Section }, sortOptions);
    }
  }

  return (
    <div
      id={id}
      data-testid="view-options"
      className="view-options-dropdown py-md mt-xxs z-200 shadow-border flex flex-col"
    >
      <p className="view-options-title">
        <Trans>View Options</Trans>
      </p>
      {!showAttachmentsToggleDisabled && (
        <div className="py-sm px-md view-options-item flex items-center">
          <BFToggleSwitch
            data-cy="show-attachments-toggle"
            data-testid="show-attachments-toggle"
            disabled={showAttachmentsToggleDisabled}
            className={classNames(
              {
                'opacity-40': showAttachmentsToggleDisabled,
              },
              'mr-sm',
            )}
            checked={showAttachments}
            onChange={() => dispatch(searchAssetsActions.toggleShowAttachments())}
          />{' '}
          <BFLabel>
            <Trans>Expand to show attachments</Trans>
          </BFLabel>
        </div>
      )}
      <div className="view-options-item px-md flex items-center">
        <BFToggleSwitch
          data-cy="organize-by-section-toggle"
          className="mr-sm"
          checked={groupBy === SearchAssetsGroupBy.Section}
          onChange={onChangeGroupBy}
        />{' '}
        <BFLabel>
          <Trans>Organize by section</Trans>
        </BFLabel>
      </div>
      <SectionContent className="view-options-item px-md">
        <div className="gap-sm flex items-center justify-between">
          <BFLabel className="text-primary whitespace-nowrap">
            <Trans>Assets per page</Trans>
          </BFLabel>
          <div style={{ minWidth: pageSize === 120 ? 95 : 90 }}>
            <BFSelect
              required
              options={[16, 32, 64, 120].map((field) => ({
                label: field,
                value: field,
              }))}
              value={pageSize}
              onOptionChange={(option) => onSetSelectedAssetNumber(option.value as number)}
              className="select"
              isClearable={false}
            />
          </div>
        </div>
      </SectionContent>
      <SortingSelector sortOptions={localSortOptions} onChange={sortOptionsChanged} />
    </div>
  );
}
