import React from 'react';
import './icon-view-only.scss';

export const IconViewOnlyEnabled = () => {
  return (
    <div
      data-testid="icon-view-only-enabled"
      className="bg-bf-primary flex h-[32px] w-[32px] items-center justify-center rounded-[50%] text-white"
    >
      {/* TODO: FontIcon uses styled components, which doesn't seem to work when building with Rollup (used for the SDK). Need to look into this. */}
      {/*<FontIcon icon={FontIcons.Show} iconSize={20} />*/}
      <span className="view-only-icon" role="img" />
    </div>
  );
};
