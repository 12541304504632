import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import { Attachment } from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React from 'react';
import { SectionTitle } from '../../../common/layout';
import { Trans, t } from '@lingui/macro';

export interface AssetDetailsProps {
  attachment: Attachment;
}

export function AttachmentDetails({ attachment }: AssetDetailsProps) {
  return (
    <div>
      <SectionTitle>
        <Trans>Details</Trans>
      </SectionTitle>
      <div className="flex flex-col">
        <span>
          {t`Last updated on`} {format(attachment.updatedAt, DEFAULT_DATE_FORMAT)}
        </span>
        <span>
          {t`Created on`} {format(attachment.createdAt, DEFAULT_DATE_FORMAT)}
        </span>
      </div>
    </div>
  );
}
