import {
  ToastProps,
  ToastContentProps,
  ToastLinkProps,
  ToastActionProps,
  ToastActionsProps,
  ToastDismissButtonProps,
} from '@smartsheet/sds-toast-ui';
import { FC } from 'react';

export const BF_STANDARD_TOAST_COMPONENT = 'BF_STANDARD_TOAST_COMPONENT';

export interface BFStandardToastProps {
  toastProps: ToastComponentProps;
  toastContentProps?: ToastComponentContentProps;
  linkProps?: ToastComponentLinkProps;
  toastActionsProps?: ToastComponentActionsProps;
  toastDismissButtonProps?: ToastComponentDismissButtonProps;
  toastActionProps?: ToastComponentActionProps;
}

export type BFStandardToastComponent = FC<BFStandardToastProps>;
export type ToastComponentProps = ToastProps &
  React.HTMLAttributes<HTMLDivElement> & { message: string };
export type ToastComponentContentProps = ToastContentProps & React.HTMLAttributes<HTMLDivElement>;
export type ToastComponentLinkProps = ToastLinkProps &
  React.HTMLAttributes<HTMLAnchorElement> & {
    linkText: string;
    linkHref: string;
  };
export type ToastComponentActionsProps = ToastActionsProps & React.HTMLAttributes<HTMLDivElement>;
export type ToastComponentActionProps = ToastActionProps &
  React.HTMLAttributes<HTMLButtonElement> & {
    actionText: string;
  };
export type ToastComponentDismissButtonProps = ToastDismissButtonProps &
  React.HTMLAttributes<HTMLButtonElement> & { dismissToast: () => void };
