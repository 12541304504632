import {
  AddAssetTagsResponseData,
  ApiFetchDataResponse,
  ApiListDataResponse,
  AttachmentInputDto,
  BulkDownloadAssetsDto,
  CustomFieldKeyResponseData,
  CustomFieldKeyValueDto,
  GenericFileDto,
  PagedResultsSortOrder,
  PaginationType,
} from '@integration-frontends/common/brandfolder-api';
import { Section } from '../section';
import { Container } from '../../container';
import { Attachment } from '../attachment';
import { ResourceBaseSortableProperty, SortDirection, SortOptions } from '../common/sorting';
import { Asset, SearchParameters } from './asset';
import { AssetCustomFieldValue } from './asset-custom-field-value';
import { AssetTag } from './asset-tag';

export const ALL_RESOURCE_BASE_SORTABLE_FIELDS = Object.values(ResourceBaseSortableProperty);
export const RESOURCE_BASE_SORT_OPTIONS: SortOptions<ResourceBaseSortableProperty>[] = [
  { field: ResourceBaseSortableProperty.Position, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.CreatedAt, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.CreatedAt, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.UpdatedAt, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.UpdatedAt, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.Name, direction: SortDirection.Asc },
  { field: ResourceBaseSortableProperty.Name, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.Popularity, direction: SortDirection.Desc },
  { field: ResourceBaseSortableProperty.Popularity, direction: SortDirection.Asc },
];

export interface ListOptions {
  searchParams?: SearchParameters;
  pagination?: {
    perPage?: number;
    page?: number;
    nextPage?: number;
  };
  sort?: SortOptions;
  after?: string;
  before?: string;
  count_based_pagination?: boolean;
}

export interface AssetDetails {
  tags: AssetTag[];
  customFieldValues: AssetCustomFieldValue[];
}

export interface PagedResults<T> {
  data: T;
  totalCount?: number;
  totalPages?: number;
  currentPage?: number;
  perPage?: number;
  nextPage?: number;
  recordCount?: number;
  from?: number;
  to?: number;
  offset?: number;
  previousPage?: number;
  moreExist?: boolean;
  sortOrder?: PagedResultsSortOrder;
  nextCursor?: string;
  nextCursorParam?: string;
  paginationType?: PaginationType;
}

export interface AssetIdResults<T> {
  data: T;
  recordCount: number;
}

export interface AssetCountResults<T> {
  data: T;
  cacheDurationSeconds: number;
  limitReached: boolean;
}

export interface CollectionStorageResults<T> {
  data: T;
  storageLimit: number;
}

export interface AssetsListResultSet {
  assets: Asset[];
  attachments: Attachment[];
}

export interface AssetsListResultSetBase {
  assets: Asset[];
}

export interface AssetIdsResultSetBase {
  assetIds: string[];
}

export interface AssetsCountResultSetBase {
  count: number;
}

export interface CollectionStorageResultSetBase {
  remaining: number;
}

export interface IAssetRepo {
  fetchAsset: (assetId: string) => Promise<Asset>;
  getAssetDetails: (assetId: string) => Promise<AssetDetails>;
  listContainerAssetsBase: (
    container: Container,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSetBase>>;
  listContainerAssetIdsBase: (
    container: Container,
    options?: ListOptions,
  ) => Promise<AssetIdResults<AssetIdsResultSetBase>>;
  listCollectionAssetsCountBase: (
    collectionId: string,
  ) => Promise<AssetCountResults<AssetsCountResultSetBase>>;
  listCollectionStorageRemainingBase: (
    collectionId: string,
  ) => Promise<CollectionStorageResults<CollectionStorageResultSetBase>>;
  bulkAddAssetTags: (
    assetIds: string[],
    tags: string[],
    collectionId?: string,
    locale?: string,
    source?: string,
  ) => Promise<AddAssetTagsResponseData | AddAssetTagsResponseData[]>;
  addAssetTags: (assetId: string, tags: string[]) => Promise<AddAssetTagsResponseData>;
  listCustomFieldKeys: (container: Container) => Promise<CustomFieldKeyResponseData>;
  addAssetCustomFields: (
    assetIds: string[],
    container: Container,
    customFields: CustomFieldKeyValueDto[],
  ) => Promise<void>;
  listContainerAssets: (
    container: Container,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listCollectionSectionAssets: (
    collectionId: string,
    sectionId: string,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listSectionAssets: (
    sectionId: string,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listContainerSectionAssets: (
    container: Container,
    sectionId: string,
    options?: ListOptions,
  ) => Promise<PagedResults<AssetsListResultSet>>;
  listContainerSectionsAssets: (
    container: Container,
    sections: Section[],
    options?: ListOptions,
  ) => Promise<{ sectionId: string; results: PagedResults<AssetsListResultSet> }[]>;
  create: (
    container: Container,
    sectionId: string,
    name: string,
    files: File[],
    source?: string,
    collectionId?: string,
  ) => Promise<ApiListDataResponse>;
  uppyUpload: (
    sectionId: string,
    name: string,
    attachment: AttachmentInputDto,
    collectionId?: string,
  ) => Promise<ApiListDataResponse>;
  bulkDownloadAssets: (assetIds: string[], name?: string) => Promise<BulkDownloadAssetsDto>;
  createExternalMedia: (
    container: Container,
    sectionId: string,
    source: string,
    externalMedia: { name: string; url: string },
    collectionId?: string,
  ) => Promise<ApiFetchDataResponse<GenericFileDto>>;
}
