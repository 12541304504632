import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from 'reselect';
import { AttachmentSelectors } from './attachment';
import {
  Asset,
  filterByAsset,
  positionComparator,
} from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';
import { assetAdapter } from '../entities';

export function createAssetSelectors(
  stateSelector: StateSelector<EntityState<Asset>>,
  attachmentsSelectors: AttachmentSelectors,
) {
  const baseSelectors = assetAdapter.getSelectors(stateSelector);
  const assetAttachmentsSelector = (asset: Asset) =>
    createSelector(attachmentsSelectors.selectAll, (attachments) =>
      attachments.filter(filterByAsset(asset.id)),
    );

  const assetMainAttachmentSelector = (asset: Asset) =>
    createSelector(assetAttachmentsSelector(asset), (attachments) => {
      return attachments.sort(positionComparator)[0];
    });

  return {
    ...baseSelectors,
    assetAttachments: assetAttachmentsSelector,
    assetMainAttachment: assetMainAttachmentSelector,
  };
}
