import styled from '@emotion/styled';
import { useTranslate } from '@integration-frontends/core/translation/use-translate';
import React, { FunctionComponent } from 'react';

const StyledRibbonContainerBase = styled.div`
  color: #f8fafc;
  transform: rotate(-45deg);
  border-bottom: 25px solid;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  font-size: 12px;
  height: 0;
  left: -25px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 18px;
  width: 110px;
  z-index: 1;
`;

export const RibbonContainerBase: FunctionComponent = (props) => {
  const { translate } = useTranslate();

  return <StyledRibbonContainerBase aria-label={translate('Asset status')} {...props} />;
};
