import { useLocation, useParams } from 'react-router-dom';
import { AttachmentEditor } from '@integration-frontends/integration/ui';
import { AttachmentEditOptions } from '@integration-frontends/integration/core/application';
import React from 'react';
import { Header } from '@integration-frontends/apps/common/ui';

export function AttachmentEditorPage() {
  const { attachmentId } = useParams<{ attachmentId: string }>();
  const { state }: { state: { options?: AttachmentEditOptions } } = useLocation();

  return (
    <div className="flex flex-col">
      <Header />
      <div className="container py-6">
        <AttachmentEditor attachmentId={attachmentId} options={state?.options} />
      </div>
    </div>
  );
}
