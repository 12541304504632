import React, { useContext } from 'react';
import { DI_CONTAINER } from '../di-container';

export const DependencyInjectorContext = React.createContext<{
  container: { get: (token: string) => unknown };
}>({ container: DI_CONTAINER });

export function useDependencyInjector<T = unknown>(token: string) {
  const { container } = useContext(DependencyInjectorContext);
  return container.get(token) as T;
}
