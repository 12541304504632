// TODO: Generalize this component. Very similar to ./top-tags-filters-container.tsx

import {
  addFileTypeFilter,
  ContainerFileTypeAggregates,
  hasFileTypeFilter,
  removeFileTypeFilter,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import React, { FunctionComponent } from 'react';
import {
  TopFileTypesFiltersComponent,
  TopFileTypesFiltersComponentProps,
} from './top-file-types-filters-component';

export interface TopFileTypesFiltersContainerProps {
  fileTypeAggregates: ContainerFileTypeAggregates[];
  searchParams: SearchParameters;
  onChange: (searchParams: SearchParameters) => void;
  Component?: FunctionComponent<TopFileTypesFiltersComponentProps>;
}

export function TopFileTypesFiltersContainer({
  fileTypeAggregates,
  searchParams,
  onChange,
  Component = TopFileTypesFiltersComponent,
}: TopFileTypesFiltersContainerProps) {
  function toggleFileType(fileType: string) {
    onChange(
      !hasFileTypeFilter(searchParams, fileType)
        ? addFileTypeFilter(searchParams, fileType)
        : removeFileTypeFilter(searchParams, fileType),
    );
  }

  return (
    <Component
      fileTypeAggregates={fileTypeAggregates}
      searchParams={searchParams}
      fileTypeToggle={toggleFileType}
    />
  );
}
