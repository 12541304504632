import {
  AttachmentUploadDate,
  SearchParameters,
  setUploadDateFilter,
} from '@integration-frontends/integration/core/model';
import { parse, subDays } from 'date-fns';
import React, { FunctionComponent } from 'react';
import {
  UploadDateFilterComponent,
  UploadDateFilterComponentProps,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/upload-date-filter/upload-date-filter-component';

export interface UploadDateFilterContainerProps {
  searchParams: SearchParameters;
  onChange: (searchParams: SearchParameters) => void;
  Component?: FunctionComponent<UploadDateFilterComponentProps>;
}

export function UploadDateFilterContainer({
  searchParams,
  onChange,
  Component = UploadDateFilterComponent,
}: UploadDateFilterContainerProps) {
  function selected(attachmentUploadDateEnum: AttachmentUploadDate) {
    if (attachmentUploadDateEnum === null) {
      onChange(setUploadDateFilter(searchParams, null));
    } else {
      onChange(
        setUploadDateFilter(searchParams, {
          uploadDateEnum: attachmentUploadDateEnum,
          dateStart: searchParams?.uploadDateFilter?.dateStart || subDays(new Date(), 7),
          dateEnd: searchParams?.uploadDateFilter?.dateEnd || new Date(),
        }),
      );
    }
  }

  function dateRangeChanged(dateStart: string, dateEnd: string) {
    onChange(
      setUploadDateFilter(searchParams, {
        uploadDateEnum: searchParams?.uploadDateFilter?.uploadDateEnum,
        dateStart: new Date(parse(dateStart, 'yyyy-MM-dd', new Date())),
        dateEnd: new Date(parse(dateEnd, 'yyyy-MM-dd', new Date())),
      }),
    );
  }

  function dateStartChanged(dateStart: string) {
    onChange(
      setUploadDateFilter(searchParams, {
        uploadDateEnum: searchParams?.uploadDateFilter?.uploadDateEnum,
        dateStart: new Date(parse(dateStart, 'yyyy-MM-dd', new Date())),
        dateEnd: searchParams?.uploadDateFilter?.dateEnd,
      }),
    );
  }

  function dateEndChanged(dateEnd: string) {
    onChange(
      setUploadDateFilter(searchParams, {
        uploadDateEnum: searchParams?.uploadDateFilter?.uploadDateEnum,
        dateStart: searchParams?.uploadDateFilter?.dateStart,
        dateEnd: new Date(dateEnd),
      }),
    );
  }

  return (
    <Component
      searchParams={searchParams}
      onSelect={selected}
      onDateStartChange={dateStartChanged}
      onDateEndChange={dateEndChanged}
      onDateRangeChange={dateRangeChanged}
    />
  );
}
