import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import React, { FC, forwardRef } from 'react';

export function createResolvableComponent<TProps>(token: string, DefaultComponent?: FC<TProps>) {
  return function (props: TProps) {
    const componentResolver = DI_CONTAINER.get<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
    const Component = componentResolver.getComponent<FC<TProps>>(token) || DefaultComponent;
    return <Component {...props} />;
  };
}

export function createResolvableForwardedRefComponent<TProps>(
  token: string,
  DefaultComponent?: FC<TProps>,
) {
  return forwardRef(function (props: TProps, ref) {
    const componentResolver = DI_CONTAINER.get<ComponentResolver>(COMPONENT_RESOLVER_TOKEN);
    const Component = componentResolver.getComponent<FC<TProps>>(token) || DefaultComponent;
    return <Component ref={ref} {...props} />;
  });
}
