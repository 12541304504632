import { Container } from 'inversify';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  AuthenticationServiceMock,
  IDENTITY_STORE_TOKEN,
  IdentityStoreMock,
} from '@integration-frontends/common/auth/core/model';

import {
  UPLOAD_ASSETS_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';

export function getDIContainerMock() {
  const container = new Container();
  container.bind(IDENTITY_STORE_TOKEN).toConstantValue(new IdentityStoreMock());
  container.bind(AUTHENTICATE_SERVICE_TOKEN).toConstantValue(new AuthenticationServiceMock());
  container.bind(UPLOAD_ASSETS_OPTIONS_TOKEN).toConstantValue({});
  return container;
}
