import {
  BadgeSize,
  BadgeType,
  IconPaperclip,
  IconSize,
  PillBadge,
} from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { Tooltip, TooltipBody, useTooltip } from '@smartsheet/lodestar-core';
import React from 'react';
import { ConnectedAssetThumbnail } from '../../../../common/entities/asset';
import { IconViewOnly } from '../../../../common/icons';
import {
  INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  IntegrationUiStylingOptions,
} from '../../../../styling-options';
import { Thumbnail } from '../../thumbnail';
import { ThumbnailContainer, ThumbnailLabelContainer } from '../common';
import { ThumbnailStatusRibbon } from '../thumbnail-status-ribbon';
import './asset-thumbnail.scss';

export interface AssetThumbnailBaseProps {
  asset: Asset;
  attachments: Attachment[];
  containerId: string;
  responsive?: boolean;
  overlay: React.ReactNode;
}

export function AssetThumbnailBase({ asset, responsive, overlay }: AssetThumbnailBaseProps) {
  const { tooltipProps, targetProps } = useTooltip({ placement: 'top-start' });
  const stylingOptions = DI_CONTAINER.get<IntegrationUiStylingOptions>(
    INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  );

  return (
    <ThumbnailContainer responsive={responsive} data-testid="asset-thumbnail">
      <Thumbnail className="mb-sm">
        <div
          className="asset-thumbnail-content flex w-full items-center justify-center"
          style={{ height: 150 }}
        >
          <ThumbnailStatusRibbon asset={asset} />
          <ConnectedAssetThumbnail assetId={asset?.id} />
          {overlay || null}
        </div>
      </Thumbnail>

      <ThumbnailLabelContainer>
        <Tooltip {...tooltipProps}>
          <TooltipBody>{asset.name}</TooltipBody>
        </Tooltip>

        {asset.attachmentCount > 0 && (
          <span className="flex items-center justify-between truncate" {...targetProps}>
            <p className="truncate">{asset.name}</p>

            <div className="gap-sm flex items-center justify-end">
              <PillBadge
                badgeType={BadgeType.Secondary}
                badgeSize={BadgeSize.Medium}
                style={{ backgroundColor: stylingOptions.backgroundColorSecondary }}
              >
                <IconPaperclip iconSize={IconSize.ExtraSmall} />
                &nbsp;
                {asset.attachmentCount}
              </PillBadge>

              {asset?.availability === 'view_only' && <IconViewOnly />}
            </div>
          </span>
        )}
      </ThumbnailLabelContainer>
    </ThumbnailContainer>
  );
}
