import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
} from '@integration-frontends/common/auth/core/model';
import {
  BFHistory,
  DI_CONTAINER,
  HISTORY_TOKEN,
  getObservabilityService,
  integrationFrontendsDD,
} from '@integration-frontends/core';
import * as SDK from 'blocksdk';
import { push } from 'redux-first-history';
import { isEmpty } from 'ramda';
import { call, put, takeEvery } from 'redux-saga/effects';
import { init } from '@integration-frontends/common/app';
import { IDENTITY_STORE_TOKEN, IIdentityStore } from '../common';
import { EditState } from '../model';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { environment } from '../../environments/environment';

const sfSdk = new SDK();

function* handleInit() {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);

  const identity = yield call(identityStore.get);
  const isAuthed = yield call(authService.isAuthenticated, identity?.token);
  const history = DI_CONTAINER.get<BFHistory>(HISTORY_TOKEN);
  const service = 'salesforce-marketing-cloud';

  if (isAuthed) {
    yield call(getObservabilityService().initObservability, {
      enableSessionRecordings: true,
      env: environment?.environment,
      datadogCredentials: integrationFrontendsDD,
      service,
    });
    yield call(getObservabilityService().sendIdentity, identity, environment?.environment);
    yield put(sendSegmentIdentifyAction({ identity }));
  }

  if (!isAuthed) yield put(push('/welcome'));
  else if (history.location.pathname === '/select-attachment') {
    const savedState: EditState = yield call(getData);
    if (savedState) {
      yield put(push(`/edit-attachment/${savedState.attachment.id}`, savedState));
    }
  }
}

function getData(): Promise<EditState> {
  const GET_DATA_TIMEOUT = 1000;
  const data = new Promise<EditState>((res) => {
    sfSdk.getData((data) => res(!isEmpty(data) ? JSON.parse(data) : null));
  });

  console.log('Data received from Salesforce', data);
  const timeout = new Promise<null>((res) => setTimeout(() => res(null), GET_DATA_TIMEOUT));
  return Promise.race([data, timeout]);
}

export function* initWorkflow() {
  yield takeEvery(init, handleInit);
}
