import { call, put, select, takeEvery } from 'typed-redux-saga';
import {
  containerCustomFieldValueActions,
  loadCustomFieldValues,
  SearchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import {
  CONTAINER_REPO_TOKEN,
  IContainerRepo,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';

function* handler(
  selectors: SearchAssetsSelectors,
  action: ReturnType<typeof loadCustomFieldValues>,
) {
  const containerRepo: IContainerRepo = DI_CONTAINER.get(CONTAINER_REPO_TOKEN);
  const container = yield select(selectors.container);

  const customFieldValues = yield call(
    containerRepo.getContainerCustomFieldValues,
    container,
    action.payload.customFieldId,
  );

  yield put(containerCustomFieldValueActions.containerCustomFieldValuesReceived(customFieldValues));
}

export function* customFieldSelectedEffects(selectors: SearchAssetsSelectors) {
  yield takeEvery(loadCustomFieldValues, handler, selectors);
}
