import { withFormControlStyles } from '../../forms/form-controls/common';
import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import isString from 'lodash/isString';
import classNames from 'classnames';
import { FlyoutPlacement } from '@smartsheet/lodestar-core';

export const BF_STANDARD_CHECKBOX_COMPONENT = 'BF_STANDARD_CHECKBOX_COMPONENT';
export type BFStandardCheckboxProps = {
  label: string;
  labelClassName?: HTMLAttributes<HTMLLabelElement>['className'];
  tooltipPlacement?: FlyoutPlacement
} & HTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLInputElement>;

function DefaultCheckbox({
  label,
  className,
  labelClassName,
  ...restProps
}: BFStandardCheckboxProps) {
  return (
    <label style={{ display: 'flex', alignItems: 'center' }} className="min-w-0">
      <input
        type="checkbox"
        className={classNames(
          restProps.disabled
            ? `bf-checkbox disabled rounded`
            : `bf-checkbox border-bf-light-gray rounded`,
          className,
        )}
        style={{ marginRight: 10 }}
        {...restProps}
      />
      {isString(label) ? (
        <span className={classNames('min-w-0 flex-grow cursor-pointer truncate', labelClassName)}>
          {label}
        </span>
      ) : label}
    </label>
  );
}

export const BFStandardCheckbox = withFormControlStyles<BFStandardCheckboxProps>((props) => {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const CheckboxComponent =
    componentResolver.getComponent(BF_STANDARD_CHECKBOX_COMPONENT) || DefaultCheckbox;
  return <CheckboxComponent {...props} />;
});
