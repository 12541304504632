import { ListboxOption, ListboxOptionValue } from '@brandfolder/react';
import { SelectV2, SelectV2Props } from '@smartsheet/lodestar-core';
import React, { useEffect, useState } from 'react';
import { BFStandardComboboxComponent, BFStandardComboboxProps } from './bf-standard-combobox';
import { SearchIcon } from '@smartsheet/lodestar-icons';

type Option = {
  label: ListboxOption['label'];
  value: ListboxOptionValue;
};

export const BFStandardComboboxSmartsheet: BFStandardComboboxComponent = (props) => {
  const { formatOption, onSelection, onInputChange, options, placeholder, selectedOption, ...restProps } = props;
  const [inputValue, setInputValue] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    onInputChange && onInputChange(inputValue);
  }, [inputValue, onInputChange]);

  return (
    <SelectV2
      formatOption={formatOption}
      isSearchable={true}
      onChange={mapOnSelectionToOnChange(onSelection, options)}
      options={mapOptions(options)}
      placeholder={placeholder}
      selectedOption={mapOption(selectedOption)}
      getOptionKey={(option) => option.label}
      locale={'en'}
      contentBefore={<SearchIcon size={'small'} />}
      hideDropdownArrow={true}
      inputValue={inputValue}
      setInputValue={setInputValue}
      menuIsOpen={inputValue !== '' && menuIsOpen}
      setMenuIsOpen={setMenuIsOpen}
      {...restProps}
    />
  );
};

function mapOnSelectionToOnChange(
  onSelection: BFStandardComboboxProps['onSelection'],
  options: BFStandardComboboxProps['options'],
): SelectV2Props<Option, HTMLElement>['onChange'] {
  return ({ value }) => {
    onSelection(options.find((option) => option.value === value));
  };
}

function mapOptions(
  options: BFStandardComboboxProps['options'],
): SelectV2Props<Option, HTMLElement>['options'] {
  return options.map(mapOption);
}

function mapOption(option: ListboxOption): Option {
  if (!option) return null;

  return {
    label: option.label,
    value: option.value,
  };
}
