import { createResolvableComponent } from '../../create-resolvable-component';

export const BF_STANDARD_DATERANGE_PICKER_COMPONENT = 'BF_STANDARD_DATERANGE_PICKER';
export interface BFStandardDaterangePickerProps {
  endDateValue: Date;
  locale?: 'en'; // Add as needed
  onSelection: (startDate: Date, endDate: Date) => void;
  startDateValue: Date;
  onDatePickerCleared: () => void;
}

const ResolvableBFStandardDaterangePicker =
  createResolvableComponent<BFStandardDaterangePickerProps>(BF_STANDARD_DATERANGE_PICKER_COMPONENT);

export const BFStandardDaterangePicker = ResolvableBFStandardDaterangePicker;
