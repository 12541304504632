import { StateSelector } from '@integration-frontends/common/utils/redux';
import {
  Container,
  generateLabelTrees,
  Label,
} from '@integration-frontends/integration/core/model';
import { EntityState } from '@reduxjs/toolkit';
import { compose, equals, filter, prop } from 'ramda';
import { createSelector } from 'reselect';
import { labelAdapter } from '../entities';

export function createLabelSelectors(stateSelector: StateSelector<EntityState<Label>>) {
  const baseLabelSelectors = labelAdapter.getSelectors(stateSelector);
  const selectContainerLabelTrees = (container: Container) =>
    createSelector(
      baseLabelSelectors.selectAll,
      compose(generateLabelTrees, filter(compose(equals(container?.id), prop('containerId')))),
    );
  const containerHasLabels = (container: Container) =>
    createSelector(selectContainerLabelTrees(container), (labelTrees) => labelTrees.length > 0);

  const selectLabelById = (id: string) => {
    return createSelector(
      baseLabelSelectors.selectAll,
      compose(filter(compose(equals(id), prop('id')))),
    );
  };

  return {
    ...baseLabelSelectors,
    labelTreesByContainer: selectContainerLabelTrees,
    containerHasLabels: containerHasLabels,
    selectLabelById: selectLabelById,
  };
}
