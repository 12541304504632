import {
  AssetsListPagination,
  getPageCeil,
  getPageFloor,
} from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import React from 'react';
import { t } from '@lingui/macro';

export function CurrentPageDisplay({ pagination }: { pagination: AssetsListPagination }) {
  return (
    <span className="font-medium" data-testid="current-page-display">
      {getPageFloor(pagination)}-{getPageCeil(pagination)} {t`of`}{' '}
      {pagination.totalCount.toLocaleString()} {t`assets`}
    </span>
  );
}
