import {
  loginThunk,
  selectError,
  selectIdentity,
} from '@integration-frontends/common/auth/core/application';
import { PageContainer } from '@integration-frontends/integration/ui/common/layout/page';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetApiKeyForm } from './set-api-key-form';

interface SetApiKeyProps {
  hostUrl?: string;
  oauthClientId?: string;
  oauthBaseUrl?: string;
}

export function SetApiKey({ hostUrl, oauthClientId, oauthBaseUrl }: SetApiKeyProps) {
  const dispatch = useDispatch();
  const identity = useSelector(selectIdentity);
  const error = useSelector(selectError);
  const [localApiKey, setLocalApiKey] = useState<string>(null);

  function getApiKey(): string {
    return localApiKey || identity?.token;
  }

  function submit() {
    dispatch(loginThunk(getApiKey()) as any);
    setLocalApiKey(null);
  }

  return (
    <PageContainer>
      <SetApiKeyForm
        hostUrl={hostUrl}
        oauthClientId={oauthClientId}
        oauthBaseUrl={oauthBaseUrl}
        apiKey={getApiKey()}
        error={error}
        onApiKeyChange={setLocalApiKey}
        onSubmit={submit}
      />
    </PageContainer>
  );
}
