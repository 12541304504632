/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Environment } from '@integration-frontends/apps/common/utils/environment-types';
import { Identity } from '@integration-frontends/common/auth/core/model';
import { IObservabilityService, DatadogRUMConfig } from '@integration-frontends/core';

export class ObservabilityServiceMock implements IObservabilityService {
  initObservability(config: DatadogRUMConfig): Promise<void> {
    return Promise.resolve();
  }
  sendIdentity(identity: Identity, environment: Environment): Promise<void> {
    return Promise.resolve();
  }
  addError(error: Error): Promise<void> {
    return Promise.resolve();
  }

  setGlobalContextProperty(key: string, value: unknown): void {
    return;
  }

  stopSessionRecording(): void {
    return;
  }
}
