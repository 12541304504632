import {
  ComponentResolver,
  COMPONENT_RESOLVER_TOKEN,
  DI_CONTAINER,
} from '@integration-frontends/core';
import React from 'react';

export const SECTION_UPLOAD_PAGE_TOKEN = 'SECTION_UPLOAD_PAGE_COMPONENT';

export function DefaultSectionUploadPageComponent() {
  //PLACEHOLDER FOR FUTURE DEFAULT COMPONENT FOR PAGE OUTSIDE OF FIGMA USAGE
  throw new Error('No default section upload page component for this application');
  return null;
}

export function SectionUploadPageContainer() {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const SectionUploadPageCompoennt: React.FunctionComponent<any> =
    componentResolver.getComponent(SECTION_UPLOAD_PAGE_TOKEN) || DefaultSectionUploadPageComponent;

  return <SectionUploadPageCompoennt />;
}
