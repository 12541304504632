import {
  assetEntitySelectors,
  searchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import { Plural, Trans, t } from '@lingui/macro';
import React from 'react';
import { useSelector } from 'react-redux';

export interface ResultsDisplayProps {
  containerId: string;
}

export function ResultsDisplay(containerId) {
  const assets = useSelector(assetEntitySelectors.selectAll);
  const resultsTotal = useSelector(searchAssetsSelectors.assetCount);

  return (
    <p id="results-display" className="font-medium">
      <Trans>Showing</Trans> {resultsTotal}{' '}
      <Plural
        value={assets.length}
        one=" search result of # asset"
        other=" search results of # assets"
      ></Plural>
    </p>
  );
}
