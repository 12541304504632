import { Attachment } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const attachmentAdapter = createEntityAdapter<Attachment>();

export const attachmentSlice = createSlice({
  name: 'attachment',
  initialState: attachmentAdapter.getInitialState(),
  reducers: {
    attachmentReceived: attachmentAdapter.upsertOne,
    attachmentsReceived: attachmentAdapter.addMany,
    attachmentDetailsReceived: attachmentAdapter.upsertMany,
    attachmentRemoved: attachmentAdapter.removeAll,
  },
});
