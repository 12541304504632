/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { all } from 'redux-saga/effects';
import { customFieldSelectedEffects } from '@integration-frontends/integration/core/application/search-assets/state/effects/filters/custom-field-selected';
import { call } from 'typed-redux-saga';
import { SearchAssetsSelectors } from '@integration-frontends/integration/core/application';
import { filterTagsEffects } from './filter-tags';

export function* filtersEffects(selectors: SearchAssetsSelectors) {
  yield all([call(customFieldSelectedEffects, selectors), call(filterTagsEffects, selectors)]);
}
