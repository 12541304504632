import { createReducer } from '@reduxjs/toolkit';
import {
  createNotification,
  closeNotification,
  createBanner,
  closeBanner,
  updateBanner,
  createToast,
  updateToast,
  closeToast,
  openBanner,
  clearToasts,
  clearBanners,
} from './actions';
import { ToastType } from '@smartsheet/lodestar-core/dist/esm/components/toast/ToastTypes';
import { BannerContent } from '@smartsheet/lodestar-core';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
}

export interface Notification {
  message: string;
  time: Date;
  closed: boolean;
  location: string;
  position: number;
  type: NotificationType;
}

export interface NotificationsState {
  notifications: Notification[];
}

export const initialNotificationsState: NotificationsState = {
  notifications: [],
};

export enum BannerType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export interface BannerContent {
  contentText?: string;
  linkText?: string;
  linkHref?: string;
  actionText?: string;
  actionTextAction?: React.MouseEventHandler<HTMLButtonElement>;
  dismissButtonAction?: React.MouseEventHandler<HTMLButtonElement>;
  displayDismissButton?: boolean;
}

export interface InteractiveToastContent {
  linkText?: string;
  linkHref?: string;
  actionText?: string;
  actionTextAction?: React.MouseEventHandler<HTMLButtonElement>;
  dismissButtonAction?: React.MouseEventHandler<HTMLButtonElement>;
}

export type ToastContent = {
  contentText: string;
  children?: React.ReactNode;
  interactiveToastContent?: InteractiveToastContent;
};

export type ToastState = {
  id: string;
  index: number;
  time: Date;
  closed: boolean;
};

export interface Banner {
  id: string;
  index: number;
  time: Date;
  closed: boolean;
  type: BannerType;
  bannerContent: BannerContent;
}
export interface Toast {
  type: ToastType;
  toastContent: ToastContent;
  toastState?: ToastState;
}

export interface BannersState {
  banners: Banner[];
}
export interface ToastsState {
  toasts: Toast[];
}

export const initialBannersState: BannersState = {
  banners: [],
};

export const initialToastState: ToastsState = {
  toasts: [],
};

const notificationsReducer = createReducer(initialNotificationsState, (builder) => {
  builder
    .addCase(createNotification, (state, action) => {
      const notification: Notification = {
        message: action.payload.message,
        location: action.payload.location,
        closed: false,
        time: new Date(),
        position: state.notifications.length,
        type: action.payload.type || NotificationType.Success,
      };
      state.notifications.push(notification);
    })
    .addCase(closeNotification, (state, action) => {
      state.notifications[action.payload.position].closed = true;
    });
});

const bannersReducer = createReducer(initialBannersState, (builder) => {
  builder
    .addCase(createBanner, (state, action) => {
      const { id, type, bannerContent } = action.payload;
      const banner: Banner = {
        id: id,
        index: state.banners.length,
        time: new Date(),
        closed: false,
        type,
        bannerContent: bannerContent || {},
      };
      state.banners.push(banner);
    })
    .addCase(updateBanner, (state, action) => {
      const { id, bannerContent } = action.payload;
      const banner = state.banners.find((b) => id === b.id);

      if (banner && bannerContent) {
        banner.bannerContent = { ...banner.bannerContent, ...bannerContent };
      }
    })
    .addCase(openBanner, (state, action) => {
      const { id } = action.payload;
      const banner = state.banners.find((b) => id === b.id);

      if (banner) {
        banner.closed = false;
      }
    })
    .addCase(closeBanner, (state, action) => {
      const { id } = action.payload;
      const banner = state.banners.find((b) => id === b.id);

      if (banner) {
        banner.closed = true;
      }
    }).addCase(clearBanners, (state) => {
      state.banners = []
    });
});

const toastsReducer = createReducer(initialToastState, (builder) => {
  builder
    .addCase(createToast, (state, action) => {
      const { id, type, toastContent } = action.payload;
      const toast: Toast = {
        toastState: { id, index: state.toasts.length, time: new Date(), closed: false },
        type,
        toastContent: toastContent || { contentText: '' },
      };
      state.toasts.push(toast);
    })
    .addCase(updateToast, (state, action) => {
      const { id, toastContent } = action.payload;
      const toast = state.toasts.find((b) => id === b.toastState.id);

      if (toast && toastContent && !toast.toastState.closed) {
        toast.toastContent = { ...toast.toastContent, ...toastContent };
      }
    })
    .addCase(closeToast, (state, action) => {
      const { id } = action.payload;
      const toast = state.toasts.find((b) => id === b.toastState.id);

      if (toast) {
        toast.toastState.closed = true;
      }
    }).addCase(clearToasts, (state) => {
      state.toasts = []
    });
});

export interface NotificationsRootState {
  notifications: NotificationsState;
}

export interface BannersRootState {
  banners: BannersState;
}

export interface ToastRootState {
  toasts: ToastsState;
}

export const notificationsRootReducer = {
  notifications: notificationsReducer,
  banners: bannersReducer,
  toasts: toastsReducer,
};
