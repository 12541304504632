import { Disclosure } from '@headlessui/react';
import { ExpandableSectionIcon, SectionContent, SectionHeader } from '../../common';
import { BFLabel, BFTextArea } from '@integration-frontends/common/ui';
import React from 'react';
import { SearchParameters } from '@integration-frontends/integration/core/model';
import { Trans } from '@lingui/macro';

export interface SkuFilterComponentProps {
  searchParams: SearchParameters;
  onChange: (sku: string) => void;
}

export function SkuFilterComponent({ searchParams, onChange }: SkuFilterComponentProps) {
  return (
    <Disclosure defaultOpen={searchParams?.skuFilters?.length > 0}>
      {({ open }) => (
        <>
          <SectionHeader>
            <Disclosure.Button as="div" className="flex justify-between">
              <span>
                <Trans>SKU Search</Trans>
              </span>
              <ExpandableSectionIcon expanded={open} />
            </Disclosure.Button>
          </SectionHeader>

          <Disclosure.Panel>
            <SectionContent className="pb-lg">
              <BFLabel>
                <Trans>Terms</Trans>
              </BFLabel>
              <BFTextArea
                aria-label="SKU Search Terms"
                className="w-full"
                rows={5}
                onChange={(e) => onChange(e.target.value)}
              />
            </SectionContent>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
