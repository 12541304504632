import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export enum IconSize {
  ExtraSmall,
  Small,
  Medium,
  Large,
  ExtraLarge
}

const ICON_SIZES = {
  [IconSize.ExtraSmall]: 12,
  [IconSize.Small]: 15,
  [IconSize.Medium]: 20,
  [IconSize.Large]: 24,
  [IconSize.ExtraLarge]: 28,
};

export type IconProps = { iconSize?: IconSize } & HTMLAttributes<HTMLElement>;

export function createIcon(iconElement) {
  return React.forwardRef(({ className, iconSize = IconSize.Medium, style, ...props }: IconProps, ref: any) => {
    return (
      <span
        ref={ref}
        className={classNames(
          'flex items-center justify-center inline-block text-primary icon-container',
          className,
        )}
        style={{
          minWidth: ICON_SIZES[iconSize],
          minHeight: ICON_SIZES[iconSize],
          width: ICON_SIZES[iconSize],
          height: ICON_SIZES[iconSize],
          ...style,
        }}
        {...props}
      >
        {iconElement}
      </span>
    );
  });
}
