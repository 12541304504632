import { searchFilterSlice } from './entities/search-filter';
import {
  assetCustomFieldValueSlice,
  assetSlice,
  assetTagSlice,
  attachmentSlice,
  brandfolderSlice,
  collectionSlice,
  containerCustomFieldSlice,
  containerCustomFieldValueSlice,
  containerFileTypeAggregatesSlice,
  containerTagSlice,
  labelSlice,
  organizationSlice,
  sectionSlice,
} from './entities';

export const attachmentEntityActions = attachmentSlice.actions;
export const assetCustomFieldValueEntityActions = assetCustomFieldValueSlice.actions;
export const assetEntityActions = assetSlice.actions;
export const assetTagEntityActions = assetTagSlice.actions;
export const brandfolderEntityActions = brandfolderSlice.actions;
export const containerCustomFieldActions = containerCustomFieldSlice.actions;
export const containerCustomFieldValueActions = containerCustomFieldValueSlice.actions;
export const containerFileTypeAggregateActions = containerFileTypeAggregatesSlice.actions;
export const containerTagsActions = containerTagSlice.actions;
export const collectionEntityActions = collectionSlice.actions;
export const labelEntityActions = labelSlice.actions;
export const organizationEntityActions = organizationSlice.actions;
export const searchFilterEntityActions = searchFilterSlice.actions;
export const sectionEntityActions = sectionSlice.actions;
