import {
  AttachmentUploadDate,
  hasAssetStatusFilter,
  hasCustomFieldFilters,
  hasFileTypeFilters,
  hasFilters,
  hasOrientationFilters,
  hasQueryFilter,
  hasSkuFilters,
  hasTagFilters,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import {
  PINNED_SEARCH_KEY,
  QUERY_KEY,
  SKUS_KEY,
  STATUS_KEY,
  TAGS_KEY,
  TYPE_KEY,
  UPLOAD_DATE_LAST_30_MINS_KEY,
  UPLOAD_DATE_PAST_24_HOURS_KEY,
  UPLOAD_DATE_PAST_7_DAYS_KEY,
} from '../../../i18n';
import { BFAnchor } from '@integration-frontends/common/ui';
import React from 'react';
import {
  getAssetStatusKey,
  getAttachmentOrientationKey,
  INTEGRATION_COMMON_NAMESPACE,
} from '../../../../common/i18n';
import i18next from 'i18next';
import { compose, filter, join, juxt, toUpper } from 'ramda';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import { t, Trans } from '@lingui/macro';

export interface FiltersDisplayProps {
  searchParams: SearchParameters;
  onClear: () => void;
}

function assetStatusFiltersString(searchParams: SearchParameters): string {
  if (!hasAssetStatusFilter(searchParams)) return null;
  return `${i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${STATUS_KEY}`)}(${i18next.t(
    `${INTEGRATION_COMMON_NAMESPACE}:${getAssetStatusKey(searchParams.assetStatusFilter)}`,
  )})`;
}

function customFieldFiltersString(searchParams: SearchParameters): string {
  if (!hasCustomFieldFilters(searchParams)) return null;
  return Object.values(searchParams.customFieldFilters)
    .map((customFieldFilter) => `${customFieldFilter.customField.name}(${customFieldFilter.value})`)
    .join(', ');
}

function fileTypeFilterString(searchParams: SearchParameters): string {
  if (!hasFileTypeFilters(searchParams)) return null;
  return `${i18next.t(
    `${INTEGRATION_COMMON_NAMESPACE}:${TYPE_KEY}`,
  )}(${searchParams.fileTypeFilters.fileTypes.map(toUpper).join(', ')})`;
}

export function orientationFiltersString(searchParams: SearchParameters): string {
  if (!hasOrientationFilters(searchParams)) return null;
  return searchParams.orientationFilters
    .map((orientation) =>
      i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${getAttachmentOrientationKey(orientation)}`),
    )
    .join(', ');
}

function queryFilterString(searchParams: SearchParameters): string {
  if (!hasQueryFilter(searchParams)) return null;
  return `${i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${QUERY_KEY}`)}(${searchParams.query})`;
}

function skuFiltersString(searchParams: SearchParameters): string {
  if (!hasSkuFilters(searchParams)) return null;
  return `${i18next.t(
    `${INTEGRATION_COMMON_NAMESPACE}:${SKUS_KEY}`,
  )}(${searchParams.skuFilters.join(', ')})`;
}

function tagFiltersString(searchParams: SearchParameters): string {
  if (!hasTagFilters(searchParams)) return null;
  return `${i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${TAGS_KEY}`)}(${searchParams.tagFilters.tags
    .map((tag) => tag.name)
    .join(', ')})`;
}

export function uploadDateFilterString({ uploadDateFilter }: SearchParameters): string {
  switch (uploadDateFilter?.uploadDateEnum) {
    case AttachmentUploadDate.Last30Minutes:
      return i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${UPLOAD_DATE_LAST_30_MINS_KEY}`);
    case AttachmentUploadDate.Past24Hours:
      return i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${UPLOAD_DATE_PAST_24_HOURS_KEY}`);
    case AttachmentUploadDate.Past7Days:
      return i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${UPLOAD_DATE_PAST_7_DAYS_KEY}`);
    case AttachmentUploadDate.DateRange:
      return `${format(uploadDateFilter.dateStart, DEFAULT_DATE_FORMAT)} - ${format(
        uploadDateFilter.dateEnd,
        DEFAULT_DATE_FORMAT,
      )}`;
    default:
      return null;
  }
}

function labelFilterString({ labelFilter }: SearchParameters): string {
  return labelFilter ? `label(${labelFilter.name})` : null;
}

function pinnedSearchFilterString({ pinnedSearch }: SearchParameters): string {
  return pinnedSearch
    ? `${pinnedSearch.label}(${i18next.t(`${INTEGRATION_COMMON_NAMESPACE}:${PINNED_SEARCH_KEY}`)})`
    : null;
}

export function buildFiltersString(searchParams: SearchParameters): string {
  return compose(
    join(', '),
    filter(Boolean),
    juxt([
      queryFilterString,
      tagFiltersString,
      customFieldFiltersString,
      fileTypeFilterString,
      uploadDateFilterString,
      assetStatusFiltersString,
      orientationFiltersString,
      skuFiltersString,
      labelFilterString,
      pinnedSearchFilterString,
    ]),
  )(searchParams);
}

export const FILTERS_DISPLAY_COMPONENT_KEY = 'FILTERS_DISPLAY';

export function FiltersDisplay({ searchParams, onClear }: FiltersDisplayProps) {
  return hasFilters(searchParams) ? (
    <span data-testid="filter-display" id="filter-display" className="w-full">
      <div>
        <Trans>Filtered by</Trans>:&nbsp;
        <span className="font-bold">{buildFiltersString(searchParams)}</span>
      </div>
      <BFAnchor data-cy="clear-filter-action" data-testid="clear-filter-action" onClick={onClear}>
        <Trans>Clear Filters</Trans>
      </BFAnchor>
    </span>
  ) : null;
}
