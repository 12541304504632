import {
  Container,
  ISectionRepo,
  Section,
  sectionsMockData,
} from '@integration-frontends/integration/core/model';

export class SectionRepoMock implements ISectionRepo {
  listBrandfolderSections(brandfolderId: string): Promise<Section[]> {
    return Promise.resolve(sectionsMockData.map((section) => ({ ...section, brandfolderId })));
  }

  listCollectionSections(collectionId: string): Promise<Section[]> {
    return Promise.resolve(sectionsMockData);
  }

  listCollectionSectionsPrivate(collectionId: string): Promise<Section[]> {
    return Promise.resolve(sectionsMockData);
  }

  listContainerSections(container: Container): Promise<Section[]> {
    return Promise.resolve(sectionsMockData);
  }
}
