import { REHYDRATE } from 'redux-persist';
import { delay, race, take } from 'typed-redux-saga';

const WAIT_FOR_REHYDRATE_DELAY = 100;

export function* getRehydratedState() {
  const [rehydrateAction] = yield race([take(REHYDRATE), delay(WAIT_FOR_REHYDRATE_DELAY)]);

  return rehydrateAction?.payload;
}
