import { SectionContent, SectionHeader } from '../../common';
import { BFLabel, BFSelect, IconClose } from '@integration-frontends/common/ui';
import React from 'react';
import {
  ContainerCustomField,
  CustomFieldFilter,
} from '@integration-frontends/integration/core/model';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import { useSelector } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import styled from '@emotion/styled';

export interface CustomFieldsFiltersComponentProps {
  customFieldFilters: CustomFieldFilter[];
  customFields: ContainerCustomField[];
  onFilterRemove: (id: string) => void;
  onKeySelect: (fieldId: string) => void;
  onValueSelect: (fieldId: string) => void;
  selectedFieldId: string;
}

export function CustomFieldsFiltersComponent({
  customFieldFilters,
  customFields,
  onFilterRemove,
  onKeySelect,
  onValueSelect,
  selectedFieldId,
}: CustomFieldsFiltersComponentProps) {
  return (
    <div>
      <SectionHeader>
        <Title>
          <Trans>Custom Fields</Trans>
        </Title>
      </SectionHeader>

      <SectionContent className="pb-lg">
        {customFieldFilters.length > 0 && (
          <div className="mb-md">
            {customFieldFilters.map((filter) => (
              <div key={filter.customField.id} className="mb-sm flex justify-between">
                <span>{filter.customField.name}</span>
                <span>{filter.value}</span>
                <IconClose
                  className="cursor-pointer"
                  onClick={() => onFilterRemove(filter.customField.id)}
                />
              </div>
            ))}
          </div>
        )}

        <div className="mb-sm flex flex-col">
          <BFLabel>
            <Trans>Key</Trans>
          </BFLabel>
          <BFSelect
            required
            placeholder={t`Select Key`}
            data-testid="custom-fields-key-selector"
            options={customFields.map((field) => ({ label: field.name, value: field.id }))}
            value={selectedFieldId}
            onOptionChange={(option) => onKeySelect(option?.value as string)}
            className="text-base"
          />
        </div>

        {selectedFieldId && (
          <CustomFieldsFiltersSelect
            field={customFields.find((field) => field.id === selectedFieldId)}
            onOptionChange={(option) => onValueSelect(option?.value as string)}
          />
        )}
      </SectionContent>
    </div>
  );
}

const CustomFieldsFiltersSelect = ({ field, onOptionChange }) => {
  const options = useSelector(
    containerSelectors.customFieldValues.selectValuesByCustomFieldId(field.id),
  );

  return (
    <div className="flex flex-col">
      <Label>
        <Trans>Value</Trans>
      </Label>
      <BFSelect
        placeholder={t`Select Value`}
        data-testid="custom-fields-value-selector"
        options={options.map((option) => ({ label: option.value, value: option.value }))}
        onOptionChange={onOptionChange}
        className="text-base"
      />
    </div>
  );
};

const Title = styled.legend`
  display: flex;
  justify-content: start;
`;

const Label = styled(BFLabel)`
  display: flex;
  justify-content: start;
`
