import { BFHR } from '@integration-frontends/common/ui';
import { assetCustomFieldValueEntitySelectors } from '@integration-frontends/integration/core/application';
import { Attachment } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../../../common/layout';
import { AssetCustomFieldValues } from '../../common/asset-custom-field-values';
import { Trans } from '@lingui/macro';

export interface CustomFieldsProps {
  attachment: Attachment;
}

export function CustomFields({ attachment }: CustomFieldsProps) {
  const customFieldValues = useSelector(
    assetCustomFieldValueEntitySelectors.byAssetId(attachment.assetId),
  );

  if (customFieldValues.length <= 0) return null;
  return (
    <>
      <div className="mb-md">
        <SectionTitle>
          <Trans>Custom Fields</Trans>
        </SectionTitle>
        <div>
          <AssetCustomFieldValues customFieldValues={customFieldValues} />
        </div>
      </div>
      <BFHR className="mb-md" />
    </>
  );
}
