/* eslint-disable @typescript-eslint/no-empty-function */
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';
import { Identity } from '@integration-frontends/common/auth/core/model';

interface SegmentEventProps {
  [key: string]: string | Array<string> | number;
}

interface UserProps {
  properties?: SegmentEventProps;
  identity?: any;
}

export const SEND_SEGMENT = 'SEND_SEGMENT';
export const SEND_SEGMENT_IDENTIFY = 'SEND_SEGMENT_IDENTIFY';

const identityHolder: {identity?: Identity} = {};

export const sendSegmentAction = createAction(
  SEND_SEGMENT,
  withPayloadType<{ event: string, eventProperties?: SegmentEventProps }>(),
);

export const sendSegmentIdentifyAction = createAction(
  SEND_SEGMENT_IDENTIFY,
  withPayloadType<{ identity?: any }>(),
);

function getUrl(): string {
  return window.location.href.split(/[?#]/)[0];
}

export const segmentMiddleware = store => next => action => {
  switch (action.type) {
    case SEND_SEGMENT: {
      const state = store.getState();
      const email = state.auth?.identity?.email || identityHolder.identity?.email;
      const appName = state.app?.name;
      const {event, eventProperties} = action.payload;
      sendSegmentEvent(event, {...eventProperties, email, appName});
    }
    case SEND_SEGMENT_IDENTIFY: {
      const state = store.getState();
      const organizationIds = state.integrationApplication?.entities?.organizations?.ids?.join(',')
      const appName = state.app?.name;
      const {identity } = action.payload;
      setSegmentUserProperties({properties: {appName, organizationIds}, identity});
    }
    default:
      break;
  }
  next(action);
}

export const sendSegmentEvent = (eventName: string, eventProperties?: SegmentEventProps): null => {
  if (window['analytics']) {
    const params = new URLSearchParams(window.location.href.split(/[?#]/)[1]);
    const defaultProps = {
      domain: window.location.hostname,
      search: window.location.search,
      url: getUrl(),
      url_raw: window.location.href.split(/[?#]/)[0]
    };
    // there are other built-in Segment calls, but for v1 we are just using .track('Event Name Here') and .page()
    // TODO: identify existing users (maybe by using a cookie set by the BF App) and add support for .identify() calls
    if (eventName === 'Viewed Page') {
      if (!window['sentPageview']) {
        window['analytics'].page(defaultProps);
        window['sentPageview'] = true;
      }
    } else {
      const pageContextProps = { ...defaultProps };
      const combinedProps = { ...pageContextProps, ...eventProperties };
      window['analytics'].track(eventName, combinedProps);
    }
  }
  return null;
};

export const setSegmentUserProperties = ({properties, identity}: UserProps ): void => {
  if (window['analytics']) {
    // By using the .identify() call without the userId as its first argument, the Segment SDK will automatically use the anonymousId.
    // https://segment.com/docs/connections/spec/identify/#anonymous-id
    // If the user is ever .identify()'d with their userId (e.g. after creating a Brandfolder account),
    // the traits / properties will be associated with that known user if Segment is able to connect the user to their anonymousId.
    identityHolder.identity = identity;
    const identifiers = identity ? [identity.userId, {...properties, ...identity}] : [properties];
    window['analytics'].identify(...identifiers);
  }
};
