import { Action, createReducer } from '@reduxjs/toolkit';
import { init, initLoaded, initLoading, initSuccess, pageLoaded } from './actions';
import { REHYDRATE } from 'redux-persist';

export interface AppState {
  success: boolean;
  name: string;
  rehydrated: boolean;
  loading: boolean;
  headerBackgroundUrl: string;
}

export const initialAppState: AppState = {
  success: false,
  name: 'integration-panel-ui',
  rehydrated: false,
  loading: true,
  headerBackgroundUrl: '',
};

const reducer = createReducer(initialAppState, (builder) => {
  builder
    .addCase(init, (state, action) => {
      state.success = false;
      state.name = action.payload.name;
      state.headerBackgroundUrl = action.payload.headerBackgroundUrl || state.headerBackgroundUrl;
    })
    .addCase(initSuccess, (state) => {
      state.success = true;
    })
    .addCase(initLoading, state => {
      state.loading = true;
    })
    .addCase(initLoaded, (state) => {
      state.loading = false;
    })
    .addCase(REHYDRATE, (state, action: Action & { payload: any }) => {
      if (action.payload) {
        state.rehydrated = true;
      }
    })
    .addCase(pageLoaded, (state) => {
      state.rehydrated = false;
    });
});

export interface AppRootState {
  app: AppState;
}

export const appRootReducer = {
  app: reducer,
};
