import { keyToClick } from '@integration-frontends/common/ui';
import React from 'react';
import { Asset, Container } from '@integration-frontends/integration/core/model';
import { useAttachmentSelectorNavigation } from '../../navigation';
import { useSelector } from 'react-redux';
import { searchAssetsSelectors } from '@integration-frontends/integration/core/application';
import './header.scss';
import { PageNav } from '../../../common/layout/page';
import { BackButton } from '../../../attachment-selector/common/back-button';

export interface AssetDetailsPageHeaderProps {
  asset: Asset;
  container: Container;
}

export function AssetDetailsPageHeader({ asset, container }: AssetDetailsPageHeaderProps) {
  const navigation = useAttachmentSelectorNavigation();
  const searchParams = useSelector(searchAssetsSelectors.searchParams);
  const sortOptions = useSelector(searchAssetsSelectors.sortOptions);

  function close() {
    container
      ? navigation.goToShowPage(container.id, searchParams, sortOptions)
      : navigation.goToContainerSelector();
  }

  return (
    <PageNav className="flex justify-between asset-details-page-header">
      <BackButton
        text={container?.name}
        data-testid="go-back-btn"
        data-cy="close"
        onClick={close}
        onKeyDown={keyToClick}
        tabIndex={0}
      />
    </PageNav>
  );
}
