import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@integration-frontends/common/utils/redux';

import { BannerContent, NotificationType, ToastContent } from './reducer';
import { BannerType } from './reducer';
import { ToastType } from '@smartsheet/lodestar-core/dist/esm/components/toast/ToastTypes';
import { CSSProperties } from 'react';

export const createNotification = createAction(
  'CREATE_NOTIFICATION',
  withPayloadType<{
    message: string;
    location: string;
    type?: NotificationType;
    style?: CSSProperties;
  }>(),
);

export const closeNotification = createAction(
  'CLOSE_NOTIFICATION',
  withPayloadType<{ position: number }>(),
);

export const createBanner = createAction(
  'CREATE_BANNER',
  withPayloadType<{
    id: string;
    type: BannerType;
    bannerContent?: Partial<BannerContent>;
  }>(),
);

export const updateBanner = createAction(
  'UPDATE_BANNER',
  withPayloadType<{
    id: string;
    bannerContent?: Partial<BannerContent>;
  }>(),
);

export const openBanner = createAction(
  'OPEN_BANNER',
  withPayloadType<{
    id: string;
  }>(),
);

export const closeBanner = createAction(
  'CLOSE_BANNER',
  withPayloadType<{
    id: string;
  }>(),
);

export const clearBanners = createAction('CLEAR_BANNERS');

export const createToast = createAction(
  'CREATE_TOAST',
  withPayloadType<{
    id: string;
    type: ToastType;
    toastContent?: ToastContent;
  }>(),
);

export const updateToast = createAction(
  'UPDATE_TOAST',
  withPayloadType<{
    id: string;
    toastContent?: Partial<ToastContent>;
  }>(),
);

export const closeToast = createAction(
  'CLOSE_TOAST',
  withPayloadType<{
    id: string;
  }>(),
);

export const clearToasts = createAction('CLEAR_TOASTS');
