import { StateSelector } from '@integration-frontends/common/utils/redux';
import {
  ContainerTag,
  SearchAssetsGroupBy,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import { createSelector } from 'reselect';
import { IntegrationEntitiesSelectors } from '../../common';
import { createAssetsListBySectionSelectors } from '../../common/assets-list/assets-list-by-section-state';
import { createAssetsListSelectors } from '../../common/assets-list/assets-list-state';
import { SearchAssetsState } from './reducer';
import { contentLibraryUploadAssetsSelectors } from '../../selectors';

const selectContainerId = (state: SearchAssetsState) => state.containerId;
const selectLoading = (state: SearchAssetsState) => state.loading;
const selectError = (state: SearchAssetsState) => state.error;
const selectSearchParams = (state: SearchAssetsState) => state.searchParams;
const selectSearchType = (state: SearchAssetsState) => state.searchType;
const selectEnforcedSearchParams = (state: SearchAssetsState) => state.enforcedSearchParams;
const selectSortOptions = (state: SearchAssetsState) => state.sortOptions;
const selectIsSearching = (state: SearchAssetsState) => state.searchParams !== null;
const selectInitSuccess = (state: SearchAssetsState) => state.initSuccess;
const selectAssetsListState = (state: SearchAssetsState) => state.listByContainer;
const selectAssetsListBySectionState = (state: SearchAssetsState) => state.listBySection;
const selectPageSize = (state: SearchAssetsState) => state.pageSize;
const selectShowAttachments = (state: SearchAssetsState) => state.showAttachments;
const selectGroupBy = (state: SearchAssetsState) => state.groupBy;

export function createSearchAssetsSelectors(
  stateSelector: StateSelector<SearchAssetsState>,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  const listSelectors = createAssetsListSelectors(
    createSelector(stateSelector, selectAssetsListState),
  );

  const listBySectionSelectors = createAssetsListBySectionSelectors(
    createSelector(stateSelector, selectAssetsListBySectionState),
  );

  const baseSelectors = {
    containerId: createSelector(stateSelector, selectContainerId),
    hasResults: listSelectors.currentPageHasAssets,
    loading: createSelector(stateSelector, selectLoading),
    error: createSelector(stateSelector, selectError),
    searchParams: createSelector(stateSelector, selectSearchParams),
    searchType: createSelector(stateSelector, selectSearchType),
    enforcedSearchParams: createSelector(stateSelector, selectEnforcedSearchParams),
    sortOptions: createSelector(stateSelector, selectSortOptions),
    isSearching: createSelector(stateSelector, selectIsSearching),
    initSuccess: createSelector(stateSelector, selectInitSuccess),
    pageSize: createSelector(stateSelector, selectPageSize),
    showAttachments: createSelector(stateSelector, selectShowAttachments),
    groupBy: createSelector(stateSelector, selectGroupBy),
    listSelectors,
    listBySectionSelectors,
  };

  /**
   * Returns the file types that can be used as filters by the user.
   * If there are enforced search parameters (effectively limiting the subset of results that the user has access to),
   * then we only allow filtering by the file types that are included in those.
   * @param containerId
   */
  const filterableFileTypeAggregatesByContainerId = (containerId: string) => {
    return createSelector(
      entitiesSelectors.container.fileTypeAggregates.byContainerId(containerId),
      baseSelectors.enforcedSearchParams,
      (containerFileTypeAggregates, enforcedSearchParams) => {
        if (!enforcedSearchParams) return containerFileTypeAggregates;
        return enforcedSearchParams?.query?.toLowerCase().includes('extension:')
          ? containerFileTypeAggregates.filter((fileType) => {
              return enforcedSearchParams?.query
                ?.toLowerCase()
                .includes(fileType.name.toLowerCase());
            })
          : containerFileTypeAggregates;
      },
    );
  };

  return {
    ...baseSelectors,
    pagedSectionSearchResults: (sectionId: string) =>
      createSelector(
        baseSelectors.listBySectionSelectors.sectionPagination(sectionId),
        baseSelectors.sortOptions,
        entitiesSelectors.asset.selectEntities,
        (sectionPagination, sortOptions, assetEntities) => {
          return (
            sectionPagination?.pageAssetIds[sectionPagination?.currentPage]
              .map((id) => assetEntities[id])
              .filter(Boolean) || []
          );
        },
      ),
    pagedSearchResults: () =>
      createSelector(
        baseSelectors.listSelectors.pagination,
        baseSelectors.sortOptions,
        entitiesSelectors.asset.selectEntities,
        (pagination, sortOptions, assetEntities) => {
          return (
            pagination?.pageAssetIds[pagination?.currentPage]
              .map((id) => assetEntities[id])
              .filter(Boolean) || []
          );
        },
      ),
    allSearchResults: () =>
      createSelector(
        baseSelectors.listSelectors.pagination,
        baseSelectors.sortOptions,
        entitiesSelectors.asset.selectEntities,
        contentLibraryUploadAssetsSelectors.pinnedUploads,
        (pagination, sortOptions, assetEntities, pinnedUploads) => {
          const pageAssetIds = pagination?.pageAssetIds;
          const flattenedPageAssetIds = (pageAssetIds || []).reduce(
            (acc, ids) => acc.concat(ids),
            [],
          );
          const assetIds =
            flattenedPageAssetIds.map((id) => assetEntities[id]).filter(Boolean) || [];
          return pinnedUploads.concat(assetIds);
        },
      ),
    allSearchResultsIds: () =>
      createSelector(
        baseSelectors.listSelectors.pagination,
        baseSelectors.sortOptions,
        entitiesSelectors.asset.selectEntities,
        contentLibraryUploadAssetsSelectors.pinnedUploads,
        (pagination, sortOptions, assetEntities, pinnedUploads) => {
          const pageAssetIds = pagination?.pageAssetIds;
          const flattenedPageAssetIds = (pageAssetIds || []).reduce(
            (acc, ids) => acc.concat(ids),
            [],
          );

          const assetIds = flattenedPageAssetIds.filter((id) => assetEntities[id]) || [];

          const pinnedUploadIds = pinnedUploads.map((upload) => upload.id);
          return pinnedUploadIds.concat(assetIds);
        },
      ),

    container: createSelector(
      baseSelectors.containerId,
      entitiesSelectors.container.selectAll,
      (containerId, containers) => containers.find((c) => c.id === containerId),
    ),
    assetCount: createSelector(
      baseSelectors.groupBy,
      listSelectors.assetCount,
      listBySectionSelectors.assetCount,
      (groupBy, listAssetCount, listBySectionAssetCount) =>
        groupBy === SearchAssetsGroupBy.Container ? listAssetCount : listBySectionAssetCount,
    ),
    pagination: createSelector(
      baseSelectors.groupBy,
      listSelectors.pagination,
      listBySectionSelectors.pagination,
      (groupBy, listPagination, listBySectionPagination) =>
        groupBy === SearchAssetsGroupBy.Container ? listPagination : listBySectionPagination,
    ),
    hasResults: createSelector(
      baseSelectors.groupBy,
      listSelectors.currentPageHasAssets,
      listBySectionSelectors.hasAssets,
      (groupBy, listSelectorsHasAssets, listBySectionSelectorsHasAssets) =>
        groupBy === SearchAssetsGroupBy.Container
          ? listSelectorsHasAssets
          : listBySectionSelectorsHasAssets,
    ),
    filterableFileTypeAggregatesByContainerId,
    filterableFileTypeAggregates: createSelector(
      (state) => state,
      baseSelectors.containerId,
      (state, containerId) => {
        return filterableFileTypeAggregatesByContainerId(containerId)(state as never);
      },
    ),
    filterableTagsByContainerId: (containerId: string) => {
      return createSelector(
        entitiesSelectors.container.tags.byContainerId(containerId),
        baseSelectors.enforcedSearchParams,
        (containerTags, enforcedSearchParams) => {
          return resolveFilterableTags(containerTags, enforcedSearchParams);
        },
      );
    },
    filterableTags: createSelector(
      baseSelectors.containerId,
      entitiesSelectors.containerTags.selectAll,
      baseSelectors.enforcedSearchParams,
      (containerId, containerTags, enforcedSearchParams) =>
        resolveFilterableTags(
          containerTags.filter((tag) => tag.containerId === containerId),
          enforcedSearchParams,
        ),
    ),
  };
}

function resolveFilterableTags(
  containerTags: ContainerTag[],
  enforcedSearchParams: SearchParameters,
): ContainerTag[] {
  if (!enforcedSearchParams) return containerTags;
  return enforcedSearchParams?.query?.toLowerCase().includes('tags:')
    ? containerTags.filter((tag) => {
        return enforcedSearchParams?.query?.toLowerCase().includes(tag.name.toLowerCase());
      })
    : containerTags;
}

export type SearchAssetsSelectors = ReturnType<typeof createSearchAssetsSelectors>;
