import {
  getResponseListDataOrDefault,
  isError,
} from '@integration-frontends/common/brandfolder-api';
import {
  Container,
  ILabelRepo,
  Label,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { map } from 'ramda';
import { mapLabel } from './model';
import { RepoBase } from './repo-base';

@injectable()
export class LabelRepo extends RepoBase implements ILabelRepo {
  getContainerLabels = async (container: Container): Promise<Label[]> => {
    if (!container) return [];

    return (
      container.type === ResourceType.BRANDFOLDER
        ? this.brandfolderApi.getBrandfolderLabels(await this.getApiKey(), container.id)
        : this.brandfolderApi.getCollectionLabels(await this.getApiKey(), container.id)
    )
      .then((response) => {
        if (isError(response)) return [];
        return getResponseListDataOrDefault(response);
      })
      .then(map((labelDto) => mapLabel(container.id, labelDto)));
  };
}
