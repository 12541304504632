import { Asset } from '@integration-frontends/integration/core/model';
import React from 'react';
import { PageSection, PageSectionContent } from '../../../common/layout/page';
import { t } from '@lingui/macro';

export interface DescriptionProps {
  asset: Asset;
}

export function AssetDescription({ asset }: DescriptionProps) {
  if (!asset.description) return null;
  return (
    <PageSection title={t`Description`}>
      <PageSectionContent>{asset.description}</PageSectionContent>
    </PageSection>
  );
}
