import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from 'reselect';
import { pipe } from 'fp-ts/function';
import { flatten, map, prop, sum } from 'ramda';
import { AssetsListBySectionState } from './reducer';

export const selectShowAttachments = (state: AssetsListBySectionState) => state?.showAttachments;
export const selectPagination = (state: AssetsListBySectionState) => state?.pagination;
export const selectSectionPagination = (sectionId: string) => (state: AssetsListBySectionState) => {
  return state.pagination?.sections[sectionId];
};
export const selectHasAssets = (state: AssetsListBySectionState) => {
  const allAssetsInSections = pipe(
    Object.values(state.pagination?.sections),
    map(prop('pageAssetIds')),
    flatten,
  );
  return allAssetsInSections.some((value) => value !== undefined);
};

export const selectTotalAssetsCount = (state: AssetsListBySectionState) => {
  return pipe(Object.values(state.pagination?.sections), map(prop('totalCount')), sum);
};

export type AssetsListBySectionStateSelector = StateSelector<AssetsListBySectionState>;

export function createAssetsListBySectionSelectors(
  stateSelector: AssetsListBySectionStateSelector,
) {
  return {
    showAttachments: createSelector(stateSelector, selectShowAttachments),
    pagination: createSelector(stateSelector, selectPagination),
    hasAssets: createSelector(stateSelector, selectHasAssets),
    sectionPagination: (sectionId: string) =>
      createSelector(stateSelector, selectSectionPagination(sectionId)),
    assetCount: createSelector(stateSelector, selectTotalAssetsCount),
  };
}
