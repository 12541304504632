import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const bulkDownloadAssetsInit = createAction(
  'BULK_DOWNLOAD_ASSETS_INIT',
  withPayloadType<{
    assetIds: string[];
    name?: string;
    onDownloadError?: () => void;
  }>(),
);

export const downloadError = createAction('DOWNLOAD_ERROR');
