import { SearchParameters, setSkuFilters } from '@integration-frontends/integration/core/model';
import React, { FunctionComponent } from 'react';
import {
  SkuFilterComponent,
  SkuFilterComponentProps,
} from '@integration-frontends/integration/ui/attachment-selector/components/advanced-filters/components/sku-filter/sku-filter-component';

export interface SkuFilterContainerProps {
  searchParams: SearchParameters;
  onChange: (searchParams: SearchParameters) => void;
  Component?: FunctionComponent<SkuFilterComponentProps>;
}

export function SkuFilterContainer({
  searchParams,
  onChange,
  Component = SkuFilterComponent,
}: SkuFilterContainerProps) {
  function changed(text: string) {
    const skus = text.split('\n');
    onChange(setSkuFilters(searchParams, skus));
  }

  return <Component searchParams={searchParams} onChange={changed} />;
}
