import React, { ReactElement } from 'react';
import { PrimaryLogoWhite } from '../logo';
import { selectHeaderBackgroundUrl } from '@integration-frontends/common/app';
import { useSelector } from 'react-redux';


export interface PanelHeaderProps {
  actions: ReactElement[];
}

interface PanelHeaderStyles {
  height: string | number;
  backgroundImage?: string;
}

export function PanelHeader({ actions }: PanelHeaderProps) {
  const headerUrl = useSelector(selectHeaderBackgroundUrl);
  const styles: PanelHeaderStyles = {
    height: 50
  };
  if (headerUrl) {
    styles.backgroundImage = `url("${headerUrl}")`;
  }
  return (
    <div
      data-testid="panel-header"
      style={ styles }
      className="px-lg py-md bg-bf-blueberry-delight flex items-center justify-between"
    >
      <div>
        <PrimaryLogoWhite style={{ width: 75, height: 20 }} />
      </div>
      <div className="cursor-pointer flex">
        {actions.map((action, idx) => (
          <div
            key={idx}
            className="ml-2 text-6xl text-white flex items-center"
          >
            {action}
          </div>
        ))}
      </div>
    </div>
  );
}
