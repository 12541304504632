import { SectionContent } from '../../common';
import React from 'react';
import {
  ContainerCustomField,
  SearchParameters,
} from '@integration-frontends/integration/core/model';
import { PrioritizedCustomFieldsFiltersSelectContainer } from './prioritized-custom-fields-filters-select-container';

export interface PrioritizedCustomFieldsFiltersComponentProps {
  customFields: ContainerCustomField[];
  searchParams: SearchParameters;
  onChange: (field: ContainerCustomField, value: string) => void;
}

export function PrioritizedCustomFieldsFiltersComponent({
  customFields,
  ...restProps
}: PrioritizedCustomFieldsFiltersComponentProps) {
  return (
    <SectionContent className="py-lg">
      <div className="flex flex-col gap-lg">
        {customFields
          .filter((field) => field.prioritized)
          .map((field) => (
            <PrioritizedCustomFieldsFiltersSelectContainer
              key={field?.id}
              customField={field}
              {...restProps}
            />
          ))}
      </div>
    </SectionContent>
  );
}
