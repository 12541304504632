import { put, select, takeEvery } from 'typed-redux-saga';
import { shiftSelectAssets, shiftSelectAssetsSuccess } from '../actions';
import { searchAssetsSelectors, selectAssetSelectors } from '../../../selectors';

const handler = function* (action: ReturnType<typeof shiftSelectAssets>) {
  const { assetId } = action.payload;
  const paginatedIds = yield select(searchAssetsSelectors.allSearchResultsIds());
  const selectedAssetIds: string[] = yield select(selectAssetSelectors.assetIds);

  const firstSelectedAssetId = selectedAssetIds[0];

  const firstSelectedAssetIndex = paginatedIds.findIndex((id) => id === firstSelectedAssetId);
  const currentSelectedAssetIndex = paginatedIds.findIndex((id) => id === assetId);

  let currentAssetIdSelection: string[] = [];
  if (firstSelectedAssetIndex < currentSelectedAssetIndex) {
    currentAssetIdSelection = paginatedIds.slice(
      firstSelectedAssetIndex,
      currentSelectedAssetIndex + 1,
    );
  } else {
    currentAssetIdSelection = paginatedIds.slice(
      currentSelectedAssetIndex,
      firstSelectedAssetIndex + 1,
    );
  }

  yield put(shiftSelectAssetsSuccess({ assetIds: currentAssetIdSelection }));
};

export function* shiftSelectAssetsEffects() {
  yield takeEvery(shiftSelectAssets, handler);
}
