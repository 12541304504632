import { Radio, TypeRampV2, typography } from '@smartsheet/lodestar-core';
import noop from 'lodash/noop';
import React from 'react';
import { BFStandardRadioProps } from './bf-standard-radio';
import './bf-standard-radio-smartsheet.scss';
import styled from '@emotion/styled';

export function BFStandardRadioSmartsheet({
  checked,
  label,
  name,
  onChange,
  size,
  ...restProps
}: BFStandardRadioProps) {
  const radioLabel = (checked: boolean) => {
    return (
      <StyledTypeRampV2 system="lodestar" variant="bodyStrong" checked={checked}>
        {label}
      </StyledTypeRampV2>
    );
  };

  return (
    <Radio
      className="smar-radio"
      name={name}
      onChange={onChange || noop}
      isChecked={checked}
      label={radioLabel(checked)}
      {...restProps}
    />
  );
}

const StyledTypeRampV2 = styled(TypeRampV2)`
  && {
    font-weight: ${(props) =>
      props.checked ? typography.fontWeight.semiBold : typography.fontWeight.medium};
  }
`;
