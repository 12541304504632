/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  Banner,
  BannerType,
  closeBanner,
  createBanner,
  createToast,
  openBanner,
  selectAllBanners,
  updateBanner,
} from '@integration-frontends/common/notifications';
import { DI_CONTAINER, STORE_TOKEN } from '@integration-frontends/core';
import { all, call, put, select } from 'typed-redux-saga';
import { toggleUploadManager, uploadComplete } from '../../../actions';
import { contentLibraryUploadAssetsSelectors } from '@integration-frontends/integration/core/application/selectors';
import { searchRefresh } from '@integration-frontends/integration/core/application/search-assets';
import { plural, t } from '@lingui/macro';
import { Store } from '@reduxjs/toolkit';
import { handleProcessFile } from './handle-process-file';

export function* handleUploadComplete() {
  const banners: Banner[] = yield select(selectAllBanners);
  const uploadSequenceId = yield select(contentLibraryUploadAssetsSelectors.uploadSequenceId);
  const unresolvedAssetUploads = yield select(
    contentLibraryUploadAssetsSelectors.unresolvedAssetUploads,
  );
  const openManagerAfterUpload = yield select(
    contentLibraryUploadAssetsSelectors.openManagerAfterUpload,
  );
  const successfulAssetUploadInUpload = yield select(
    contentLibraryUploadAssetsSelectors.successfulAssetUploadInUpload,
  );
  const unresolvedAssetUploadsCount = unresolvedAssetUploads.length;
  const uploadHasBanner = banners.find((banner) => banner.id === uploadSequenceId);

  const message = plural(unresolvedAssetUploadsCount, {
    one: '# unresolved upload',
    other: '# unresolved uploads',
  });

  const toastMessage = plural(successfulAssetUploadInUpload.length, {
    one: '# file uploaded successfully',
    other: '# files uploaded successfully',
  });

  const store: Store = DI_CONTAINER.get(STORE_TOKEN);

  yield put(closeBanner({ id: `upload-${uploadSequenceId}` }));

  if (openManagerAfterUpload) {
    yield call(handleUpdateBanner, uploadSequenceId, uploadHasBanner, store, message);
    yield put(toggleUploadManager({ open: true }));
  }
  if (successfulAssetUploadInUpload.length > 0) {
    yield put(searchRefresh());
    yield put(
      createToast({
        toastContent: {
          contentText: toastMessage,
        },
        id: crypto.randomUUID(),
        type: 'success',
      }),
    );
  }

  const assetIdsToProcess = yield select(contentLibraryUploadAssetsSelectors.filesProcessing);
  yield all(assetIdsToProcess.map((assetId) => call(handleProcessFile, assetId)));
  yield put(uploadComplete());
}

export function* handleUpdateBanner(
  uploadSequenceId: string,
  uploadHasBanner: Banner | undefined,
  store: Store,
  message: string,
) {
  if (!uploadHasBanner) {
    yield put(
      createBanner({
        id: uploadSequenceId,
        type: BannerType.Error,
        bannerContent: {
          contentText: message,
          actionText: t`View details`,
          actionTextAction: () => {
            store.dispatch(toggleUploadManager({ open: true }));
          },
          displayDismissButton: false,
        },
      }),
    );
  } else if (uploadHasBanner) {
    yield put(
      updateBanner({
        id: uploadSequenceId,
        bannerContent: {
          contentText: message,
        },
      }),
    );
    yield put(openBanner({ id: uploadSequenceId }));
  }
}
