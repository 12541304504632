import {
  BrandfolderDto,
  CollectionFieldParameter,
  CollectionIncludeParameter,
  FieldParameter,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import {
  Collection,
  CollectionCustomField,
  CollectionFileTypeAggregates,
  CollectionTag,
  CustomFieldValueOption,
  ICollectionRepo,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { RepoBase } from './repo-base';
import {
  mapCollection,
  mapCollectionCustomField,
  mapCollectionFileTypeAggregates,
  mapCollectionFiletypeAggregations,
  mapCollectionTag,
  mapCollectionTagAggregations,
  mapCustomFieldValueList,
} from './model';

@injectable()
export class CollectionRepo extends RepoBase implements ICollectionRepo {
  getCollection: ICollectionRepo['getCollection'] = async (
    collectionId: string,
    fields: CollectionFieldParameter[] = [FieldParameter.AssetCount],
    include: CollectionIncludeParameter[] = [Relationship.BRANDFOLDER],
  ) => {
    return this.brandfolderApi
      .fetchCollection(await this.getApiKey(), collectionId, {
        fields,
        include,
      })
      .then((response) => {
        const collection = getResponseDataOrDefault(response);
        const included = getResponseIncluded(response);

        const brandfolder = included?.find(
          (resource) => resource.id === collection.relationships[Relationship.BRANDFOLDER].data.id,
        ) as BrandfolderDto | undefined;

        return mapCollection(collection, brandfolder);
      });
  };

  listCollections = async (fields: CollectionFieldParameter[] = []): Promise<Collection[]> => {
    return this.brandfolderApi
      .listCollections(await this.getApiKey(), {
        fields,
        include: [Relationship.BRANDFOLDER],
      })
      .then((response) => {
        const data = getResponseListDataOrDefault(response);
        const included = getResponseIncluded(response);

        return data.map((collection) => {
          const brandfolder = included.find(
            (resource) =>
              resource.id === collection.relationships[Relationship.BRANDFOLDER].data.id,
          ) as BrandfolderDto;

          return mapCollection(collection, brandfolder);
        });
      });
  };

  getCollectionCustomFields = async (collectionId: string): Promise<CollectionCustomField[]> => {
    const apiKey = await this.getApiKey();
    const searchableAggregations = await this.brandfolderApi.getCollectionSearchableAggregations(
      apiKey,
      collectionId,
    );
    const customFieldsResponse = await this.brandfolderApi.getCollectionCustomFieldKeys(
      apiKey,
      collectionId,
    );
    return getResponseListDataOrDefault(customFieldsResponse).map((customFieldDto) =>
      mapCollectionCustomField(collectionId, customFieldDto, searchableAggregations),
    );
  };

  getCollectionCustomFieldValues = async (
    collectionId: string,
    customFieldKey: string,
  ): Promise<CustomFieldValueOption[]> => {
    const apiKey = await this.getApiKey();

    const customFieldValuesResponse =
      await this.brandfolderApi.getCollectionCustomFieldValuesOptions(
        apiKey,
        collectionId,
        customFieldKey,
      );

    return mapCustomFieldValueList(customFieldValuesResponse.data, customFieldKey);
  };

  getCollectionFileTypeAggregates = async (
    collectionId: string,
  ): Promise<CollectionFileTypeAggregates[]> => {
    return await this.brandfolderApi
      .getCollectionSearchableAggregations(await this.getApiKey(), collectionId)
      .then((searchableAggregations) =>
        mapCollectionFileTypeAggregates(collectionId, searchableAggregations),
      );
  };

  getCollectionTags = async (collectionId: string): Promise<CollectionTag[]> => {
    const apiKey = await this.getApiKey();
    const searchableAggregations = await this.brandfolderApi.getCollectionSearchableAggregations(
      apiKey,
      collectionId,
    );
    return searchableAggregations.tags.map((tag) => mapCollectionTag(collectionId, tag));
  };

  getCollectionFiletypeAggregations = async (collectionId: string): Promise<CollectionFileTypeAggregates[]> => {
    const apiKey = await this.getApiKey();
    const filetypeAggregations = await this.brandfolderApi.getCollectionFiletypeAggregations(
      apiKey,
      collectionId,
    );
    return mapCollectionFiletypeAggregations(collectionId, filetypeAggregations);
  };

  getCollectionTagAggregations = async (collectionId: string): Promise<CollectionTag[]> => {
    const apiKey = await this.getApiKey();
    const tagAggregations = await this.brandfolderApi.getCollectionTagAggregations(
      apiKey,
      collectionId,
    );
    return mapCollectionTagAggregations(collectionId, tagAggregations);
  };

  updateCollectionName = async (id: string, name: string): Promise<Collection> => {
    const apiKey = await this.getApiKey();

    return this.brandfolderApi.updateCollectionName(apiKey, id, name);
  };

  removeAssetsFromCollections = async (
    collectionId: string,
    assetIds: string[],
  ): Promise<{ result: string }> => {
    const apiKey = await this.getApiKey();
    const body = {
      data: {
        attributes: {
          asset_keys: assetIds,
        },
      },
    };
    return this.brandfolderApi.removeAssetsFromCollections(apiKey, collectionId, body);
  };

  searchTags = async (collectionId: string, query: string): Promise<CollectionTag[]> => {
    const apiKey = await this.getApiKey();
    return this.brandfolderApi.searchFilterableTags(apiKey, collectionId, query);
  };
}
