import { ICdnService } from '@integration-frontends/integration/core/model';
import { supportsCdnTransforms } from './common';
import { injectable } from 'inversify';

@injectable()
export class CdnService implements ICdnService {
  supportsCdnTransform(url: string): boolean {
    return supportsCdnTransforms(url);
  }
}
