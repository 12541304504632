import React from 'react';
import classNames from 'classnames';
import { createIcon } from './create-icon';
import './icon-place-background.scss';

export const IconPlaceBackgroundInactive = createIcon(
  <div
    className="icon-place-background"
    style={{
      filter:
        'invert(84%) sepia(97%) saturate(0%) hue-rotate(181deg) brightness(88%) contrast(83%)',
    }}
  />,
);

export const IconPlaceBackgroundActive = createIcon(<div className="icon-place-background" />);

export function IconPlaceBackground({ active }) {
  return (
    <div className={classNames('flex items-center cursor-pointer, className')}>
      {active ? <IconPlaceBackgroundActive /> : <IconPlaceBackgroundInactive />}
    </div>
  );
}
