import {
  ApiFetchDataResponse,
  ApiListDataResponse,
  AssetDto,
  AssetOptions,
  AttachmentInputDto,
  CustomFieldKeyResponseData,
  CustomFieldKeyValueDto,
  CustomFieldValueDto,
  FieldParameter,
  GenericFileDto,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
  getResponseMeta,
  IncludedAttachmentDto,
  isError,
  Relationship,
  ResourceType as ApiResourceType,
  SortDirection,
  SortField as ApiSortField,
  TagDto,
  BulkDownloadAssetsDto,
  AddAssetTagsResponseData,
  AssetFieldParameter,
  AssetIdsDto,
  ApiIdsDataResponse,
  CLBrandfolderApi,
  ApiCountDataResponse,
  AssetCountDto,
  CollectionStorageDto,
  ApiStorageDataResponse,
} from '@integration-frontends/common/brandfolder-api';
import {
  Asset,
  AssetCountResults,
  AssetCustomFieldValue,
  AssetDetails,
  AssetIdResults,
  AssetIdsResultSetBase,
  AssetsCountResultSetBase,
  AssetsListResultSet,
  AssetsListResultSetBase,
  AssetTag,
  CollectionStorageResults,
  CollectionStorageResultSetBase,
  Container,
  IAssetRepo,
  ListOptions,
  PagedResults,
  ResourceType,
  Section,
} from '@integration-frontends/integration/core/model';
import { ResourceBaseSortableProperty } from '@integration-frontends/integration/core/model/entities/common/sorting';
import { injectable } from 'inversify';
import { compose, isEmpty } from 'ramda';
import {
  buildSearchQuery,
  buildSearchQueryBase,
  mapAsset,
  mapAssetCustomFieldValue,
  mapAssetTag,
  mapIncludedAttachmentDto,
} from './model';
import { RepoBase } from './repo-base';

@injectable()
export class AssetRepo extends RepoBase implements IAssetRepo {
  fetchAsset = async (assetId: string): Promise<Asset> => {
    return this.brandfolderApi
      .fetchAsset(await this.getApiKey(), assetId, this._generateDefaultAssetFieldOptions())
      .then(compose(mapAsset, getResponseDataOrDefault));
  };

  bulkAddAssetTags = async (
    assetIds: string[],
    tags: string[],
    collectionId?: string,
    locale?: string,
    source?: string,
  ): Promise<AddAssetTagsResponseData | AddAssetTagsResponseData[]> => {
    const apiKey = await this.getApiKey();

    if (collectionId) {
      return this.brandfolderApi.addAssetTags(apiKey, assetIds, tags, collectionId, locale, source);
    } else {
      const assetTags = assetIds.map(async (id) => {
        return this.brandfolderApi.addAssetTags(apiKey, id, tags);
      });

      return Promise.all(assetTags);
    }
  };

  addAssetTags = async (assetId: string, tags: string[]): Promise<AddAssetTagsResponseData> => {
    return this.brandfolderApi.addAssetTags(await this.getApiKey(), assetId, tags);
  };

  listCustomFieldKeys = async (container: Container): Promise<CustomFieldKeyResponseData> => {
    const brandfolderId: string =
      container.type === ResourceType.BRANDFOLDER ? container.id : container.brandfolderId;
    return this.brandfolderApi.listCustomFieldKeys(await this.getApiKey(), brandfolderId);
  };

  addAssetCustomFields = async (
    assetIds: string[],
    container: Container,
    customFields: CustomFieldKeyValueDto[],
  ): Promise<void> => {
    const brandfolderId: string =
      container.type === ResourceType.BRANDFOLDER ? container.id : container.brandfolderId;
    const existingCustomFieldNames = await this.listCustomFieldKeys(container).then((response) =>
      response.data.map((key) => key.attributes.name),
    );
    const nonExistingCustomFieldKeys = customFields.reduce((keys, customField) => {
      const key = Object.keys(customField)[0];
      if (!existingCustomFieldNames.includes(key)) {
        keys.push({
          name: key,
        });
      }
      return keys;
    }, []);

    if (nonExistingCustomFieldKeys.length) {
      await this.brandfolderApi
        .createCustomFieldKeys(await this.getApiKey(), brandfolderId, nonExistingCustomFieldKeys)
        .then(async () => {
          await this.brandfolderApi.addCustomFieldsToAsset(
            await this.getApiKey(),
            assetIds,
            brandfolderId,
            customFields,
          );
        });
    } else {
      await this.brandfolderApi.addCustomFieldsToAsset(
        await this.getApiKey(),
        assetIds,
        brandfolderId,
        customFields,
      );
    }
  };

  getAssetDetails = async (assetId: string): Promise<AssetDetails> => {
    const response = await this.brandfolderApi.fetchAsset(await this.getApiKey(), assetId, {
      include: [Relationship.CUSTOM_FIELDS, Relationship.TAGS],
    });
    if (!isError(response) && response.included != null) {
      return {
        tags: response.included
          .filter(({ type }) => type === ApiResourceType.TAG)
          .map((tagDto: TagDto) => mapAssetTag(assetId, tagDto)),
        customFieldValues: response.included
          .filter(({ type }) => type === ApiResourceType.CUSTOM_FIELD_VALUE)
          .map((customFieldValueDto: CustomFieldValueDto) =>
            mapAssetCustomFieldValue(assetId, customFieldValueDto),
          ),
      };
    } else {
      return {
        tags: <AssetTag[]>[],
        customFieldValues: <AssetCustomFieldValue[]>[],
      };
    }
  };

  listContainerAssetsBase = async (
    container: Container,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSetBase>> => {
    return this._listCollectionAssetsBase(container.id, options);
  };

  private _listCollectionAssetsBase = async (
    collectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSetBase>> => {
    return this.brandfolderApi
      .listCollectionAssets(await this.getApiKey(), collectionId, {
        ...this._generateDefaultAssetFieldOptionsBase(options),
      })
      .then((res) => this._mapAssetsListResponseBase(res, options));
  };

  listContainerAssetIdsBase = async (
    container: Container,
    options?: ListOptions,
  ): Promise<AssetIdResults<AssetIdsResultSetBase>> => {
    return this._listCollectionAssetIdsBase(container.id, options);
  };

  private _listCollectionAssetIdsBase = async (
    collectionId: string,
    options?: ListOptions,
  ): Promise<AssetIdResults<AssetIdsResultSetBase>> => {
    if (this.brandfolderApi instanceof CLBrandfolderApi) {
      return this.brandfolderApi
        .listCollectionAssetIds(await this.getApiKey(), collectionId, {
          ...this._generateDefaultAssetFieldOptionsBase(options),
        })
        .then((res) => this._mapAssetIdsResponseBase(res));
    }
  };

  listCollectionAssetsCountBase = async (
    collectionId: string,
  ): Promise<AssetCountResults<AssetsCountResultSetBase>> => {
    return this._listCollectionAssetsCountBase_(collectionId);
  };

  private _listCollectionAssetsCountBase_ = async (
    collectionId: string,
  ): Promise<AssetCountResults<AssetsCountResultSetBase>> => {
    if (this.brandfolderApi instanceof CLBrandfolderApi) {
      return this.brandfolderApi
        .listCollectionAssetsCount(await this.getApiKey(), collectionId)
        .then((res) => this._mapAssetsCountResponseBase(res));
    }
  };

  listCollectionStorageRemainingBase = async (
    collectionId: string,
  ): Promise<CollectionStorageResults<CollectionStorageResultSetBase>> => {
    if (this.brandfolderApi instanceof CLBrandfolderApi) {
      return this.brandfolderApi
        .listCollectionStorageRemaining(await this.getApiKey(), collectionId)
        .then((res) => this._mapCollectionStorageResponseBase(res));
    }
  };

  listContainerAssets = async (
    container: Container,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return container.type === ResourceType.BRANDFOLDER
      ? this._listBrandfolderAssets(container.id, options)
      : this._listCollectionAssets(container.id, options);
  };

  private _listBrandfolderAssets = async (
    brandfolderId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return this.brandfolderApi
      .listBrandfolderAssets(await this.getApiKey(), brandfolderId, {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS],
      })
      .then((res) => this._mapAssetsListResponse(res, options));
  };

  private _listCollectionAssets = async (
    collectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return this.brandfolderApi
      .listCollectionAssets(await this.getApiKey(), collectionId, {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS],
      })
      .then((res) => this._mapAssetsListResponse(res, options));
  };

  listSectionAssets = async (
    sectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return this.brandfolderApi
      .listSectionAssets(await this.getApiKey(), sectionId, {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS],
      })
      .then((res) => this._mapAssetsListResponse(res, options));
  };

  listCollectionSectionAssets = async (
    collectionId: string,
    sectionId: string,
    options?: ListOptions,
  ): Promise<PagedResults<AssetsListResultSet>> => {
    return this.brandfolderApi
      .listCollectionSectionAssets(await this.getApiKey(), collectionId, sectionId, {
        ...this._generateDefaultAssetListOptions(options),
        include: [Relationship.SECTION, Relationship.ATTACHMENTS],
      })
      .then((res) => this._mapAssetsListResponse(res, options));
  };

  listContainerSectionAssets = async (
    container: Container,
    sectionId: string,
    options?: ListOptions,
  ) => {
    return container.type === ResourceType.BRANDFOLDER
      ? this.listSectionAssets(sectionId, options)
      : this.listCollectionSectionAssets(container.id, sectionId, options);
  };

  listContainerSectionsAssets = async (
    container: Container,
    sections: Section[],
    options?: ListOptions,
  ): Promise<{ sectionId: string; results: PagedResults<AssetsListResultSet> }[]> => {
    return Promise.all(
      sections.map((section) =>
        this.listContainerSectionAssets(container, section.id, options).then((results) => ({
          sectionId: section.id,
          results,
        })),
      ),
    );
  };

  private _mapAssetsListResponse(
    res: ApiListDataResponse<AssetDto>,
    options?: ListOptions,
  ): PagedResults<AssetsListResultSet> {
    return {
      data: {
        assets: getResponseListDataOrDefault(res).map((asset) => mapAsset(asset)),
        attachments: getResponseListDataOrDefault(res).flatMap((asset) =>
          asset.relationships?.[Relationship.ATTACHMENTS].data.map(({ id }) =>
            mapIncludedAttachmentDto(
              getResponseIncluded(res).find((inc) => inc.id === id) as IncludedAttachmentDto,
              asset.id,
            ),
          ),
        ),
      },
      currentPage: getResponseMeta(res).current_page,
      totalCount: getResponseMeta(res).total_count,
      totalPages: getResponseMeta(res).total_pages,
      nextPage: getResponseMeta(res).next_page,
      perPage: options?.pagination?.perPage || 1,
    };
  }

  private _mapAssetsListResponseBase(
    res: ApiListDataResponse<AssetDto>,
    options?: ListOptions,
  ): PagedResults<AssetsListResultSetBase> {
    switch (getResponseMeta(res)?.pagination_type) {
      case 'countless':
        return {
          data: {
            assets: getResponseListDataOrDefault(res).map((asset) => mapAsset(asset)),
          },
          recordCount: getResponseMeta(res).record_count,
          from: getResponseMeta(res).from,
          to: getResponseMeta(res).to,
          offset: getResponseMeta(res).offset,
          previousPage: getResponseMeta(res).previous_page,
          currentPage: getResponseMeta(res).current_page,
          nextPage: getResponseMeta(res).next_page,
          paginationType: getResponseMeta(res).pagination_type,
        };
      case 'cursor':
        return {
          data: {
            assets: getResponseListDataOrDefault(res).map((asset) => mapAsset(asset)),
          },
          recordCount: getResponseMeta(res).record_count,
          moreExist: getResponseMeta(res).more_exist,
          sortOrder: getResponseMeta(res).sort_order,
          paginationType: getResponseMeta(res).pagination_type,
          nextCursor: getResponseMeta(res).next_cursor,
          nextCursorParam: getResponseMeta(res).next_cursor_param,
        };
      default:
        return {
          data: {
            assets: getResponseListDataOrDefault(res).map((asset) => mapAsset(asset)),
          },
          recordCount: getResponseMeta(res).record_count,
          currentPage: getResponseMeta(res).current_page,
          totalCount: getResponseMeta(res).total_count,
          totalPages: getResponseMeta(res).total_pages,
          nextPage: getResponseMeta(res).next_page,
          perPage: options?.pagination?.perPage || 1,
          paginationType: getResponseMeta(res).pagination_type,
        };
    }
  }

  private _mapAssetIdsResponseBase(
    res: ApiIdsDataResponse<AssetIdsDto>,
  ): AssetIdResults<AssetIdsResultSetBase> {
    return {
      data: {
        assetIds: isError(res) ? [] : res.data.ids,
      },
      recordCount: getResponseMeta(res).record_count,
    };
  }

  private _mapAssetsCountResponseBase(
    res: ApiCountDataResponse<AssetCountDto>,
  ): AssetCountResults<AssetsCountResultSetBase> {
    return {
      data: {
        count: isError(res) ? 0 : res.data.count,
      },
      cacheDurationSeconds: getResponseMeta(res).cache_duration_seconds,
      limitReached: getResponseMeta(res).limit_reached,
    };
  }

  private _mapCollectionStorageResponseBase(
    res: ApiStorageDataResponse<CollectionStorageDto>,
  ): CollectionStorageResults<CollectionStorageResultSetBase> {
    return {
      data: {
        remaining: isError(res) ? null : res.data.remaining,
      },
      storageLimit: getResponseMeta(res).storage_limit,
    };
  }

  create = async (
    container: Container,
    sectionId: string,
    name: string,
    files: File[],
    source?: string,
  ): Promise<ApiListDataResponse> => {
    const attachments: AttachmentInputDto[] = await Promise.all(
      files.map(async (file) => {
        const payload = await this.brandfolderApi.uploadFile(await this.getApiKey(), file);
        return {
          type: ApiResourceType.ATTACHMENT as ApiResourceType.ATTACHMENT,
          url: payload.objectUrl,
          filename: file.name,
          mimetype: file.type,
          source,
        };
      }),
    );

    const createAsset =
      container.type === ResourceType.BRANDFOLDER
        ? this.brandfolderApi.createBrandfolderAsset.bind(this.brandfolderApi)
        : this.brandfolderApi.createCollectionAsset.bind(this.brandfolderApi);

    return await createAsset(await this.getApiKey(), container.id, sectionId, name, attachments);
  };

  uppyUpload = async (
    sectionId: string,
    name: string,
    attachment: AttachmentInputDto,
    collectionId: string,
  ): Promise<ApiListDataResponse> => {
    const createAsset = this.brandfolderApi.createCollectionAsset.bind(this.brandfolderApi);
    return await createAsset(await this.getApiKey(), collectionId, sectionId, name, [attachment]);
  };

  createExternalMedia = async (
    container: Container,
    sectionId: string,
    source: string,
    externalMedia: {
      url: string;
      name: string;
    },
  ): Promise<ApiFetchDataResponse<GenericFileDto>> => {
    const createAsset =
      container.type === ResourceType.BRANDFOLDER
        ? this.brandfolderApi.createExternalBrandfolderAsset.bind(this.brandfolderApi)
        : this.brandfolderApi.createExternalCollectionAsset.bind(this.brandfolderApi);
    return await createAsset(await this.getApiKey(), container.id, sectionId, externalMedia);
  };

  bulkDownloadAssets = async (
    assetIds: string[],
    name?: string,
  ): Promise<BulkDownloadAssetsDto> => {
    return await this.brandfolderApi.bulkDownloadAssets(await this.getApiKey(), assetIds, name);
  };

  private _generateDefaultAssetFieldOptions(exclusions?: AssetOptions): AssetOptions {
    const defaultFields = [
      FieldParameter.CardImage,
      FieldParameter.AttachmentCount,
      FieldParameter.CdnUrl,
      FieldParameter.CreatedAt,
      FieldParameter.ExpiresOn,
      FieldParameter.Extension,
      FieldParameter.UpdatedAt,
      FieldParameter.Availability,
    ];

    const filteredFields = exclusions
      ? defaultFields.filter((field) => !exclusions.fields?.includes(field as AssetFieldParameter))
      : defaultFields;

    return {
      fields: filteredFields as AssetFieldParameter[],
    };
  }

  private _generateDefaultAssetListOptions(
    options: ListOptions,
    exclusions?: AssetOptions,
  ): AssetOptions {
    const sortFieldMap = {
      [ResourceBaseSortableProperty.Position]: ApiSortField.Position,
      [ResourceBaseSortableProperty.CreatedAt]: ApiSortField.CreatedAt,
      [ResourceBaseSortableProperty.Name]: ApiSortField.Name,
      [ResourceBaseSortableProperty.UpdatedAt]: ApiSortField.UpdatedAt,
      [ResourceBaseSortableProperty.Popularity]: ApiSortField.Score,
    };

    return {
      ...this._generateDefaultAssetFieldOptions(exclusions),
      search: buildSearchQuery(options?.searchParams),
      per: options?.pagination?.perPage,
      page: options?.pagination?.page,
      sort: {
        direction: (options?.sort?.direction === 'DESC' && SortDirection.DESC) || SortDirection.ASC,
        field: sortFieldMap[options?.sort?.field] || ApiSortField.Position,
      },
    };
  }

  private _generateDefaultAssetFieldOptionsBase(options: ListOptions): AssetOptions {
    const sortFieldMap = {
      [ResourceBaseSortableProperty.Position]: ApiSortField.Position,
      [ResourceBaseSortableProperty.CreatedAt]: ApiSortField.CreatedAt,
      [ResourceBaseSortableProperty.Name]: ApiSortField.Name,
      [ResourceBaseSortableProperty.UpdatedAt]: ApiSortField.UpdatedAt,
    };
    const searchQuery = buildSearchQueryBase(options?.searchParams);

    const searchQueryBase: AssetOptions = {
      search_query: searchQuery,
      per: options?.pagination?.perPage || 48,
      page: options?.pagination?.page,
      sort: {
        direction: (options?.sort?.direction === 'DESC' && SortDirection.DESC) || SortDirection.ASC,
        field: sortFieldMap[options?.sort?.field] || ApiSortField.CreatedAt,
      },
    };

    const isCountBasedPagination = !isEmpty(searchQuery) && Boolean(searchQuery);
    const isCursorBasedPagination =
      options?.sort?.field === ResourceBaseSortableProperty.CreatedAt &&
      options?.sort?.direction === 'DESC';

    // If search params are present, enable count_based_pagination
    if (isCountBasedPagination) {
      searchQueryBase.count_based_pagination = true;
    } else if (isCursorBasedPagination) {
      // Remove sort field if sorting is the default option
      // This results in cursor_pagination being utilized instead of countless (if no search is present)
      delete searchQueryBase.sort.field;
      if (options?.sort?.direction === 'DESC') {
        searchQueryBase.before = options?.before;
      } else {
        searchQueryBase.after = options?.after;
      }
    } else {
      // Countless pagination
      searchQueryBase.page = options?.pagination?.page;
    }

    return searchQueryBase;
  }
}
