import {
  ConfigSettingStateService,
  configSettingStateServiceKey,
} from '@smartsheet/config-setting-state-service';
import {
  SettingsStateService,
  settingsStateServiceDIKey,
} from '@smartsheet/settings-state-service';
import { diContainer } from '@smartsheet/cufflink';

import { FeatureFlags } from './feature-flags';

export function isFeatureFlagEnabled(id: FeatureFlags): boolean {
  try {
    const configSettingStateService = diContainer.get<ConfigSettingStateService>(
      configSettingStateServiceKey.name,
    );

    return configSettingStateService.getSetting(id)?.valueBoolean || false;
  } catch (error) {
    console.warn(
      `Failed to get config setting state service. Assuming feature flag ${id} is disabled`,
      error,
    );

    return false;
  }
}

export function isFeatureFlagEnabled_V2(settingName: string) {
  try {
    const settingsStateService = diContainer.get<SettingsStateService>(settingsStateServiceDIKey);
    const setting = settingsStateService.getUserSettingValue(settingName);
    return setting?.valueBoolean || false;
  } catch {
    return false;
  }
}

export function isEapSettingEnabled(settingName: string): boolean {
  try {
    const settingsStateService = diContainer.get<SettingsStateService>(settingsStateServiceDIKey);
    return settingsStateService.isEAPSettingEnabled(settingName);
  } catch {
    return false;
  }
}

export function getFeatureFlagStringValue(settingName: string) {
  try {
    const settingsStateService = diContainer.get<SettingsStateService>(settingsStateServiceDIKey);
    const setting = settingsStateService.getUserSettingValue(settingName);
    return setting?.valueString || '';
  } catch {
    return '';
  }
}
