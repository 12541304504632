import { Header } from './header';
import * as React from 'react';
import { useState } from 'react';
import { AttachmentSelector } from '../../../../libs/integration/ui/src';
import './select-attachment.scss';

export interface SelectAttachmentProps {
  hideHeader?: boolean;
}

export function SelectAttachment({ hideHeader }: SelectAttachmentProps) {
  const [containerId, setContainerId] = useState(null);
  const [assetId, setAssetId] = useState(null);

  return (
    <>
      {(!hideHeader && 
        <Header containerId={containerId} assetId={assetId} />
      )}
      <div id="attachment-selector-container">
        <AttachmentSelector containerSelect={setContainerId} assetSelect={setAssetId} />
      </div>
    </>
  );
}
