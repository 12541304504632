import { all, call, fork } from 'redux-saga/effects';
import { authWorkflow } from './auth-workflow';
import { backToHomeEffects } from './back-to-home';
import { editAttachmentWorkflow } from './edit-attachment-workflow';
import { initWorkflow } from './init-workflow';
import { selectAttachmentWorkflow } from './select-attachment-workflow';
import { getObservabilityService } from '@integration-frontends/core';

export function* rootWorkflow() {
  try {
    yield all([
      fork(authWorkflow),
      fork(backToHomeEffects),
      fork(editAttachmentWorkflow),
      fork(initWorkflow),
      fork(selectAttachmentWorkflow),
    ]);
  } catch (e) {
    yield call(getObservabilityService().addError, e);
  }
}
