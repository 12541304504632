import { getParam } from './get-param';

const origin = getParam('origin');

export const sendMessage = (msg) => {
  console.log('Sending:', msg);
  if (origin) {
    console.log('Sending to origin', origin);
    const targetWindow = window.parent || window.opener;
    targetWindow.postMessage(msg, origin);
  } else {
    window.postMessage(msg);
  }
};
