import { DI_CONTAINER } from '@integration-frontends/core';
import {
  attachmentEdited,
  editAttachmentCanceled,
  editAttachmentEnd,
} from '@integration-frontends/integration/core/application';
import {
  ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  IAttachmentTransformService,
} from '@integration-frontends/integration/core/model';
import { AttachmentRenderer } from '@integration-frontends/integration/ui';
import * as SDK from 'blocksdk';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { all, call, put, takeEvery } from 'typed-redux-saga';
import { goBack, push } from 'redux-first-history';

const sfSdk = new SDK();

const onAttachmentEdited = function* (action: ReturnType<typeof attachmentEdited>) {
  sfSdk.setData(JSON.stringify(action.payload));
  yield;
};

const onEditAttachmentCanceled = function* (action: ReturnType<typeof editAttachmentCanceled>) {
  const canGoBack = !history.state;

  if (!canGoBack) {
    const { attachment } = action.payload;
    yield put(push(`/select-attachment/asset-details/${attachment.assetId}`));
  } else {
    yield put(goBack());
  }
};

const onEditAttachmentEnd = function* (action: ReturnType<typeof editAttachmentEnd>) {
  const attachmentVariantService: IAttachmentTransformService = DI_CONTAINER.get(
    ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  );
  const { alignment, attachment, labelOptions, linkOptions, transforms } = action.payload;
  const variant = yield transforms &&
    call(attachmentVariantService.transform, attachment, transforms);

  const element = (
    <AttachmentRenderer
      attachment={variant || attachment}
      alignment={alignment}
      labelOptions={labelOptions}
      linkOptions={linkOptions}
    />
  );
  const html = ReactDOMServer.renderToStaticMarkup(element);
  sfSdk.setSuperContent(html);
  sfSdk.setContent(html);
  yield;
};

export function* editAttachmentWorkflow() {
  yield all([
    takeEvery(editAttachmentEnd, onEditAttachmentEnd),
    takeEvery(attachmentEdited, onAttachmentEdited),
    takeEvery(editAttachmentCanceled, onEditAttachmentCanceled),
  ]);
}
