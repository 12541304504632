import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ATTACHMENT_ACTION_PROVIDER_TOKEN,
  AttachmentActionProviderContext,
  IAttachmentActionProvider,
} from '../../index';
import { useContext } from 'react';
import { PlaceAttachmentActionContainer } from '@integration-frontends/integration/ui/attachment-selector/common/attachment-actions/place-attachment-action-container';

export const useAttachmentActions = () => {
  const attachmentActionProvider: IAttachmentActionProvider = DI_CONTAINER.get(
    ATTACHMENT_ACTION_PROVIDER_TOKEN,
  );
  const attachmentActionProviderContext = useContext(AttachmentActionProviderContext);

  const customActionContainers = attachmentActionProvider.getActionContainers();
  const contextualActionContainers = attachmentActionProviderContext.actionContainers;

  return {
    attachmentActionContainers: [
      ...(customActionContainers.length
        ? customActionContainers
        : [PlaceAttachmentActionContainer]),
      ...contextualActionContainers,
    ],
  };
};
