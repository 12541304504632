import { takeEvery } from 'typed-redux-saga';
import { addAssetTagsReset, contentLibraryAddAssetTagsComplete } from '../actions';
import { uploadTagsSelectors } from '../../../selectors';
import { put, select } from 'redux-saga/effects';
import { diContainer } from '@smartsheet/cufflink';
import { BannerType, createBanner } from '@integration-frontends/common/notifications';
import {
  ContentLibraryStateService,
  contentLibraryStateServiceDIKey,
} from '@smartsheet/content-library-state-service';
import { t } from '@lingui/macro';

export function* handler() {
  const successfulAssetIds: string[] = yield select(uploadTagsSelectors.selectUploadTagsSuccess);

  if (successfulAssetIds.length > 0) {
    addSuccessfulAssetIdsToStateService(successfulAssetIds);
    yield put(
      createBanner({
        id: `upload-tags-success-${crypto.randomUUID()}`,
        type: BannerType.Info,
        bannerContent: {
          contentText: t`Bulk tagging in progress. Please check back later.`,
          displayDismissButton: true,
        },
      }),
    );
  }

  yield put(addAssetTagsReset());
}

export function* contentLibraryAddAssetTagsCompleteEffects() {
  yield takeEvery(contentLibraryAddAssetTagsComplete, handler);
}

function addSuccessfulAssetIdsToStateService(assetIds: string[]) {
  const contentLibraryStateService = diContainer.get<ContentLibraryStateService>(
    contentLibraryStateServiceDIKey,
  );

  contentLibraryStateService.setBulkTaggedAssets(assetIds);
}
