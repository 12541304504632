import { ButtonLooks } from '@brandfolder/react';
import { Button, ButtonProps } from '@smartsheet/lodestar-core';
import React, { forwardRef } from 'react';
import { BFStandardButtonProps } from './bf-standard-button';
import { useTheme } from '@smartsheet/lodestar-core';
import { ThemeKey } from '@smartsheet/design-tokens';

export const BFStandardButtonSmartsheet = forwardRef<HTMLButtonElement, BFStandardButtonProps>(
  ({ disabled, look, ...restProps }, ref) => {
    const { key } = useTheme();
    return (
      <Button
        appearance={mapLookToAppearance(look, key as ThemeKey)}
        isDisabled={disabled}
        {...restProps}
        ref={ref}
      />
    );
  },
);

function mapLookToAppearance(
  look: BFStandardButtonProps['look'],
  theme: ThemeKey,
): ButtonProps['appearance'] {
  switch (look) {
    case ButtonLooks.Primary:
      return `primary${theme === 'ursa-light' ? '-filled' : ''}`;
    case ButtonLooks.Secondary:
      return `secondary${theme === 'ursa-light' ? '-filled' : ''}`;
    case ButtonLooks.Danger:
      return `destructive${theme === 'ursa-light' ? '-filled' : ''}`;
    case ButtonLooks.White:
      return 'secondary-outlined';
    case 'canvas-empty':
      return 'canvas-empty';
    default:
      return `primary${theme === 'ursa-light' ? '-filled' : ''}`;
  }
}
