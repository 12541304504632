import { BFInput, BFLabel } from '@integration-frontends/common/ui';
import {
  ResizingOptions,
  setHeight,
  setWidth,
} from '@integration-frontends/integration/core/application';
import React from 'react';
import { Trans } from '@lingui/macro';

export interface ResizerProps {
  options: ResizingOptions;
  onChange: (options: ResizingOptions) => void;
}

export function Resizer({ options, onChange }: ResizerProps) {
  const { newDimensions } = options;

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="gap-lg flex justify-start">
        <div className="flex flex-auto flex-col">
          <BFLabel>
            <Trans>Image Width</Trans> (px)
          </BFLabel>
          <BFInput
            data-cy="resize-width"
            data-testid="resize-width"
            className="w-full"
            type="number"
            value={Math.round(newDimensions?.width) || ''}
            onChange={(e) => onChange(setWidth(options, parseInt(e.target.value)))}
          />
        </div>

        <div className="flex flex-auto flex-col">
          <BFLabel>
            <Trans>Image Height</Trans> (px)
          </BFLabel>
          <BFInput
            data-cy="resize-height"
            data-testid="resize-height"
            className="w-full"
            type="number"
            value={Math.round(newDimensions?.height) || ''}
            onChange={(e) => onChange(setHeight(options, parseInt(e.target.value)))}
          />
        </div>
      </div>
    </div>
  );
}
