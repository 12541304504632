import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const addAssetTagsInit = createAction(
  'ADD_ASSET_TAGS_INIT',
  withPayloadType<{
    assetIds: string[];
    tags: string[];
    totalAssets: number;
    onAddAssetTagError?: (errorType) => void;
  }>(),
);
export const contentLibraryAddAssetTagsInit = createAction(
  'CONTENT_LIBRARY_ADD_ASSET_TAGS_INIT',
  withPayloadType<{
    assetIds: string[];
    collectionId: string;
    tags: string[];
    totalAssets?: number;
    source: string;
    onAddAssetTagError?: (errorType) => void;
  }>(),
);
export const addAssetTagSuccess = createAction(
  'ADD_ASSET_TAG_SUCCESS',
  withPayloadType<{ assetIds: string[] }>(),
);
export const addAssetTagFailure = createAction(
  'ADD_ASSET_TAG_FAILURE',
  withPayloadType<{ assetId: string }>(),
);
export const contentLibraryAddAssetTagsComplete = createAction(
  'CONTENT_LIBRARY_ADD_ASSET_TAGS_COMPLETE',
);
export const addAssetTagsComplete = createAction('ADD_ASSET_TAGS_COMPLETE');
export const addAssetTagsReset = createAction('ADD_ASSET_TAGS_RESET');
