import { DI_CONTAINER } from '@integration-frontends/core';
import { IGetAuthToken } from '@integration-frontends/integration/infrastructure/isomorphic';
import {
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';

export const getApiKeyIdentityStrategy: IGetAuthToken = async () => {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  return (await identityStore.get())?.token;
};
