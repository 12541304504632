import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
  getResponseDataOrDefault,
} from '@integration-frontends/common/brandfolder-api';
import {
  Asset,
  Container,
  ISharingService,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { inject, injectable } from 'inversify';
import { GET_API_KEY_TOKEN, IGetAuthToken } from './model';

@injectable()
export class SharingService implements ISharingService {
  @inject(BRANDFOLDER_API_TOKEN)
  private brandfolderApi: BrandfolderApi;
  @inject(GET_API_KEY_TOKEN)
  private getApiKey: IGetAuthToken;

  getAssetShareUrl = async (container: Container, asset: Asset): Promise<string> => {
    const apiKey = await this.getApiKey();
    const response =
      container.type === ResourceType.BRANDFOLDER
        ? await this.brandfolderApi.getBrandfolderAssetShareUrl(apiKey, container.id, asset.id)
        : await this.brandfolderApi.getCollectionAssetShareUrl(apiKey, container.id, asset.id);

    return getResponseDataOrDefault(response)?.attributes.link;
  };
}
