import { createResolvableComponent } from '../../create-resolvable-component';
import { BFStandardBannerProps, BF_STANDARD_BANNER_COMPONENT } from './bf-standard-banner-base';
import { BFStandardBannerSmartsheet } from './bf-standard-banner-smartsheet';

export const BFStandardBanner = createResolvableComponent<BFStandardBannerProps>(
  BF_STANDARD_BANNER_COMPONENT,
);

export const BANNER_COMPONENT = {
  [BF_STANDARD_BANNER_COMPONENT]: BFStandardBannerSmartsheet,
};
