import React, { useContext } from 'react';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { SearchParameters, SortOptions } from '@integration-frontends/integration/core/model';
import { compose, isNil, not } from 'ramda';
import { useDispatch } from 'react-redux';
import {
  ISelectAttachmentOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import { DI_CONTAINER } from '@integration-frontends/core';
import * as qs from 'qs';
import { push } from 'redux-first-history';
import { Path } from 'react-router';
import { omit } from 'lodash';

export enum AttachmentSelectorView {
  ContainerSelector = 'ATTACHMENT_SELECTOR_CONTAINER_SELECTOR_VIEW',
  ShowPage = 'ATTACHMENT_SELECTOR_SHOW_PAGE_VIEW',
  AssetDetails = 'ATTACHMENT_SELECTOR_ASSET_DETAILS_VIEW',
  AssetDetailsNoContainer = 'ATTACHMENT_SELECTOR_ASSET_DETAILS_NO_CONTAINER',
  SectionUploadPage = 'SECTION_UPLOAD_PAGE_VIEW',
}

export const ATTACHMENT_SELECTOR_ROUTES: {
  [view: string]: { route: string; parent?: AttachmentSelectorView };
} = {
  [AttachmentSelectorView.ContainerSelector]: { route: '' },
  [AttachmentSelectorView.ShowPage]: { route: '/:containerId' },
  [AttachmentSelectorView.AssetDetails]: {
    route: '/asset-details/:assetId',
    parent: AttachmentSelectorView.ShowPage,
  },
  [AttachmentSelectorView.AssetDetailsNoContainer]: { route: '/asset-details/:assetId' },
  [AttachmentSelectorView.SectionUploadPage]: {
    route: '/section-upload-page',
    parent: AttachmentSelectorView.ShowPage,
  },
};

export function resolveFullRoute(view: AttachmentSelectorView): string {
  const routeDef = ATTACHMENT_SELECTOR_ROUTES[view];
  return routeDef.parent ? `${resolveFullRoute(routeDef.parent)}${routeDef.route}` : routeDef.route;
}

export interface AttachmentSelectorRouteParams {
  containerId?: string;
  assetId?: string;
  attachmentId?: string;
}

export const ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT = React.createContext<{
  navigationRootPath: Path;
}>(null);

export function useAttachmentSelectorNavigation() {
  const params = useParams<{ containerId: string }>();
  const location = useLocation();
  const dispatch = useDispatch();
  const { navigationRootPath } = useContext(ATTACHMENT_SELECTOR_NAVIGATION_CONTEXT);
  const { customSortOptions, searchParameters: customSearchParameters }: ISelectAttachmentOptions =
    DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);

  const urlSearch = qs.parse(location.search.substr(1)) as {
    searchParams?: string;
    sortOptions?: string;
  }; // substr(1) removes the leading '?'

  function getRoute(view: AttachmentSelectorView): string {
    return ATTACHMENT_SELECTOR_ROUTES[view]?.route;
  }

  function goToContainerSelector() {
    goTo(generatePath(resolveFullRoute(AttachmentSelectorView.ContainerSelector)));
  }

  function getDefaultSortOptions() {
    return urlSearch.sortOptions ? JSON.parse(urlSearch.sortOptions) : customSortOptions || null;
  }

  function getDefaultSearchParams() {
    return urlSearch.searchParams ? JSON.parse(urlSearch.searchParams) : null;
  }

  function goToShowPage(
    containerId: string,
    searchParams: SearchParameters = getDefaultSearchParams(),
    sortOptions: SortOptions = getDefaultSortOptions(),
  ) {
    const serialize = compose(encodeURIComponent, JSON.stringify);
    const isDefined = compose(not, isNil);
    goTo(
      generatePath(resolveFullRoute(AttachmentSelectorView.ShowPage), {
        containerId,
      }),
      [
        searchParams && `searchParams=${serialize(searchParams)}`,
        sortOptions && `sortOptions=${serialize(sortOptions)}`,
      ]
        .filter(isDefined)
        .join('&'),
    );
  }

  function goToAssetDetails(assetId: string, containerId: string, attachmentId?: string) {
    const searchParams = new URLSearchParams(location.search);
    attachmentId && searchParams.append('selectedAttachmentId', attachmentId);

    goTo(
      generatePath(resolveFullRoute(AttachmentSelectorView.AssetDetails), {
        assetId,
        containerId,
      }),
      searchParams.toString(),
    );
  }

  function goToSectionUploadPage(containerId: string) {
    const searchParams = new URLSearchParams(location.search);
    goTo(
      generatePath(resolveFullRoute(AttachmentSelectorView.SectionUploadPage), {
        containerId,
      }),
      searchParams.toString(),
    );
  }

  function goTo(childRoute: string, search?: string) {
    const parentPath = navigationRootPath.pathname === '/' ? '' : navigationRootPath.pathname;
    dispatch(push({ pathname: `${parentPath}${childRoute}`, search }));
  }

  return {
    ...params,
    getRoute,
    goToAssetDetails,
    goToContainerSelector,
    goToShowPage,
    goToSectionUploadPage,
  };
}
