import {
  CUSTOM_DIMENSIONS,
  CustomDimension,
  Platform,
  PLATFORM_ASSET_TYPE_DIMENSIONS,
  PlatformAssetType,
  SocialMediaPlatform,
} from '@integration-frontends/integration/core/model';
import { CropType } from '@integration-frontends/integration/core/application';
import { getCustomDimensionKey, getPlatformAssetTypeKey } from '../../common/i18n';
import { t } from '@lingui/macro';
export interface Option<T extends string | number = string | number> {
  translatedLabel: string;
  value: T;
}

export function getCropTypeOptions(): Option<CropType>[] {
  return [
    { translatedLabel: t`Freeform`, value: CropType.Freeform },
    { translatedLabel: t`Social Media`, value: CropType.SocialMedia },
    { translatedLabel: t`Custom`, value: CropType.Custom },
  ];
}

export function getSocialMediaOptions(): Option<SocialMediaPlatform>[] {
  return [
    { translatedLabel: t`Facebook`, value: Platform.Facebook },
    { translatedLabel: t`Twitter`, value: Platform.Twitter },
    { translatedLabel: t`LinkedIn`, value: Platform.LinkedIn },
    { translatedLabel: t`Instagram`, value: Platform.Instagram },
    { translatedLabel: t`Pintreset`, value: Platform.Pinterest },
  ];
}

export function getSocialMediaImageSizeOptions(socialMedia: SocialMediaPlatform): Option[] {
  return Object.keys(PLATFORM_ASSET_TYPE_DIMENSIONS[socialMedia] ?? []).map((type) => {
    return {
      translatedLabel: getPlatformAssetTypeKey(socialMedia, type as PlatformAssetType),
      value: type,
    };
  });
}

export function getCustomImageSizeOptions(): Option[] {
  return Object.keys(CUSTOM_DIMENSIONS).map((customDimension) => {
    return {
      translatedLabel: getCustomDimensionKey(customDimension as CustomDimension),
      value: customDimension,
    };
  });
}
