import {
  Container,
  IUserSettingsRepo,
  UploadPreferences,
  UploadPreferencesResults,
  uploadPreferencesMockData,
} from '@integration-frontends/integration/core/model';

export class UserSettingsRepoMock implements IUserSettingsRepo {
  setAssetUploadPreference(
    organizationId: string,
    container: Container,
    sectionId: string,
  ): Promise<UploadPreferencesResults> {
    return Promise.resolve(uploadPreferencesMockData);
  }
  getAssetUploadPreference(): Promise<UploadPreferencesResults> {
    return Promise.resolve(uploadPreferencesMockData);
  }
}
