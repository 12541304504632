import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
} from '@integration-frontends/core';
import {
  containerSelectors,
  ISelectAttachmentOptions,
  searchAssetsSelectors,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import {
  areSearchParamsEqual,
  createSearchParams,
  DEFAULT_SORT_OPTIONS,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import './advanced-filters-component.scss';
import { useAttachmentSelectorNavigation } from '../../navigation';
import {
  ADVANCED_FILTERS_COMPONENT_KEY,
  AdvancedFiltersComponent,
  AdvancedFiltersProps,
} from './advanced-filters-component';

export type AdvancedFiltersContainerProps = Pick<
  AdvancedFiltersProps,
  'onClose' | 'onApply' | 'responsive' | 'showAdvancedFiltersView'
> & {
  containerId: string;
  searchParams: SearchParameters;
  sortOptions: SortOptions;
};

export function AdvancedFiltersContainer({
  containerId,
  searchParams,
  sortOptions,
  responsive,
  ...restProps
}: AdvancedFiltersContainerProps) {
  const [localSearchParams, setLocalSearchParams] = useState(searchParams);
  const [initialSearchParams] = useState(searchParams);
  const [localSortOptions, setLocalSortOptions] = useState(sortOptions);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const { customSortOptions, enforcedSearchParameters }: ISelectAttachmentOptions =
    DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);
  const navigation = useAttachmentSelectorNavigation();
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const Component: FunctionComponent<AdvancedFiltersProps> =
    componentResolver.getComponent(ADVANCED_FILTERS_COMPONENT_KEY) || AdvancedFiltersComponent;

  const containerCustomFields = useSelector(
    containerSelectors.customFields.byContainerId(selectedContainer?.id),
  );
  const fileTypeAggregates = useSelector(
    searchAssetsSelectors.filterableFileTypeAggregatesByContainerId(selectedContainer?.id),
  );
  const tags = useSelector(
    searchAssetsSelectors.filterableTagsByContainerId(selectedContainer?.id),
  );

  function searchParamsChanged(searchParams: SearchParameters) {
    if (areSearchParamsEqual(searchParams, initialSearchParams)) {
      setIsFormDirty(false);
    } else {
      setIsFormDirty(true);
    }

    setLocalSearchParams(searchParams);
  }

  function clear() {
    searchParamsChanged(createSearchParams());
    setLocalSortOptions(customSortOptions || DEFAULT_SORT_OPTIONS);
    navigation.goToShowPage(navigation.containerId);
  }

  return (
    <Component
      container={selectedContainer}
      containerCustomFields={containerCustomFields}
      dirty={isFormDirty}
      fileTypeAggregates={fileTypeAggregates}
      onClear={clear}
      searchParams={localSearchParams}
      searchParamsChanged={searchParamsChanged}
      sortOptions={localSortOptions}
      tags={tags}
      responsive={responsive}
      {...restProps}
    />
  );
}
