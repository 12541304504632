import { useComponentResolver } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import React, { FunctionComponent } from 'react';
import {
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from '../../../../features';
import { AttachmentThumbnailProps } from './attachment-thumbnail-props';
import { DraggableAttachmentThumbnail } from './draggable-attachment-thumbnail';
import { NavigatableAttachmentThumbnail } from './navigatable-attachment-thumbnail';

export const ATTACHMENT_THUMBNAIL_COMPONENT_TOKEN = 'ATTACHMENT_THUMBNAIL_COMPONENT_TOKEN';

export function AttachmentThumbnail(props: AttachmentThumbnailProps) {
  const featureService: IAttachmentSelectorFeaturesService = useDependencyInjector(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );
  const AttachmentThumbnailComponent: FunctionComponent<AttachmentThumbnailProps> =
    useComponentResolver(ATTACHMENT_THUMBNAIL_COMPONENT_TOKEN) ||
    (featureService.hasFeature(AttachmentSelectorFeature.DragAndDrop) &&
      props.asset?.availability !== 'view_only')
      ? DraggableAttachmentThumbnail
      : NavigatableAttachmentThumbnail;

  return <AttachmentThumbnailComponent {...props} />;
}
