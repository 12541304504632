import { createResolvableComponent } from '../../create-resolvable-component';
import { BFStandardToastProps, BF_STANDARD_TOAST_COMPONENT } from './bf-standard-toast-base';
import { BFStandardToastSmartsheet } from './bf-standard-toast-smartsheet';

export const BFStandardToast = createResolvableComponent<BFStandardToastProps>(
  BF_STANDARD_TOAST_COMPONENT,
);

export const TOAST_COMPONENT = {
  [BF_STANDARD_TOAST_COMPONENT]: BFStandardToastSmartsheet,
};
