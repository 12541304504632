import { enterToClick, IconCaretDown, IconCaretRight } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import classNames from 'classnames';
import OverlayScrollbars from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { HTMLAttributes, LegacyRef, useImperativeHandle, useRef, useState } from 'react';
import {
  INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  IntegrationUiStylingOptions,
} from '../../styling-options';
import './page.scss';

export type PageContainerProps = HTMLAttributes<HTMLDivElement>;

export const PageContainer = ({ className, ...restProps }: PageContainerProps) => {
  const stylingOptions = DI_CONTAINER.get<IntegrationUiStylingOptions>(
    INTEGRATION_UI_STYLING_OPTIONS_TOKEN,
  );

  return (
    <div
      className={classNames('page-container', className)}
      style={{ width: '100%', height: '100%', backgroundColor: stylingOptions.backgroundColor }}
      {...restProps}
    />
  );
};

export type PageScrollContainerProps = HTMLAttributes<HTMLDivElement> & {
  scrollOptions?: OverlayScrollbars.Options;
};

export const PageScrollContainer = React.forwardRef(
  ({ className, scrollOptions, ...restProps }: PageScrollContainerProps, ref: LegacyRef<any>) => {
    return (
      <OverlayScrollbarsComponent
        className={classNames('page-scroll-container', className)}
        ref={ref}
        options={scrollOptions}
        {...restProps}
      />
    );
  },
);

export function PageHeader(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="page-header" {...props} />;
}

export function PageNav({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames('page-nav', className)} {...props} />;
}

export type PageContentProps = HTMLAttributes<HTMLDivElement> & { fullWidth?: boolean };

export function PageContent({ className, fullWidth, ...props }: PageContentProps) {
  return (
    <div
      className={classNames('page-content', { 'page-content-full-width': fullWidth }, className)}
      {...props}
    />
  );
}

export type PageSectionProps = {
  title?: string;
  expandable?: boolean;
  initialExpanded?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const PageSection = React.forwardRef(
  (
    {
      children,
      className,
      expandable = false,
      initialExpanded,
      title,
      ...restProps
    }: PageSectionProps,
    ref,
  ) => {
    const [internalExpanded, setInternalExpanded] = useState(initialExpanded);
    const isExpanded = !expandable || internalExpanded;
    const elementRef = useRef(null);

    useImperativeHandle(ref, () => ({
      open: () => {
        setInternalExpanded(true);
      },
      scrollIntoView: () => {
        elementRef?.current.scrollIntoView({ behavior: 'smooth' });
      },
    }));

    return (
      <article
        aria-label={title}
        ref={elementRef}
        className={classNames('page-section', className, { expanded: isExpanded })}
        {...restProps}
      >
        {expandable && (
          <div
            aria-label="Toggle expand"
            className="toggle-expand"
            data-testid="toggle-expand"
            onClick={() => setInternalExpanded(!isExpanded)}
            onKeyDown={enterToClick}
            role="button"
            tabIndex={0}
          />
        )}
        {title && (
          <div className={classNames('page-section-title', { expanded: isExpanded })}>
            {expandable ? (
              <>
                {isExpanded ? (
                  <>
                    <IconCaretDown aria-label="Close section" className="expand-icon" />
                    <span className="font-medium">{title}</span>
                  </>
                ) : (
                  <>
                    <IconCaretRight
                      aria-label="Expand section"
                      className="expand-icon"
                      role="button"
                    />
                    <h4>{title}</h4>
                  </>
                )}
              </>
            ) : (
              <h4 className="font-medium">{title}</h4>
            )}
          </div>
        )}

        {isExpanded && <div className="page-section-body">{children}</div>}
      </article>
    );
  },
);

export type PageSubSectionProps = {
  title?: string;
} & HTMLAttributes<HTMLDivElement>;

export function PageSubSection({ children, title, ...restProps }: PageSubSectionProps) {
  return (
    <article className="page-subsection" {...restProps} aria-label={title}>
      {title && (
        <div className="page-subsection-title">
          <h3>{title}</h3>
        </div>
      )}
      {children}
    </article>
  );
}

export function PageSectionContent({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  return <article className={classNames('page-section-content', className)} {...restProps} />;
}

export function PageActions(props: HTMLAttributes<HTMLDivElement>) {
  return <div className="page-actions" {...props} />;
}
