import { put, select } from 'typed-redux-saga';
import { addFilesProcessing, uploadAssetFailure, uploadAssetSuccess } from '../../../actions';
import { ASSET_REPO_TOKEN, IAssetRepo } from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { AssetUpload, MAX_FILE_SIZE } from '../../../reducer';
import { t } from '@lingui/macro';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  contentLibraryUploadAssetsSelectors,
  entitiesSelectors,
} from '@integration-frontends/integration/core/application/selectors';
import PQueue from 'p-queue';
import { handleUploadProgressBanner } from './handle-upload-progress-banner';
import { removeFileExtension } from '@brandfolder/utilities';
import { AttachmentSources } from '../sources';

const assetQueue = new PQueue({ concurrency: 2 });

export function* uploadAsset(assetUploadId: string) {
  const assetUpload: AssetUpload = yield select(
    contentLibraryUploadAssetsSelectors.assetUploadById(assetUploadId),
  );
  const collection = yield select(
    entitiesSelectors.collection.byCollectionId(assetUpload.collectionId),
  );
  const file = assetUpload.fileUpload.file;
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const filename = removeFileExtension(file.name);

  if (file.size === 0) {
    yield* handleUploadFailure({ assetUploadId, error: t`File is empty`, valid: false });
    return;
  }
  if (file.size > MAX_FILE_SIZE) {
    yield* handleUploadFailure({
      assetUploadId,
      error: t`File too large. (1GB max)`,
      valid: false,
    });
    return;
  }
  try {
    const response = yield assetQueue.add(() =>
      assetRepo.create(collection, assetUpload.sectionId, filename, [file], AttachmentSources.SmartsheetContentLibrary),
    );

    const assetId = response?.data[0]?.id;
    yield* handleUploadSuccess({ assetUploadId, assetId });
    yield put(addFilesProcessing({ assetIds: [assetId] }));
  } catch (error) {
    new Error(error);
    yield* handleUploadFailure({ assetUploadId });
  }
  yield* handleUploadProgressBanner();
}

function* handleUploadSuccess({ assetUploadId }: { assetUploadId: string; assetId: string }) {
  yield put(
    uploadAssetSuccess({
      assetUploadId,
    }),
  );
}

function* handleUploadFailure({
  assetUploadId,
  error,
  valid = true,
}: {
  assetUploadId: string;
  error?: string;
  valid?: boolean;
}) {
  const errorMessage = error || t`An error occurred.`;

  yield put(
    uploadAssetFailure({
      assetUploadId,
      error: errorMessage,
      valid,
    }),
  );
}
