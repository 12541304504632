import { takeEvery } from 'redux-saga/effects';
import { selectAllAttachments, selectAllAttachmentsInit } from '../actions';
import { entitiesSelectors } from '../../../selectors';
import { all, put, select } from 'typed-redux-saga';
import {
  IMediaTypeSupportService,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';

const handler = function* () {
  const attachmentEntities = yield select(entitiesSelectors.attachment.selectEntities);
  const mediaTypeSupportService: IMediaTypeSupportService = DI_CONTAINER.get(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const supportedAttachments = yield all(
    Object.keys(attachmentEntities).reduce((supported, key) => {
      if (mediaTypeSupportService.isSupported(attachmentEntities[key].mediaType)) {
        supported.push(attachmentEntities[key]);
      }

      return supported;
    }, []),
  );

  yield put(selectAllAttachments({ attachments: supportedAttachments }));
};

export function* selectAllAttachmentsInitEffects() {
  yield takeEvery(selectAllAttachmentsInit, handler);
}