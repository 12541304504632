export enum FileType {
  Ai = 'AI',
  MSExcel = 'MS_EXCEL',
  MSPowerpoint = 'MS_POWERPOINT',
  MSWord = 'MS_WORD',
  Pdf = 'PDF',
  Zip = 'ZIP',
}

export const ALL_FILE_TYPES = Object.values(FileType);
export const FILE_TYPE_EXTENSIONS = {
  [FileType.Ai]: ['ai'],
  [FileType.MSExcel]: ['xls', 'xlsx'],
  [FileType.MSPowerpoint]: ['ppt', 'pptx'],
  [FileType.MSWord]: ['doc', 'docx'],
  [FileType.Pdf]: ['pdf'],
  [FileType.Zip]: ['zip'],
};

export const FILE_TYPE_MIME_TYPES = {
  [FileType.Ai]: ['application/vnd.adobe.illustrator'],
  [FileType.MSExcel]: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  [FileType.MSPowerpoint]: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  [FileType.MSWord]: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  [FileType.Pdf]: ['application/pdf'],
  [FileType.Zip]: ['application/zip'],
};

export function getFileTypeFromMimeType(mimeType: string): FileType {
  let record: [string, string[]];
  if (mimeType !== null) {
    record = Object.entries(FILE_TYPE_MIME_TYPES).find(([, mimeTypes]) =>
      mimeTypes.includes(mimeType.toLocaleLowerCase()),
    );
  }

  return (record?.[0] as FileType) || null;
}
