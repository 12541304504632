import { mergeRight } from 'ramda';
import { PartialDeep } from 'type-fest';

export interface LabelOptions {
  label: string;
}

export function createLabelOptions(overrides: PartialDeep<LabelOptions> = {}): LabelOptions {
  const defaults: LabelOptions = { label: '' };
  return mergeRight(defaults, overrides);
}

export function setLabel(label: string, options: LabelOptions): LabelOptions {
  return mergeRight(options, { label });
}
