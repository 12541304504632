import { AuthError, Identity } from '@integration-frontends/common/auth/core/model';
import { createReducer } from '@reduxjs/toolkit';
import {
  clear,
  loginError,
  loginSuccess,
  logout,
  setIdentity,
  setPermissionLevel,
  setRefreshLock,
  setRefreshToken,
} from './actions';

export interface AuthState {
  identity: Identity | null;
  error: AuthError;
  refreshToken: string | null;
  refreshLock?: boolean;
  refreshLockTimestamp?: number;
  permissionLevel: string | null;
}

const initialState: AuthState = {
  identity: null,
  error: null,
  refreshToken: null,
  refreshLock: false,
  refreshLockTimestamp: null,
  permissionLevel: null,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(loginSuccess, (state, action) => {
      state.identity = action.payload.identity;
    })
    .addCase(loginError, (state, action) => {
      state.error = action.payload.error;
    })
    .addCase(logout, (state) => {
      state.identity = null;
      state.refreshToken = null;
    })
    .addCase(setRefreshToken, (state, action) => {
      state.refreshToken = action.payload.refreshToken;
    })
    .addCase(setRefreshLock, (state, action) => {
      state.refreshLock = action.payload.refreshLock;
      state.refreshLockTimestamp = action.payload.refreshLockTimestamp;
    })
    .addCase(setIdentity, (state, action) => {
      state.identity = action.payload.identity;
    })
    .addCase(clear, (state) => {
      state.error = null;
      state.identity = null;
      state.refreshToken = null;
      state.refreshLock = false;
    })
    .addCase(setPermissionLevel, (state, action) => {
      state.permissionLevel = action.payload.permissionLevel;
    });
});
