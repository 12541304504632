import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React from 'react';
import { PageSection, PageSectionContent } from '../../../../common/layout/page';
import './asset-details-component.scss';
import { t } from '@lingui/macro';

export const ASSET_DETAILS_COMPONENT_KEY = 'ASSET_DETAILS';

export interface AssetDetailsComponentProps {
  asset: Asset;
  attachment: Attachment;
}

export function AssetDetailsComponent({ asset }: AssetDetailsComponentProps) {
  return (
    <PageSection className="asset-details" title={t`Details`}>
      <PageSectionContent>
        <div>
          {t`Last updated on`} {format(new Date(asset?.updatedAt), DEFAULT_DATE_FORMAT)}
        </div>
        <div>
          {t`Created on`} {format(new Date(asset?.createdAt), DEFAULT_DATE_FORMAT)}
        </div>
        {asset?.availabilityEnd && (
          <div>
            {t`Expires on`} {format(new Date(asset?.availabilityEnd), DEFAULT_DATE_FORMAT)}
          </div>
        )}
      </PageSectionContent>
    </PageSection>
  );
}
