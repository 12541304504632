import { createSelector } from 'reselect';
import {
  BannersRootState,
  BannersState,
  NotificationsRootState,
  NotificationsState,
  ToastRootState,
  ToastsState,
} from './reducer';
import { StateSelector } from '@integration-frontends/common/utils/redux';

const selectNotificationsState: StateSelector<NotificationsState> = (
  state: NotificationsRootState,
) => state.notifications;
export const selectNewNotifcations = createSelector(selectNotificationsState, (state) => {
  return state.notifications.filter((notification) => {
    return notification.closed === false;
  });
});
export const selectAllNotificaitons = createSelector(
  selectNotificationsState,
  (state) => state.notifications,
);

const selectBannersState: StateSelector<BannersState> = (state: BannersRootState) => state.banners;
export const selectOpenBanners = createSelector(selectBannersState, (state) => {
  return state.banners.filter((banner) => {
    return banner.closed === false;
  });
});
export const selectAllBanners = createSelector(selectBannersState, (state) => state.banners);

const selectToastState: StateSelector<ToastsState> = (state: ToastRootState) => state.toasts;
export const selectOpenToasts = createSelector(selectToastState, (state) => {
  return state.toasts.filter((toast) => {
    return toast.toastState.closed === false;
  });
});
