import { Attachment, ResourceType } from '@integration-frontends/integration/core/model';
import noop from 'lodash/noop';
import * as React from 'react';
import { Cropper } from './cropper';

export default {
  title: 'Cropper',
  component: Cropper
};

export const Freeform = () => (
  <Cropper
    attachment={
      {
        type: ResourceType.ATTACHMENT,
        url: 'https://cdn.bfldr.com/YUHW9ZNT/at/pfbdqq-edkps0-4gypa7/bf_logo_giant_png'
      } as Attachment
    }
    onCrop={noop}
  />
);
