import { call, put, takeEvery } from 'redux-saga/effects';
import { createNotification } from '../actions';
import { ToastOptions, toast } from 'react-toastify';
import { NotificationType } from '../reducer';

const handler = function* (action: ReturnType<typeof createNotification>) {
  const { message, location, type, style } = action.payload;
  const options = {
    containerId: location,
    hideProgressBar: true,
    style,
  } as ToastOptions;
  switch (type) {
    case NotificationType.Success:
      yield toast.success(message, { className: 'toast-success', ...options });
      break;
    case NotificationType.Error:
      yield toast.error(message, { className: 'toast-error', ...options });
      break;
    case NotificationType.Info:
      yield toast.info(message, { className: 'toast-info', ...options });
      break;
    default:
      yield toast.success(message, { className: 'toast-success', ...options });
  }
};

export function* newNotificationEffects() {
  yield takeEvery(createNotification, handler);
}
