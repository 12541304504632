import { BFButton, BFErrorLabel, BFHR, BFLabel } from '@integration-frontends/common/ui';
import {
  AlignmentOptions,
  attachmentEdited,
  AttachmentEditOptions,
  attachmentEntitySelectors,
  centerCropper,
  createAlignmentOptions,
  createLabelOptions,
  createLinkOptions,
  createResizingOptions,
  CropOptions,
  editAttachmentCanceled,
  editAttachmentInit,
  getOutputDimensions,
  getRelativeCropDimensions,
  hasFixedOutputDimensions,
  IntegrationRootState,
  LabelOptions,
  LinkOptions,
  ResizingOptions,
  setDimensions,
} from '@integration-frontends/integration/core/application';
import {
  CDN_SERVICE_TOKEN,
  DimensionType,
  ICdnService,
  isImage,
} from '@integration-frontends/integration/core/model';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cropper, CropperOptions } from '../common/cropper';
import { SectionTitle } from '../common/layout';
import { Align } from './align';
import { Label } from './label';
import { Link } from './link';
import { Resizer } from './resizer';
import { useAttachmentTransforms } from '../attachment-selector/common/attachment-transforms/use-attachment-transforms';
import { DI_CONTAINER } from '@integration-frontends/core';
import { Trans } from '@lingui/macro';

export interface AttachmentEditorProps {
  attachmentId: string;
  options?: AttachmentEditOptions;
}

export function AttachmentEditor({ attachmentId, options = {} }: AttachmentEditorProps) {
  const dispatch = useDispatch();
  const [alignmentOptions, setAlignmentOptions] = useState<AlignmentOptions>(
    options.alignmentOptions || createAlignmentOptions(),
  );
  const [labelOptions, setLabelOptions] = useState<LabelOptions>(
    options.labelOptions || createLabelOptions(),
  );
  const [linkOptions, setLinkOptions] = useState<LinkOptions>(
    options.linkOptions || createLinkOptions(),
  );
  const attachment = useSelector((state: IntegrationRootState) =>
    attachmentEntitySelectors.selectById(state, attachmentId),
  );
  const { userResizingOptions, resized, cropOptions, setCropOptions } = useAttachmentTransforms({
    attachment,
  });
  const [initialized, setInitialized] = useState(false);
  const cropperRef = useRef<any>(null);
  const cdnService: ICdnService = DI_CONTAINER.get(CDN_SERVICE_TOKEN);

  useEffect(() => {
    if (attachment && cropperRef.current && !initialized) {
      setInitialized(true);
      resized(
        createResizingOptions({
          initialDimensions: attachment.dimensions,
          newDimensions: attachment.dimensions,
        }),
      );

      cropperRef.current.setCropOptions(cropOptions);
    }
  }, [attachment, !!cropperRef.current]);

  useEffect(() => {
    dispatch(editAttachmentInit({ attachmentId }));
  }, [attachmentId]);

  function resizeImage({ newDimensions: { width, height } }: ResizingOptions) {
    resized(
      setDimensions(userResizingOptions, {
        width: width / cropOptions.newDimensions.width,
        height: height / cropOptions.newDimensions.height,
        type: DimensionType.Absolute,
      }),
    );
  }

  function resolveResizingOptions(): ResizingOptions {
    const outputDimensions = getOutputDimensions(
      userResizingOptions?.newDimensions || attachment.dimensions,
      cropOptions,
    );

    return createResizingOptions(setDimensions(userResizingOptions, outputDimensions));
  }

  function cropImage(options: CropOptions) {
    options && setCropOptions(options);
  }

  function cropperOptionsChanged(options: CropOptions) {
    options = hasFixedOutputDimensions(options)
      ? centerCropper(
          setDimensions(
            options,
            getRelativeCropDimensions(
              attachment.dimensions,
              getOutputDimensions(attachment.dimensions, options),
            ),
          ),
        )
      : options;

    setCropOptions(options);
    cropperRef.current.setCropOptions(options);
  }

  function previewImage() {
    dispatch(
      attachmentEdited({
        attachment,
        options: {
          alignmentOptions: alignmentOptions,
          cropOptions: cropOptions,
          labelOptions: labelOptions,
          resizingOptions: resolveResizingOptions(),
          linkOptions: linkOptions,
        },
      }),
    );
  }

  if (!attachment) return null;
  return (
    <div data-cy="attachment-editor" data-testid="attachment-editor" className="pt-lg">
      <div className="mb-lg gap-md flex flex-col">
        <div className="flex items-center">
          <BFLabel>
            <Trans>Alignment</Trans>:{' '}
          </BFLabel>
          <Align options={alignmentOptions} onChange={setAlignmentOptions} />
        </div>

        <Label options={labelOptions} onChange={setLabelOptions} />
        <Link options={linkOptions} onChange={setLinkOptions} />
        <Resizer options={resolveResizingOptions()} onChange={resizeImage} />
      </div>

      <BFHR className="mb-md" />

      <div className="gap-md flex flex-col">
        {isImage(attachment.mimetype) && (
          <>
            <SectionTitle>
              <Trans>Crop</Trans>
            </SectionTitle>

            {cdnService.supportsCdnTransform(attachment.url) ? (
              <div className="gap-md flex w-full flex-col">
                <Cropper
                  key={attachment.id}
                  attachment={attachment}
                  onCrop={cropImage}
                  ref={cropperRef}
                />

                <div className="flex-none">
                  <CropperOptions options={cropOptions} onChange={cropperOptionsChanged} />
                </div>
              </div>
            ) : (
              <BFErrorLabel>
                <Trans>Cropping is not supported for this image.</Trans>
              </BFErrorLabel>
            )}
          </>
        )}

        <div className="gap-xs flex w-full justify-end">
          <BFButton
            data-cy="cancel-action"
            onClick={() => dispatch(editAttachmentCanceled({ attachment }))}
          >
            <Trans>Cancel</Trans>
          </BFButton>
          <BFButton data-cy="import-action" onClick={previewImage}>
            <Trans>Preview</Trans>
          </BFButton>
        </div>
      </div>
    </div>
  );
}
