import { put, select, takeEvery } from 'typed-redux-saga';
import { retryAssetUpload } from '../../actions';
import { uploadAsset } from './common/upload-asset';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { contentLibraryUploadAssetsSelectors } from '@integration-frontends/integration/core/application/selectors';
import { handleUploadComplete } from './common/handle-upload-complete';
import { plural } from '@lingui/macro';
import { updateBanner } from '@integration-frontends/common/notifications';
import { handleUploadProgressBanner } from './common';

export function* retryAssetUploadEffects() {
  yield takeEvery(retryAssetUpload, handler);
}

const handler = function* (action: ReturnType<typeof retryAssetUpload>) {
  const { assetUploadId } = action.payload;
  yield* handleUploadProgressBanner();
  yield* uploadAsset(assetUploadId);

  const isUploadOngoing = yield select(contentLibraryUploadAssetsSelectors.isUploadOngoing);
  const uploadSequenceId = yield select(contentLibraryUploadAssetsSelectors.uploadSequenceId);
  const unresolvedAssetUploads = yield select(
    contentLibraryUploadAssetsSelectors.unresolvedAssetUploads,
  );
  const unresolvedAssetUploadsCount = unresolvedAssetUploads.length;
  const message = plural(unresolvedAssetUploadsCount, {
    one: '# unresolved upload',
    other: '# unresolved uploads',
  });
  yield put(
    updateBanner({
      id: uploadSequenceId,
      bannerContent: {
        contentText: message,
      },
    }),
  );
  if (!isUploadOngoing) {
    yield* handleUploadComplete();
  }
};
