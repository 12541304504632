import {
  getResponseListDataOrDefault,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import {
  Container,
  ISectionRepo,
  ResourceType,
  Section,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { mapSection } from './model';
import { RepoBase } from './repo-base';

@injectable()
export class SectionRepo extends RepoBase implements ISectionRepo {
  listBrandfolderSections = async (brandfolderId: string): Promise<Section[]> => {
    return this.brandfolderApi
      .listBrandfolderSections(await this.getApiKey(), brandfolderId, {
        include: [Relationship.BRANDFOLDER],
      })
      .then((res) => {
        return getResponseListDataOrDefault(res).map(mapSection);
      });
  };

  listCollectionSections = async (collectionId: string): Promise<Section[]> => {
    return this.brandfolderApi
      .listCollectionSections(await this.getApiKey(), collectionId, {
        include: [Relationship.BRANDFOLDER],
      })
      .then((res) => {
        return getResponseListDataOrDefault(res).map(mapSection);
      });
  };

  listCollectionSectionsPrivate = async (collectionId: string): Promise<Section[]> => {
    return this.brandfolderApi
      .listCollectionSectionsPrivate(await this.getApiKey(), collectionId)
      .then((res) => {
        return getResponseListDataOrDefault(res).map(mapSection);
      });
  };

  listContainerSections = async (container: Container): Promise<Section[]> => {
    if (!container) return [];

    return container.type === ResourceType.BRANDFOLDER
      ? this.listBrandfolderSections(container.id)
      : this.listCollectionSections(container.id);
  };
}
