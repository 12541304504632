import { TextInput, TextInputProps } from '@smartsheet/lodestar-core';
import { SearchIcon } from '@smartsheet/lodestar-icons';
import React, { ChangeEvent, FC, forwardRef } from 'react';
import { BFStandardTextfieldProps } from './bf-standard-textfield';

export const BFStandardTextfieldSmartsheet: FC<BFStandardTextfieldProps> = forwardRef(
  ({ onChange, type, value, ...restProps }, ref) => {
    const smarOnChange: TextInputProps['onChange'] = (_, event) => {
      onChange(event as ChangeEvent<HTMLInputElement>);
    };

    return (
      <TextInput
        contentBefore={type === 'search' ? <SearchIcon /> : null}
        onChange={smarOnChange}
        type={type === 'search' ? 'text' : type}
        value={value?.toString()}
        ref={ref}
        variant="underline"
        {...restProps}
      />
    );
  },
);
