import { DI_CONTAINER } from '@integration-frontends/core';
import { assetEntityActions, attachmentEntityActions } from '../../../common';
import {
  decrementCurrentPage,
  incrementCurrentPage,
  AssetsListPagination,
} from '../../../common/assets-list/assets-list-state/assets-list-pagination';
import {
  ASSET_REPO_TOKEN,
  Container,
  IAssetRepo,
  ListOptions,
  SearchParameters,
  SortDirection,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { searchAssetsActions } from '../actions';
import { SearchAssetsSelectors } from '../selectors';

function* previousPageHandler(
  action: ReturnType<
    typeof searchAssetsActions.previousPage | typeof searchAssetsActions.previousPageBase
  >,
  selectors: SearchAssetsSelectors,
) {
  const { container } = action.payload;
  const searchParams = yield select(selectors.searchParams);
  const paginationState = yield select(selectors.listSelectors.pagination);
  const sortOptions = yield select(selectors.sortOptions);
  yield action instanceof searchAssetsActions.previousPage
    ? handler(container, decrementCurrentPage(paginationState), searchParams, sortOptions)
    : baseHandler(container, decrementCurrentPage(paginationState), searchParams, sortOptions);
}

function* nextPageHandler(
  action: ReturnType<typeof searchAssetsActions.nextPage | typeof searchAssetsActions.nextPageBase>,
  selectors: SearchAssetsSelectors,
) {
  const { container } = action.payload;
  const searchParams = yield select(selectors.searchParams);
  const paginationState = yield select(selectors.listSelectors.pagination);
  const sortOptions = yield select(selectors.sortOptions);
  yield action instanceof searchAssetsActions.nextPage ||
  action instanceof searchAssetsActions.nextPageBase
    ? handler(container, incrementCurrentPage(paginationState), searchParams, sortOptions)
    : baseHandler(container, incrementCurrentPage(paginationState), searchParams, sortOptions);
}

function* baseHandler(
  container: Container,
  paginationState: AssetsListPagination,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
) {
  const pagedResults = yield call(
    fetchContainerAssetsBase,
    container,
    paginationState,
    searchParams,
    sortOptions,
  );
  yield put(searchAssetsActions.pageLoaded({ results: pagedResults }));
  yield put(assetEntityActions.assetsReceived(pagedResults.data.assets));
}

function* handler(
  container: Container,
  paginationState: AssetsListPagination,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
) {
  const pagedResults = yield call(
    fetchContainerAssets,
    container,
    paginationState,
    searchParams,
    sortOptions,
  );
  yield put(searchAssetsActions.pageLoaded({ results: pagedResults }));
  yield put(assetEntityActions.assetsReceived(pagedResults.data.assets));
  yield put(attachmentEntityActions.attachmentsReceived(pagedResults.data.attachments));
}

function fetchContainerAssets(
  container: Container,
  pagination: AssetsListPagination,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const options: ListOptions = {
    searchParams,
    pagination: { page: pagination.currentPage, perPage: pagination.pageSize },
    sort: sortOptions,
  };
  return assetRepo.listContainerAssets(container, options);
}

function fetchContainerAssetsBase(
  container: Container,
  pagination: AssetsListPagination,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
) {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const options: ListOptions = (() => {
    switch (pagination?.paginationType) {
      case 'cursor':
        if (sortOptions.direction === SortDirection.Desc) {
          return {
            searchParams,
            sort: sortOptions,
            before: pagination?.nextCursor,
          };
        } else {
          return {
            searchParams,
            sort: sortOptions,
            after: pagination?.nextCursor,
          };
        }
      case 'countless':
        return {
          searchParams,
          pagination: { page: pagination.nextPage },
          sort: sortOptions,
        };
      default:
        return {
          searchParams,
          pagination: { page: pagination.currentPage, perPage: pagination.pageSize },
          sort: sortOptions,
        };
    }
  })();
  return assetRepo.listContainerAssetsBase(container, options);
}

export function* paginationContainerEffects(searchAssetsSelectors: SearchAssetsSelectors) {
  yield all([
    takeEvery(searchAssetsActions.nextPage, function* (action) {
      yield nextPageHandler(action, searchAssetsSelectors);
    }),
    takeEvery(searchAssetsActions.nextPageBase, function* (action) {
      yield nextPageHandler(action, searchAssetsSelectors);
    }),
    takeEvery(searchAssetsActions.previousPage, function* (action) {
      yield previousPageHandler(action, searchAssetsSelectors);
    }),
    takeEvery(searchAssetsActions.previousPageBase, function* (action) {
      yield previousPageHandler(action, searchAssetsSelectors);
    }),
  ]);
}
