import React, { ButtonHTMLAttributes, HTMLAttributes, SelectHTMLAttributes } from 'react';
import { BFPopover, IconClose, IconCheckMark } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import './popover-menu.scss';

import './select.scss';

export const MULTI_SELECT_COMPONENT_KEY_ = 'MULTI_SELECT';

export enum MultiSelectSize {
  Small = 's',
  Medium = 'm',
}

export type MultiSelectOption = {
  value: string | number;
  label?: string;
};

export type MultiSelectProps = {
  options: MultiSelectOption[];
  selected: MultiSelectOption[];
  value: string[];
  onClear: () => void;
  onChange: any;
  isClearable?: boolean;
  placeholder?: string;
  size?: MultiSelectSize;
  selectedOptions?: MultiSelectOption[];
  getOptionKey?: (opton: MultiSelectOption) => string | number;
  isDisabled?: boolean;
  zIndex?: number;
  panelStyleOptions?: HTMLAttributes<HTMLElement>['style'];
  buttonStyleOptions?: HTMLAttributes<HTMLButtonElement>['style'];
} & Omit<HTMLAttributes<HTMLElement>, 'onChange'> &
  Omit<SelectHTMLAttributes<HTMLElement>, 'onChange' | 'size'>;

export function MultiSelect({
  options,
  selected,
  value,
  onChange,
  onClear,
  zIndex,
  panelStyleOptions,
  buttonStyleOptions,
  isDisabled,
  placeholder = 'Select',
  ...restProps
}: MultiSelectProps) {
  return isDisabled ? (
    <MultiSelectButtonDisabled placeholder={placeholder} />
  ) : (
    <BFPopover
      button={
        <MultiSelectButton
          selected={selected}
          selectedValues={value}
          onClear={onClear}
          onClick={onChange}
          placeholder={placeholder}
        />
      }
      panel={
        <MultiSelectDropdown
          options={options}
          onClick={onChange}
          selectedValues={value}
          selected={selected}
        />
      }
      zIndex={zIndex || 1}
      panelClass="multi-select"
      buttonStyleOptions={buttonStyleOptions}
      panelStyleOptions={panelStyleOptions}
    />
  );
}

export interface MultiSelectButtonProps {
  selected?: MultiSelectOption[];
  selectedValues?: string[];
  onClick?: () => void;
  onClear?: () => void;
  placeholder?: string;
}

export function MultiSelectButtonDisabled({ placeholder }: MultiSelectButtonProps) {
  return (
    <div className="multi-select-button disabled" tabIndex={0}>
      <p className="multi-select-placeholder">{placeholder}</p>
    </div>
  );
}

export function MultiSelectButton({
  selected,
  selectedValues,
  onClick,
  onClear,
  placeholder,
}: MultiSelectButtonProps) {
  const clonedValues = selectedValues.slice();
  return (
    <div
      className={classNames('multi-select-button')}
      data-testid="multi-select-button"
      tabIndex={0}
    >
      {selected?.length ? (
        selected?.map((option) => (
          <MultiSelectSelectedItem
            onClick={(value) => selectOption(clonedValues, value, onClick)}
            value={`${option.value}`}
            label={option.label}
            key={`selected-${option.value}`}
          />
        ))
      ) : (
        <p className="multi-select-placeholder">{placeholder}</p>
      )}
    </div>
  );
}

const selectOption = (valuesArray, value, onClick) => {
  const idx = valuesArray.indexOf(value);
  if (idx >= 0) {
    valuesArray.splice(idx, 1);
  } else {
    valuesArray.push(value);
  }
  onClick(valuesArray);
};

export interface MultiSelectDropdownProps {
  options: MultiSelectOption[];
  selectedValues: string[];
  selected: MultiSelectOption[];
  onClick: (value: any) => void;
}

export function MultiSelectDropdown({
  options,
  selectedValues,
  onClick,
}: MultiSelectDropdownProps) {
  const clonedValues = selectedValues.slice();

  return (
    <section className="multi-select-dropdown" data-testid="multi-select-dropdown">
      {options.map((option) => (
        <MultiSelectItem
          onClick={(value) => selectOption(clonedValues, value, onClick)}
          checked={selectedValues.includes(`${option.value}`)}
          value={`${option.value}`}
          label={option.label}
          key={option.value}
        />
      ))}
    </section>
  );
}

interface MultiSelectItemProps extends ButtonHTMLAttributes<any> {
  checked?: boolean;
  value: string;
  label: string;
  onClick: (value: any) => void;
}

export function MultiSelectItem({ checked, value, label, onClick }: MultiSelectItemProps) {
  return (
    <button
      data-testid="multi-select-dropdown-option"
      onClick={() => onClick(value)}
      className={
        'popover-menu-item multi-select-item hover:bg-bf-marshmallow-light w-max whitespace-nowrap'
      }
      tabIndex={900}
    >
      {label}
      {checked && <IconCheckMark />}
    </button>
  );
}

export function MultiSelectSelectedItem({ value, label, onClick }: MultiSelectItemProps) {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick(value);
      }}
      data-testid="multi-select-selected-item"
      className={'popover-menu-item multi-select-selected-item w-max whitespace-nowrap'}
    >
      {label}
      <IconClose data-testid="multi-select-remove-selected-item" />
    </button>
  );
}
