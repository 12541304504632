import {
  IntegrationEntitiesSelectors,
  SearchAssetsSelectors,
} from '@integration-frontends/integration/core/application';
import { all } from 'redux-saga/effects';
import { clearSearchEffects } from './clear-search';
import { initEffects } from './init';
import { paginationSectionEffects } from './pagination-by-section';
import { paginationContainerEffects } from './pagination-by-container';
import { searchEffects } from './search';
import { call } from 'typed-redux-saga';
import { filtersEffects } from './filters';
import { contentLibraryInitSearchEffects } from './content-library-init-search';
import { baseSearchEffects } from './base-search';
import { baseClearSearchEffects } from './base-clear-search';

export function* searchAssetsEffects(
  searchAssetsSelectors: SearchAssetsSelectors,
  entitiesSelectors: IntegrationEntitiesSelectors,
) {
  yield all([
    call(clearSearchEffects),
    call(baseClearSearchEffects),
    call(initEffects),
    call(paginationContainerEffects, searchAssetsSelectors),
    call(paginationSectionEffects, searchAssetsSelectors),
    call(searchEffects, searchAssetsSelectors, entitiesSelectors),
    call(baseSearchEffects, searchAssetsSelectors, entitiesSelectors),
    call(filtersEffects, searchAssetsSelectors),
    call(contentLibraryInitSearchEffects),
  ]);
}
