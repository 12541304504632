import { Container, interfaces } from 'inversify';

let _container: interfaces.Container = new Container();

export const DI_CONTAINER = {
  container: _container,
  merge: (container: interfaces.Container) => {
    _container = Container.merge(_container, container);
  },
  get: <T = any>(token: string | symbol): T => {
    return _container.get(token);
  },
  reset: () => (_container = new Container()),
};
