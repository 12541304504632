import {
  BannerActionProps,
  BannerActionSeparatorProps,
  BannerActionsProps,
  BannerContentProps,
  BannerDismissButtonProps,
  BannerLinkProps,
  BannerProps,
} from '@smartsheet/lodestar-core';
import React, { FC } from 'react';

export const BF_STANDARD_BANNER_COMPONENT = 'BF_STANDARD_BANNER_COMPONENT';

export type BFStandardBannerProps = {
  bannerProps: BannerComponentProps;
  contentProps?: BannerComponentContentProps;
  linkProps?: BannerComponentLinkProps;
  actionsProps?: BannerComponentActionsProps;
  actionProps?: BannerComponentActionProps;
  actionSeperatorProps?: BannerComponentActionSeperatorProps;
  dismissButtonProps?: BannerComponentDismissButtonProps;
  displayDismissButton?: boolean;
};

export type BFStandardBannerComponent = FC<BFStandardBannerProps>;

export type BannerComponentProps = BannerProps & React.HTMLAttributes<HTMLDivElement>;

export type BannerComponentContentProps = BannerContentProps &
  React.HTMLAttributes<HTMLDivElement> & {
    contentText: string;
  };

export type BannerComponentLinkProps = BannerLinkProps &
  React.HTMLAttributes<HTMLAnchorElement> & {
    linkText: string;
    linkHref: string;
  };

export type BannerComponentActionsProps = BannerActionsProps & React.HTMLAttributes<HTMLDivElement>;

export type BannerComponentActionProps = BannerActionProps &
  React.HTMLAttributes<HTMLButtonElement> & {
    actionText: string;
  };

export type BannerComponentActionSeperatorProps = BannerActionSeparatorProps &
  React.HTMLAttributes<HTMLDivElement>;

export type BannerComponentDismissButtonProps = BannerDismissButtonProps &
  React.HTMLAttributes<HTMLButtonElement>;
