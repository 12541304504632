import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
  assetDetailsDataLoaded,
  assetDetailsPageEntered,
  attachmentDataLoaded,
  attachmentDeselected,
  attachmentSelected,
  deselectAllAttachments,
  orgLandingPageDataLoaded,
  orgLandingPageEntered,
  selectAllAttachments,
  selectAllAttachmentsError,
  selectAllAttachmentsInit,
  selectAttachmentInit,
  selectAttachmentInitSuccess,
  showPageDataLoaded,
  showPageEntered,
  showPageScrollStopped,
  singleSelectAttachmentEnded,
} from '../actions';
import { SelectionType } from '../../select-attachment-options';
import { prop } from 'ramda';
import { Attachment, AttachmentVariant } from '@integration-frontends/integration/core/model';

export interface SelectAttachmentState {
  selectedAttachments: (Attachment | AttachmentVariant)[];
  selectionType: SelectionType.Multi | SelectionType.Single;
  initLoading: boolean;
  initSuccess: boolean;
  pageLoading: boolean;
  showPageScrollPosition: number;
  selectAllLoading: boolean;
}

export const selectAttachmentInitialState: SelectAttachmentState = {
  initLoading: false,
  selectedAttachments: [],
  selectionType: null,
  initSuccess: false,
  pageLoading: false,
  showPageScrollPosition: 0,
  selectAllLoading: false,
};

function isPageEnteredAction(action: AnyAction) {
  return [assetDetailsPageEntered, orgLandingPageEntered, showPageEntered]
    .map(prop('type'))
    .includes(action.type);
}

function isDataLoadedAction(action: AnyAction) {
  return [
    assetDetailsDataLoaded,
    attachmentDataLoaded,
    orgLandingPageDataLoaded,
    showPageDataLoaded,
  ]
    .map(prop('type'))
    .includes(action.type);
}

export const SELECT_ATTACHMENT_REDUCER = createReducer(selectAttachmentInitialState, (builder) => {
  builder
    .addCase(selectAttachmentInit, (state) => {
      state.initLoading = true;
    })
    .addCase(selectAttachmentInitSuccess, (state, action) => {
      state.initLoading = false;
      state.initSuccess = true;
      state.selectionType = action.payload.selectAttachmentOptions.selectionType;
    })
    .addCase(attachmentSelected, (state, action) => {
      state.selectedAttachments.push(action.payload.attachment);
    })
    .addCase(attachmentDeselected, (state, action) => {
      state.selectedAttachments = state.selectedAttachments.filter((attachment) => {
        return attachment.id !== action.payload.attachment.id;
      });
    })
    .addCase(selectAllAttachments, (state, action) => {
      state.selectedAttachments = action.payload.attachments;
    })
    .addCase(deselectAllAttachments, (state) => {
      state.selectedAttachments = [];
    })
    .addCase(singleSelectAttachmentEnded, (state, action) => {
      state.selectedAttachments = [action.payload.attachments[0]];
    })
    .addCase(showPageScrollStopped, (state, action) => {
      state.showPageScrollPosition = action.payload.scrollPosition;
    })
    .addCase(selectAllAttachmentsInit, (state) => {
      state.selectAllLoading = true;
    })
    .addCase(selectAllAttachmentsError, (state) => {
      state.selectAllLoading = false;
    })
    .addMatcher(isPageEnteredAction, (state) => {
      state.pageLoading = true;
    })
    .addMatcher(isDataLoadedAction, (state) => {
      state.pageLoading = false;
    });
});
