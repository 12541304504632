import { call, delay, put } from 'typed-redux-saga';
import { removeFileProcessing } from '../../../actions';
import { assetEntityActions, attachmentEntityActions } from '../../../../../common';
import { ATTACHMENT_REPO_TOKEN, IAttachmentRepo } from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';

const MAX_RETRIES = 6;

export function* handleProcessFile(assetId: string) {
  yield call(handleFileProcessing, assetId);
}

function* handleFileProcessing(assetId: string | undefined) {
  let retryCount = 0;

  const POLLING_INTERVALS = [2000, 4000, 8000, 16000, 32000, 64000];

  while (retryCount < MAX_RETRIES) {
    const attachment = yield* call(fetchAssetAttachment, assetId);
    if (attachment?.isProcessing === false) {
      yield put(removeFileProcessing({ assetIds: [assetId] }));
      yield put(assetEntityActions.assetUpdated({ id: assetId, changes: { thumbnailUrl: attachment.thumbnailUrl } }));
      yield put(attachmentEntityActions.attachmentReceived(attachment));
      break;
    }

    const pollingInterval = POLLING_INTERVALS[retryCount];

    yield delay(pollingInterval);
    retryCount++;
  }
}

function fetchAssetAttachment(assetId: string) {
  const attachmentRepo = DI_CONTAINER.get<IAttachmentRepo>(ATTACHMENT_REPO_TOKEN);
  return attachmentRepo.getAssetAttachment(assetId);
}
