import {
  AssetsListPagination,
  initAssetsListPagination,
} from '../assets-list-state/assets-list-pagination';
import { createAssetsListActions } from './actions';
import { createReducer } from '@reduxjs/toolkit';
import { prop } from 'ramda';

export interface AssetsListState {
  showAttachments: boolean;
  pagination: AssetsListPagination;
}

export const assetsListInitialState: AssetsListState = {
  showAttachments: false,
  pagination: initAssetsListPagination(),
};

export function createAssetsListReducer(actions: ReturnType<typeof createAssetsListActions>) {
  return createReducer(assetsListInitialState, (builder) =>
    builder
      .addCase(actions.listInit, (state) => {
        state.pagination = initAssetsListPagination();
      })
      .addCase(actions.nextPage, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.nextPageBase, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.previousPage, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.previousPageBase, (state) => {
        setLoadingIfNotAllAssetsLoaded(state);
      })
      .addCase(actions.pageLoaded, (state, action) => {
        const { results } = action.payload;
        state.pagination.pageSize = results.perPage;
        state.pagination.totalCount = results.totalCount;
        state.pagination.totalPages = results.totalPages;
        if (results?.paginationType === 'cursor') {
          state.pagination.pageAssetIds[state.pagination.currentPage] = results.data.assets.map(
            prop('id'),
          );
          // we meed to manually increment the current page as the backend does not return it for cursor pagination
          state.pagination.currentPage = state.pagination.currentPage + 1;
        } else if (results.currentPage) {
          state.pagination.currentPage = results.currentPage;
          state.pagination.pageAssetIds[results.currentPage] = results.data.assets.map(prop('id'));
        }
        state.pagination.nextPage = results.nextPage;
        state.pagination.loading = false;
        state.pagination.perPage = results.perPage;
        state.pagination.recordCount = results.recordCount;
        state.pagination.from = results.from;
        state.pagination.to = results.to;
        state.pagination.offset = results.offset;
        state.pagination.previousPage = results.previousPage;
        state.pagination.moreExist = results.moreExist;
        state.pagination.sortOrder = results.sortOrder;
        state.pagination.nextCursor = results.nextCursor;
        state.pagination.nextCursorParam = results.nextCursorParam;
        state.pagination.paginationType = results.paginationType;
      })
      .addCase(actions.toggleShowAttachment, (state) => {
        state.showAttachments = !state.showAttachments;
      }),
  );
}

const setLoadingIfNotAllAssetsLoaded = (state: AssetsListState) => {
  switch (state?.pagination?.paginationType) {
    case 'countless':
      if (state.pagination.nextPage) {
        state.pagination.loading = true;
      }
      break;
    case 'cursor':
      if (state.pagination.moreExist) {
        state.pagination.loading = true;
      }
      break;
    default:
      if (pageAssetIdsCount(state) < state.pagination.totalCount) {
        state.pagination.loading = true;
      }
  }
};

const pageAssetIdsCount = (state: AssetsListState) => {
  return state.pagination.pageAssetIds.reduce((sum, pageAssetIds) => sum + pageAssetIds.length, 0);
};
