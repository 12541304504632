import { i18n, Messages } from '@lingui/core';

/**
 * Same as `SupportedLocales` from
 * node_modules/@smartsheet/lodestar-core/dist/esm/i18n/constants.d.ts
 *
 * Cross-referenced with Translation.io
 * https://translation.io/docs/languages
 */
const SmartsheetLocales = {
  /** English */
  EN: 'en',
  /** Spanish */
  ES: 'es',
  /** German */
  DE: 'de',
  /** French */
  FR: 'fr',
  /** Italian */
  IT: 'it',
  /** Japanese */
  JA: 'ja',
  /** Portuguese */
  PT: 'pt',
  /** Russian */
  RU: 'ru'
};

export const Locales = {
  ...SmartsheetLocales
};

export type Locale = (typeof Locales)[keyof typeof Locales];

const loadAndActivate = (locale: Locale | string, messages: Messages): void => {
  i18n.load(locale, messages);
  i18n.activate(locale);
};

export const dynamicActivate: (locale?: Locale | string) => Promise<void> = async (
  locale = Locales.EN,
) => {
  const { messages } =
    process.env.NODE_ENV === 'test'
      ? require(`./locales/${locale}/messages.js`)
      : await import(`./locales/${locale}/messages.js`);
  loadAndActivate(locale, messages);
};
