import {
  DimensionType,
  RelativeDimensions,
  RelativeOffsets,
} from '@integration-frontends/integration/core/model';
import { mergeRight } from 'ramda';
import { PartialDeep } from 'type-fest';
import { Resizable } from '../common';
import { CropType } from './crop-type';

export interface FreeformCropOptions extends Resizable {
  type: CropType.Freeform;
  offsets: RelativeOffsets | null;
  newDimensions: RelativeDimensions | null;
}

export function createFreeformCropOptions(
  overrides: PartialDeep<FreeformCropOptions> = {},
): FreeformCropOptions {
  const defaults: FreeformCropOptions = {
    type: CropType.Freeform,
    initialDimensions: { width: 1, height: 1, type: DimensionType.Relative },
    newDimensions: { width: 1, height: 1, type: DimensionType.Relative },
    lockAspectRatio: false,
    offsets: null,
  };

  return mergeRight(defaults, overrides) as FreeformCropOptions;
}
