import { ButtonLooks, FontIcons } from '@brandfolder/react';
import { Button, ButtonAppearances } from '@smartsheet/lodestar-core';
import { FilterIcon } from '@smartsheet/lodestar-icons';
import React, { forwardRef, ReactElement } from 'react';
import { BFStandardIconButtonProps } from './bf-standard-icon-button';

export const BFStandardIconButtonSmartsheet = forwardRef<
  HTMLButtonElement,
  BFStandardIconButtonProps
>(({ disabled, icon, look, ...restProps }, ref) => {
  return (
    <Button
      ref={ref}
      appearance={mapLookToAppearance(look)}
      isDisabled={disabled}
      iconBefore={mapIcon(icon)}
      {...restProps}
    />
  );
});

function mapLookToAppearance(look: BFStandardIconButtonProps['look']): ButtonAppearances {
  switch (look) {
    case ButtonLooks.Primary:
      return 'primary';
    case ButtonLooks.Secondary:
      return 'secondary-outlined';
    case 'canvas-empty':
      return 'canvas-empty';
    default:
      return 'primary';
  }
}

function mapIcon(icon: BFStandardIconButtonProps['icon']): ReactElement {
  switch (icon) {
    case FontIcons.Filters:
      return (
        <div className="flex items-center justify-center" style={{ width: 24, height: 24 }}>
          <FilterIcon height={'16px'} width={'16px'} />
        </div>
      );
  }
}
