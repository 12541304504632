import {
  Attachment,
  ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  AttachmentVariant,
  DimensionType,
  IAttachmentTransformService,
  isKnownMediaType,
  KnownMediaType,
  MediaType,
} from '@integration-frontends/integration/core/model';
import { useCallback, useEffect, useState } from 'react';
import {
  createFreeformCropOptions,
  CropOptions,
  getCropDimensions,
  getCropOffsets,
  ResizingOptions,
} from '@integration-frontends/integration/core/application';
import { DI_CONTAINER } from '@integration-frontends/core';

export interface UseAttachmentTransformsProps {
  attachment: Attachment;
}

export function useAttachmentTransforms({ attachment }: UseAttachmentTransformsProps) {
  const [modifiedAttachment, setModifiedAttachment] = useState<AttachmentVariant>(null);
  const [cropOptions, setCropOptions] = useState<CropOptions>(createFreeformCropOptions());
  const [userResizingOptions, setUserResizingOptions] = useState<ResizingOptions>(null);
  const [selectedMediaType, setSelectedMediaType] = useState<KnownMediaType>(null);

  useEffect(() => {
    if (attachment) {
      initialize();
    }
  }, [attachment]);

  function initialize() {
    setUserResizingOptions(
      attachment
        ? {
            initialDimensions: attachment.dimensions,
            newDimensions: attachment.dimensions,
            lockAspectRatio: true,
          }
        : null,
    );

    setModifiedAttachment(null);
    setCropOptions(createFreeformCropOptions());
    setSelectedMediaType(isKnownMediaType(attachment?.mediaType) ? attachment?.mediaType : null);
  }

  const mediaTypeChanged = useCallback(
    async (type: KnownMediaType) => {
      setSelectedMediaType(type);
      await attachmentModified(userResizingOptions, cropOptions, type);
    },
    [attachment, cropOptions, userResizingOptions],
  );

  const resized = useCallback(
    async (resizingOptions: ResizingOptions) => {
      setUserResizingOptions(resizingOptions);
      await attachmentModified(resizingOptions, cropOptions, selectedMediaType);
    },
    [attachment, cropOptions, selectedMediaType],
  );

  async function attachmentModified(
    resizingOptions: ResizingOptions,
    cropOptions: CropOptions,
    mediaType: MediaType,
  ) {
    const { width, height } = getCropDimensions(attachment.dimensions, cropOptions);
    const { offsetX, offsetY } = getCropOffsets(attachment.dimensions, cropOptions);

    const attachmentVariantService: IAttachmentTransformService = DI_CONTAINER.get(
      ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
    );

    setModifiedAttachment(
      await attachmentVariantService.transform(attachment, {
        size: resizingOptions.newDimensions,
        crop: { width, height, offsetX, offsetY, type: DimensionType.Absolute },
        mediaType,
      }),
    );
  }

  return {
    modifiedAttachment,
    mediaTypeChanged,
    resized,
    selectedMediaType,
    userResizingOptions,
    setCropOptions,
    cropOptions,
  };
}
