import { call, all } from 'typed-redux-saga';
import { addAssetTagsInitEffects } from './effects/add-asset-tags-init';
import { addAssetTagsCompleteEffects } from './effects/add-asset-tags-complete';
import { contentLibraryAddAssetTagsInitEffects } from './effects/content-library-add-asset-tags-init';
import { contentLibraryAddAssetTagsCompleteEffects } from './effects/content-library-add-asset-tags-complete';

export function* uploadTagsEffects() {
  yield all([
    call(addAssetTagsInitEffects),
    call(contentLibraryAddAssetTagsInitEffects),
    call(contentLibraryAddAssetTagsCompleteEffects),
    call(addAssetTagsCompleteEffects),
  ]);
}
