import { searchAssetsEffects as localSearchAssetsEffects } from './search-assets/state/effects';
import { selectAttachmentEffects as localSelectAttachmentEffects } from './select-attachment/state/effects';
import { selectAssetEffects as localSelectAssetEffects } from './select-assets/state/effects';
import { entitiesSelectors, searchAssetsSelectors, selectAttachmentSelectors } from './selectors';

export function* searchAssetsEffects() {
  yield localSearchAssetsEffects(searchAssetsSelectors, entitiesSelectors);
}

export function* selectAttachmentEffects() {
  yield localSelectAttachmentEffects(
    entitiesSelectors,
    selectAttachmentSelectors,
    searchAssetsSelectors,
  );
}

export function* selectAssetEffects() {
  yield localSelectAssetEffects();
}
