import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from 'reselect';
import { AssetsListState } from './reducer';

export const selectShowAttachments = (state: AssetsListState) => state?.showAttachments;
export const selectPagination = (state: AssetsListState) => state?.pagination;

export const selectCurrentPageHasAssets = (state: AssetsListState) => {
  return state.pagination?.pageAssetIds[state.pagination?.currentPage]?.length > 0;
};

export const selectHasNextPage = (state: AssetsListState) => {
  switch (state?.pagination?.paginationType) {
    case 'countless':
      return state.pagination?.nextPage;
    case 'cursor':
      return state.pagination?.moreExist;
    default:
      return state.pagination?.currentPage < state.pagination?.totalPages;
  }
};

export const selectTotalAssetsCount = (state: AssetsListState) => {
  return state.pagination?.totalCount || 0;
};

export const selectHasNoResultsAfterSearch = (state: AssetsListState) => {
  return state.pagination?.totalCount === 0; // totalCount is null if no search has been performed
};

export const selectPaginationType = (state: AssetsListState) => {
  return state.pagination?.paginationType;
};

export type AssetsListStateSelector = StateSelector<AssetsListState>;

export function createAssetsListSelectors(stateSelector: AssetsListStateSelector) {
  return {
    showAttachments: createSelector(stateSelector, selectShowAttachments),
    pagination: createSelector(stateSelector, selectPagination),
    currentPageHasAssets: createSelector(stateSelector, selectCurrentPageHasAssets),
    assetCount: createSelector(stateSelector, selectTotalAssetsCount),
    hasNoResultsAfterSearch: createSelector(stateSelector, selectHasNoResultsAfterSearch),
    hasNextPage: createSelector(stateSelector, selectHasNextPage),
    paginationType: createSelector(stateSelector, selectPaginationType),
  };
}
