import { createResolvableComponent } from '@integration-frontends/common/ui';
import { ThumbnailStatusRibbonProps } from './common';
import { ThumbnailStatusRibbonBrandfolder } from './thumbnail-status-ribbon-brandfolder';

export const THUMBNAIL_STATUS_RIBBON_COMPONENT = 'THUMBNAIL_STATUS_RIBBON_COMPONENT';
const ResolvableThumbnailStatusRibbon = createResolvableComponent<ThumbnailStatusRibbonProps>(
  THUMBNAIL_STATUS_RIBBON_COMPONENT,
  ThumbnailStatusRibbonBrandfolder,
);

export const ThumbnailStatusRibbon = ResolvableThumbnailStatusRibbon;
