import {
  AttachmentFieldParameter,
  AttachmentIncludeParameter,
  Options,
} from '@integration-frontends/common/brandfolder-api';
import { Container } from '../../container';
import { Attachment } from './attachment';
import { ListOptions, PagedResults } from '../asset';

export const ATTACHMENT_REPO_TOKEN = 'ATTACHMENT_REPO';

export interface AttachmentListResultSet {
  attachments: Attachment[];
}

export interface AttachmentPagedResults extends PagedResults<AttachmentListResultSet> {
  attachments: Attachment[];
}

export interface IAttachmentRepo {
  getAssetAttachment: (assetId: string) => Promise<Attachment>;
  getAttachment: (attachmentId: string) => Promise<Attachment>;
  listAssetAttachments: (assetId: string) => Promise<Attachment[]>;
  listContainerAttachments: (
    container: Container,
    options: ListOptions | Options<AttachmentFieldParameter, AttachmentIncludeParameter>,
  ) => Promise<PagedResults<AttachmentListResultSet>>;
}
