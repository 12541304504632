import { Attachment } from '@integration-frontends/integration/core/model';
import { AttachmentSelectorFeature } from '@integration-frontends/integration/ui';
import { useFeatures } from '@integration-frontends/integration/ui/attachment-selector/common/use-features';
import React from 'react';
import { useAttachmentTransforms } from '../../../common/attachment-transforms/use-attachment-transforms';
import { PlacementOptions } from '../../asset-details-page/placement-options';

export interface PlacementOptionsContainerProps {
  attachment: Attachment;
}

export function PlacementOptionsContainer({ attachment }: PlacementOptionsContainerProps) {
  const { modifiedAttachment, mediaTypeChanged, resized, selectedMediaType, userResizingOptions } =
    useAttachmentTransforms({ attachment });
  const { hasFeature } = useFeatures();

  if (!attachment) return null;
  return (
    <PlacementOptions
      attachment={modifiedAttachment || attachment}
      onMediaTypeChange={!hasFeature(AttachmentSelectorFeature.CopyMode) && mediaTypeChanged}
      onResize={resized}
      selectedMediaType={selectedMediaType}
      userResizingOptions={userResizingOptions}
    />
  );
}
