import { createReducer } from '@reduxjs/toolkit';
import { bulkDownloadAssetsInit, downloadError } from './actions';

export interface DownloadAssetsState {
  loading: boolean;
}

export const downloadAssetsInitialState: DownloadAssetsState = {
  loading: false,
};

export const DOWNLOAD_ASSETS_REDUCER = createReducer(downloadAssetsInitialState, (builder) =>
  builder
    .addCase(bulkDownloadAssetsInit, (state) => {
      state.loading = true;
    })
    .addCase(downloadError, (state) => {
      state.loading = false;
    }),
);
