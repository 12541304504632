import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ATTACHMENT_REPO_TOKEN,
  IAttachmentRepo,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { call, put, takeEvery } from 'redux-saga/effects';
import { initiatePlaceAttachment, placeAttachment } from './actions';

const initiatePlaceAttachmentHandler = function* (
  action: ReturnType<typeof initiatePlaceAttachment>,
) {
  const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);
  const attachment = action.payload.attachment;

  if (attachment.type === ResourceType.ATTACHMENT_VARIANT) {
    // if attachment was modified (resizing/cropping) then we can assume that it has all the required data e.g. CDN url
    yield put(placeAttachment({ attachment }));
  } else {
    // Attachment data may be incomplete depending on which API calls were used previously (due to performance concerns),
    // we may therefore need to fetch the data here to ensure its integrity
    const remoteAttachment = yield call(attachmentRepo.getAttachment, action.payload.attachment.id);
    yield put(placeAttachment({ attachment: remoteAttachment }));
  }
};

export function* placeAttachmentEffects() {
  yield takeEvery(initiatePlaceAttachment, initiatePlaceAttachmentHandler);
}
