import { enterToClick } from '@integration-frontends/common/ui';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import { Asset, Attachment } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from '../../../../features';
import { useAttachmentSelectorNavigation } from '../../../navigation';
import { ThumbnailOverlay } from '../../thumbnail-overlay';
import { AssetThumbnailBase } from './asset-thumbnail-base';
import { AssetThumbnailMenu } from './asset-thumbnail-menu';
import { AssetThumbnailProps } from './asset-thumbnail-props';
import { Trans } from '@lingui/macro';

export function NavigatableAssetThumbnail(props: AssetThumbnailProps) {
  const featureService: IAttachmentSelectorFeaturesService = useDependencyInjector(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );

  return (
    <div
      className={classNames('attachment-thumbnail', {
        'with-padding': featureService.hasFeature(AttachmentSelectorFeature.DragAndDrop),
      })}
    >
      <AssetThumbnailBase {...props} overlay={<Overlay {...props} />} />
    </div>
  );
}

interface OverlayProps {
  asset: Asset;
  attachments: Attachment[];
  containerId: string;
}

function Overlay({ asset, attachments, containerId }: OverlayProps) {
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const navigation = useAttachmentSelectorNavigation();
  const [menuFocus, setMenuFocus] = useState(false);

  const action = (
    <a
      tabIndex={0}
      data-testid="asset-view-details-btn"
      className="attachment-adjust-margin button-neutral w-max"
      onClick={() => navigation.goToAssetDetails(asset.id, selectedContainer.id)}
      onKeyDown={enterToClick}
      onFocus={() => setMenuFocus(true)}
      onBlur={() => setMenuFocus(false)}
    >
      <Trans>View Details</Trans>
    </a>
  );

  return (
    <ThumbnailOverlay
      className={`asset-overlay ${menuFocus && 'show'}`}
      centerElement={action}
      topElement={
        <AssetThumbnailMenu
          asset={asset}
          containerId={containerId}
          onFocus={() => setMenuFocus(true)}
          onBlur={() => setMenuFocus(false)}
        />
      }
    />
  );
}
