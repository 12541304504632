import { DI_CONTAINER } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import {
  Asset,
  Attachment,
  IMediaTypeSupportService,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
} from '@integration-frontends/integration/core/model';
import React from 'react';
import { AttachmentDragAndDrop } from '../../../common/attachment-drag-and-drop';
import { AttachmentThumbnailImage } from '../../../common/attachment-thumbnail-image';
import {
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from '../../../features';
import { Thumbnail } from '../../common';

export interface AttachmentPreviewProps {
  asset: Asset;
  attachment: Attachment;
}

export function AttachmentPreview({ asset, attachment }: AttachmentPreviewProps) {
  const mediaTypeSupportService = DI_CONTAINER.get<IMediaTypeSupportService>(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );
  const featureService: IAttachmentSelectorFeaturesService = useDependencyInjector(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );

  const thumbnail = (
    <Thumbnail id="attachment-preview-image" className="w-full" style={{ height: 220 }}>
      <AttachmentThumbnailImage
        // we want to enable native image drag and drop if the feature is not enabled
        draggable={!featureService.hasFeature(AttachmentSelectorFeature.DragAndDrop)}
        alt="Preview of focused thumbnail"
        className="p-lg"
        attachment={attachment}
        useFullSize={true}
      />
    </Thumbnail>
  );

  return featureService.hasFeature(AttachmentSelectorFeature.DragAndDrop) &&
    mediaTypeSupportService.isSupported(attachment?.mediaType) &&
    asset.availability !== 'view_only' ? (
    <AttachmentDragAndDrop attachment={attachment}>{thumbnail}</AttachmentDragAndDrop>
  ) : (
    thumbnail
  );
}
