import { ComponentResolver, COMPONENT_RESOLVER_TOKEN, DI_CONTAINER } from '@integration-frontends/core';
import React from 'react';

export const ATTACHMENT_PLACEMENT_DETAILS_COMPONENT_KEY = 'ATTACHMENT_PLACEMENT_DETAILS_COMPONENT';

interface AttachmentPlacementDetailsProps {
  attachmentID: string,
  assetID: string,
  icon?: boolean,
}

export function DefaultAttachmentPlacementDetailsComponent({ attachmentID, assetID, icon = false }: AttachmentPlacementDetailsProps) {
  return <div></div>;
}

export function AttachmentPlacementDetailsContainer(props: AttachmentPlacementDetailsProps) {
  const componentResolver: ComponentResolver = DI_CONTAINER.get(COMPONENT_RESOLVER_TOKEN);
  const AttachmentPlacementDetails = componentResolver.getComponent(ATTACHMENT_PLACEMENT_DETAILS_COMPONENT_KEY) || DefaultAttachmentPlacementDetailsComponent;

  return <AttachmentPlacementDetails {...props} />
}
