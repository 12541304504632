import { Container } from '../container';

export const USER_SETTINGS_REPO_TOKEN = Symbol.for('USER_SETTINGS_REPO');

export interface UploadPreferences {
  organizationId: string;
  containerId: string;
  sectionId: string;
  success: boolean;
}

export interface UploadPreferencesResults {
  organizationId: string;
  brandfolderId: string;
  collectionId: string;
  sectionId: string;
  success: boolean;
}

export interface IUserSettingsRepo {
  setAssetUploadPreference: (
    organizationId: string,
    container: Container,
    sectionId: string,
  ) => Promise<UploadPreferencesResults>;
  getAssetUploadPreference: () => Promise<UploadPreferencesResults>;
}
