import { IconChevronDown, IconChevronRight } from '@integration-frontends/common/ui';
import { Organization } from '@integration-frontends/integration/core/model';
import React from 'react';
import './organization-section-banner.scss';
import classNames from 'classnames';
import { t } from '@lingui/macro';

interface OrganizationSectionBannerProps {
  brandfolderCount: number;
  collapsed: boolean;
  organization: Organization;
  multipleOrganizations: boolean;
}

export function OrganizationSectionBanner({
  brandfolderCount,
  collapsed = false,
  organization,
  multipleOrganizations,
}: OrganizationSectionBannerProps) {
  const translatedBrandfolderLabel = t`Brandfolder`;
  const translatedBrandfoldersLabel = t`Brandfolders`;

  return (
    <div
      className={classNames(
        'organization-section-banner text-primary gap-md rounded-xl font-medium',
        { collapsed: collapsed, listItemDelimiter: !multipleOrganizations },
      )}
      data-cy="organization-section-banner"
    >
      <div className="flex flex-grow items-center overflow-auto">
        <span className="flex h-full flex-grow items-center overflow-auto">
          <h2 className="org-name truncate font-medium">
            {organization.name}
            {collapsed &&
              ` (${brandfolderCount} ${
                multipleOrganizations ? translatedBrandfolderLabel : translatedBrandfoldersLabel
              })`}
          </h2>
        </span>
      </div>

      {multipleOrganizations ? (
        <span className="px-xs">{collapsed ? <IconChevronRight /> : <IconChevronDown />}</span>
      ) : (
        <span className="whitespace-nowrap">
          {brandfolderCount}{' '}
          {brandfolderCount > 1 ? translatedBrandfoldersLabel : translatedBrandfolderLabel}
        </span>
      )}
    </div>
  );
}
