export * from './new-notification';


import { all, fork } from 'redux-saga/effects';
import { newNotificationEffects } from './new-notification';

export function* notificationEffects() {
  yield all([
    fork(newNotificationEffects),
  ]);
}
