import {
  BFButton,
  ButtonSize,
  ButtonType,
  IconArrowLeft,
  IconArrowLeftWhite,
  IconArrowRight,
  IconArrowRightWhite,
  IconSize,
  Loader,
} from '@integration-frontends/common/ui';
import {
  AssetsListPagination,
  getPageCount,
  hasNextPage,
  hasPreviousPage,
} from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import { Asset, Section } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes, useRef } from 'react';
import { ThumbnailList } from '../../../common/thumbnails';
import { AttachmentSelectorFeature } from '../../../features';
import { FlexSpacer } from '../../common/layout/flex-spacer';
import { AssetThumbnails } from '../asset-thumbnails';
import { AssetsListHeaderContainer } from '../assets-list-header';
import { CurrentPageDisplay } from '../current-page-display';
import { useFeatures } from '../use-features';
import './assets-list.scss';
import { Trans, t } from '@lingui/macro';

export interface AssetListProps {
  assets: Asset[];
  containerId: string;
  className: string;
  title?: string;
  pagination: AssetsListPagination;
  section: Section;
  showAttachments: boolean;
  onNextPageClick: () => void;
  onPreviousPageClick: () => void;
}

export function AssetsList({
  assets,
  containerId,
  className,
  title,
  pagination,
  section,
  showAttachments,
  onNextPageClick,
  onPreviousPageClick,
}: AssetListProps & HTMLAttributes<HTMLDivElement>) {
  const ref = useRef(null);
  const { hasFeature } = useFeatures();

  function nextPageClicked() {
    onNextPageClick();
  }

  function previousPageClicked() {
    onPreviousPageClick();
  }

  return assets.length ? (
    <div
      data-testid="section-assets-list"
      className={classNames('assets-list', className)}
      ref={ref}
      data-cy={`section-${title}`}
    >
      <AssetsListHeaderContainer title={title} pagination={pagination} section={section} />

      {pagination.loading ? (
        <Loader />
      ) : (
        <ThumbnailList responsive={hasFeature(AttachmentSelectorFeature.Responsive)}>
          {assets.map((asset) => (
            <AssetThumbnails
              key={asset.id}
              asset={asset}
              containerId={containerId}
              responsive={hasFeature(AttachmentSelectorFeature.Responsive)}
              showAttachments={showAttachments}
            />
          ))}
        </ThumbnailList>
      )}

      {getPageCount(pagination) > 1 && (
        <div className="pagination-controls flex items-center justify-between">
          <BFButton
            data-testid={'previous-page'}
            disabled={!hasPreviousPage(pagination)}
            className="gap-xs flex items-center justify-center"
            buttonType={!hasPreviousPage(pagination) ? ButtonType.Disabled : ButtonType.Primary}
            size={ButtonSize.Small}
            onClick={previousPageClicked}
          >
            <FlexSpacer size={'xs'} className="items-center">
              {!hasPreviousPage(pagination) ? (
                <IconArrowLeft iconSize={IconSize.Small} />
              ) : (
                <IconArrowLeftWhite iconSize={IconSize.Small} />
              )}
              <span aria-label={`${title} ` + t`Previous page`}>
                <Trans>Previous</Trans>
              </span>
            </FlexSpacer>
          </BFButton>

          <CurrentPageDisplay pagination={pagination} />

          <BFButton
            data-testid="next-page"
            disabled={!hasNextPage(pagination)}
            className="gap-xs flex items-center justify-center"
            buttonType={!hasNextPage(pagination) ? ButtonType.Disabled : ButtonType.Primary}
            size={ButtonSize.Small}
            onClick={nextPageClicked}
          >
            <FlexSpacer size={'xs'} className="items-center">
              <span aria-label={`${title} ` + t`Next page`}>
                <Trans>Next</Trans>
              </span>
              {!hasNextPage(pagination) ? (
                <IconArrowRight iconSize={IconSize.Small} />
              ) : (
                <IconArrowRightWhite iconSize={IconSize.Small} />
              )}
            </FlexSpacer>
          </BFButton>
        </div>
      )}
    </div>
  ) : null;
}
