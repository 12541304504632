import {
  contentLibraryUploadAssetsSelectors,
  entitiesSelectors,
} from '@integration-frontends/integration/core/application';
import { Asset, ResourceType } from '@integration-frontends/integration/core/model';
import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { AssetThumbnailImage } from './asset-thumbnail-image';

export type ConnectedAssetThumbnailProps = {
  assetId: string;
  checkeredBackground?: boolean;
  displayActualThumbnailSize?: boolean;
  backgroundColor?: string;
} & HTMLAttributes<HTMLDivElement>;

export function ConnectedAssetThumbnail({
  assetId,
  checkeredBackground,
  displayActualThumbnailSize,
  backgroundColor,
  ...restProps
}: ConnectedAssetThumbnailProps) {
  const asset = useSelector((state) => entitiesSelectors.asset.selectById(state, assetId));
  const isFileProcessing = useSelector(
    contentLibraryUploadAssetsSelectors.isFileProcessing(asset.id),
  );

  if (asset.type === ResourceType.TEXT) return <TextAssetThumbnail asset={asset} {...restProps} />;
  return (
    <AssetThumbnailImage
      asset={asset}
      backgroundColor={backgroundColor}
      checkeredBackground={checkeredBackground}
      displayActualThumbnailSize={displayActualThumbnailSize}
      isFileProcessing={isFileProcessing}
      {...restProps}
    />
  );
}

type TextAssetThumbanilProps = {
  asset: Asset;
} & HTMLAttributes<HTMLDivElement>;

function TextAssetThumbnail({ asset, ...restProps }: TextAssetThumbanilProps) {
  return (
    <div className="relative h-full w-full" {...restProps}>
      <h4 className="text-sm">{asset.name}</h4>
      <div
        className="overflow-hidden overflow-ellipsis whitespace-nowrap"
        dangerouslySetInnerHTML={{ __html: asset.description }}
      />
    </div>
  );
}
