import { DI_CONTAINER } from '@integration-frontends/core';
import {
  ASSET_REPO_TOKEN,
  ATTACHMENT_REPO_TOKEN,
  IAssetRepo,
  IAttachmentRepo,
} from '@integration-frontends/integration/core/model';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  assetCustomFieldValueEntityActions,
  assetEntityActions,
  assetTagEntityActions,
  attachmentEntityActions,
} from '../../../common/entities-state';
import { assetDetailsDataLoaded, assetDetailsPageEntered } from '../actions';
import { pageLoaded, pageLoadError } from '@integration-frontends/common/app';

const handler = function* (action: ReturnType<typeof assetDetailsPageEntered>) {
  try {
    const attachmentRepo: IAttachmentRepo = DI_CONTAINER.get(ATTACHMENT_REPO_TOKEN);
    const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);

    const [asset, assetDetails, attachments] = yield all([
      call(assetRepo.fetchAsset, action.payload.assetId),
      call(assetRepo.getAssetDetails, action.payload.assetId),
      call(attachmentRepo.listAssetAttachments, action.payload.assetId),
    ]);

    yield put(assetEntityActions.assetsReceived([asset]));
    yield put(assetTagEntityActions.assetTagsReceived(assetDetails.tags));
    yield put(
      assetCustomFieldValueEntityActions.assetCustomFieldValuesReceived(
        assetDetails.customFieldValues,
      ),
    );
    yield put(attachmentEntityActions.attachmentDetailsReceived(attachments));
    yield put(assetDetailsDataLoaded());
    yield put(pageLoaded());
  } catch (e) {
    yield put(pageLoadError({ error: e }));
  }
};

export function* enteredAssetDetailsEffects() {
  yield takeEvery(assetDetailsPageEntered, handler);
}
