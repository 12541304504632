import { BFInputProps, BFSearchInput } from '@integration-frontends/common/ui';
import { setQueryFilter } from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import React, { HTMLAttributes, InputHTMLAttributes } from 'react';
import { useSearch } from '../search/use-search';
import { searchAssetsSelectors } from '@integration-frontends/integration/core/application';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

export type SearchInputProps = BFInputProps & {
  inputHeight?: number;
};

export function SearchInput({
  className,
  ...props
}: SearchInputProps & HTMLAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>) {
  const { search, searchParams: searchParamsFromURL } = useSearch();
  const searchParams = useSelector(searchAssetsSelectors.searchParams) || searchParamsFromURL || {};

  const searchQueryChangedHandler = (query: string) => {
    search(setQueryFilter(query || null, searchParams));
  };

  return (
    <div className={classNames(className)}>
      <div className="border-bf-light-gray hover:border-bf-dark-gray relative rounded border transition ease-in-out">
        <BFSearchInput
          data-testid="assets-search-input"
          className={classNames(
            'search-ph-md h-5 w-full border-none transition duration-300 ease-in-out',
          )}
          placeholder={t`Search`}
          onChange={(e) => searchQueryChangedHandler(e.target.value)}
          value={searchParams?.query || ''}
          style={{ height: props.inputHeight || 38 }}
          {...props}
        />
      </div>
    </div>
  );
}
