import { DI_CONTAINER } from '@integration-frontends/core';
import {
  assetEntityActions,
  attachmentEntityActions,
} from '@integration-frontends/integration/core/application/common';
import {
  decrementCurrentPage,
  incrementCurrentPage,
  AssetsListPagination,
} from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import {
  ASSET_REPO_TOKEN,
  AssetsListResultSet,
  Container,
  IAssetRepo,
  ListOptions,
  PagedResults,
  SearchParameters,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { searchAssetsActions } from '../actions';
import { SearchAssetsSelectors } from '../selectors';

function* previousPageHandler(
  action: ReturnType<typeof searchAssetsActions.previousSectionPage>,
  selectors: SearchAssetsSelectors,
) {
  const { container, sectionId } = action.payload;
  const searchParams = yield select(selectors.searchParams);
  const paginationState = yield select(
    selectors.listBySectionSelectors.sectionPagination(sectionId),
  );
  const sortOptions = yield select(selectors.sortOptions);
  yield handler(
    sectionId,
    container,
    decrementCurrentPage(paginationState),
    searchParams,
    sortOptions,
  );
}

function* nextPageHandler(
  action: ReturnType<typeof searchAssetsActions.nextSectionPage>,
  selectors: SearchAssetsSelectors,
) {
  const { container, sectionId } = action.payload;
  const searchParams = yield select(selectors.searchParams);
  const paginationState = yield select(
    selectors.listBySectionSelectors.sectionPagination(sectionId),
  );
  const sortOptions = yield select(selectors.sortOptions);
  yield handler(
    sectionId,
    container,
    incrementCurrentPage(paginationState),
    searchParams,
    sortOptions,
  );
}

function* handler(
  sectionId: string,
  container: Container,
  paginationState: AssetsListPagination,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
) {
  const pagedResults = yield call(
    fetchSectionAssets,
    container,
    sectionId,
    paginationState,
    searchParams,
    sortOptions,
  );

  yield put(searchAssetsActions.sectionPageLoaded({ sectionId, results: pagedResults }));
  yield put(assetEntityActions.assetsReceived(pagedResults.data.assets));
  yield put(attachmentEntityActions.attachmentsReceived(pagedResults.data.attachments));
}

function fetchSectionAssets(
  container: Container,
  sectionId: string,
  pagination: AssetsListPagination,
  searchParams: SearchParameters,
  sortOptions: SortOptions,
): Promise<PagedResults<AssetsListResultSet>> {
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const options: ListOptions = {
    searchParams,
    pagination: {
      page: pagination.currentPage,
      perPage: pagination.pageSize,
      nextPage: pagination.nextPage,
    },
    sort: sortOptions,
  };

  return assetRepo.listContainerSectionAssets(container, sectionId, options);
}

export function* paginationSectionEffects(searchAssetsSelectors: SearchAssetsSelectors) {
  yield all([
    takeEvery(searchAssetsActions.nextSectionPage, function* (action) {
      yield nextPageHandler(action, searchAssetsSelectors);
    }),
    takeEvery(searchAssetsActions.previousSectionPage, function* (action) {
      yield previousPageHandler(action, searchAssetsSelectors);
    }),
  ]);
}
