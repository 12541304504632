import {
  PrioritizedCustomFieldsFiltersSelectComponent,
  PrioritizedCustomFieldsFiltersSelectComponentProps,
} from './prioritized-custom-fields-filters-select-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  containerSelectors,
  loadCustomFieldValues,
} from '@integration-frontends/integration/core/application';
import React, { FunctionComponent, useEffect } from 'react';

export type PrioritizedCustomFieldsFiltersSelectContainerProps = Pick<
  PrioritizedCustomFieldsFiltersSelectComponentProps,
  'customField' | 'searchParams' | 'onChange'
> & {
  Component?: FunctionComponent<PrioritizedCustomFieldsFiltersSelectComponentProps>;
};

export function PrioritizedCustomFieldsFiltersSelectContainer({
  Component = PrioritizedCustomFieldsFiltersSelectComponent,
  ...restProps
}: PrioritizedCustomFieldsFiltersSelectContainerProps) {
  const { customField } = restProps;
  const customFieldValues = useSelector(
    containerSelectors.customFieldValues.selectValuesByCustomFieldId(customField.id),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCustomFieldValues({ customFieldId: customField?.id }));
  }, []);

  return <Component {...restProps} customFieldValues={customFieldValues} />;
}
