import { StateSelector } from '@integration-frontends/common/utils/redux';
import { DEFAULT_SELECTED_ASSETS_LIMIT, SelectAssetState } from './reducer';
import { createSelector } from 'reselect';

const selectAssetIds = (state: SelectAssetState) => state.selectedAssetIds;
const selectAssetLoading = (state: SelectAssetState) => state.loading;
const selectSelectedAssetsCount = (state: SelectAssetState) => state.selectedAssetIds.length;

export type SelectAssetStateSelector = StateSelector<SelectAssetState>;

export function createSelectAssetSelector(stateSelector: SelectAssetStateSelector) {
  const baseSelectors = {
    assetIds: createSelector(stateSelector, selectAssetIds),
    loading: createSelector(stateSelector, selectAssetLoading),
    selectedAssetsCount: createSelector(stateSelector, selectSelectedAssetsCount),
  };

  return {
    ...baseSelectors,
    isAssetSelected: (assetId: string) =>
      createSelector(baseSelectors.assetIds, (selectedAssetIds) => {
        return selectedAssetIds.includes(assetId);
      }),
    enforceSelectedAssetsLimit: createSelector(baseSelectors.assetIds, (selectedAssetIds) => {
      return selectedAssetIds.length >= DEFAULT_SELECTED_ASSETS_LIMIT;
    }),
  };
}

export type SelectAssetSelectors = ReturnType<typeof createSelectAssetSelector>;
