import { Disclosure } from '@headlessui/react';
import { BFIconButton, IconArrowRight, IconLogo } from '@integration-frontends/common/ui';
import {
  Brandfolder,
  Collection,
  Container,
  hasCardImage,
} from '@integration-frontends/integration/core/model';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { HTMLAttributes } from 'react';
import { CollectionsToggle, ContainersCompactList } from '../../common';
import { useAttachmentSelectorNavigation } from '../../navigation';
import './brandfolder-list-item.scss';
import { Plural } from '@lingui/macro';

export function BrandfolderListItem({
  brandfolder,
  brandfolderCount,
  collections,
  onClick,
  className,
  ...props
}: {
  brandfolder: Brandfolder;
  collections: Collection[];
  brandfolderCount: number;
} & HTMLAttributes<any>) {
  const navigation = useAttachmentSelectorNavigation();

  const ContainerInfo = ({
    container,
    ...props
  }: { container: Container } & HTMLAttributes<any>) => (
    <div className="flex w-full flex-col overflow-hidden" {...props}>
      <span className="container-name truncate font-medium">{container.name}</span>
      {container.hasAccess && (
        <span className="container-asset-count text-base">
          <Plural value={container.assetCount} one="# asset" other="# assets"></Plural>
        </span>
      )}
    </div>
  );

  const BrandfolderImage = ({ brandfolder }: { brandfolder: Brandfolder }) => {
    return (
      <div className="container-thumbnail">
        {hasCardImage(brandfolder) ? (
          <img src={brandfolder.cardImage} alt={brandfolder.name} />
        ) : (
          <IconLogo />
        )}
      </div>
    );
  };

  const ArrowButton = () => {
    return (
      <BFIconButton
        data-testid="select-brandfolder-btn"
        style={{ width: 30, height: 30 }}
        iconElement={<IconArrowRight className="icon-arrow-right" style={{ width: 16 }} />}
        transparentBackground={true}
        ariaLabel="select brandfolder button"
      />
    );
  };

  function onCollectionSelected(collection: Collection) {
    navigation.goToShowPage(collection.id);
  }

  const CollectionsList = ({
    brandfolder,
    collections,
  }: {
    brandfolder: Brandfolder;
    collections: Collection[];
  }) => {
    return (
      <Disclosure defaultOpen={!brandfolder.hasAccess}>
        {({ open }) => (
          <div
            className="w-full"
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <Disclosure.Button
              data-cy="collections-toggle"
              className="collections-toggle-button flex items-center"
            >
              <CollectionsToggle collectionCount={collections.length} open={open} primary={false} />
            </Disclosure.Button>

            <Disclosure.Panel className="w-full">
              <ContainersCompactList
                containers={collections}
                containerSelect={onCollectionSelected}
                listItemDelimiter={false}
              />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    );
  };

  return (
    <li
      data-testid={brandfolder.type}
      data-cy={brandfolder.type}
      data-is-focusable={true}
      className={classNames('brandfolder-list-item', 'text-primary cursor-pointer', className)}
      onClick={brandfolder.hasAccess ? onClick : noop}
      {...props}
    >
      <BrandfolderImage brandfolder={brandfolder} />

      <div className="flex flex-grow flex-col items-start overflow-hidden">
        <ContainerInfo container={brandfolder} />
        {collections.length > 0 && (
          <CollectionsList brandfolder={brandfolder} collections={collections} />
        )}
      </div>

      {brandfolderCount > 0 && brandfolder.hasAccess && <ArrowButton />}
    </li>
  );
}
