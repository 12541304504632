export enum ResourceBaseSortableProperty {
  Name = 'name',
  Position = 'position',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Popularity = 'popularity',
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface SortOptions<T = ResourceBaseSortableProperty> {
  direction: SortDirection;
  field: T;
}

export const DEFAULT_SORT_OPTIONS: SortOptions = {
  direction: SortDirection.Asc,
  field: ResourceBaseSortableProperty.Position,
};

export function setSortOptionsField(
  field: ResourceBaseSortableProperty,
  options: SortOptions,
): SortOptions {
  return { ...options, field };
}
