import React from 'react';
import { SelectAttachment } from '@integration-frontends/apps/common/ui';

export function SelectAttachmentPage() {
  return (
    <div className="flex flex-col">
      <SelectAttachment />
    </div>
  );
}
