import { takeEvery } from 'redux-saga/effects';
import { put, select } from 'typed-redux-saga';
import { searchAssetsSelectors } from '../../../selectors';
import { baseClearSearch, baseSearch } from '../actions';

function* handler(action: ReturnType<typeof baseClearSearch>) {
  // mainain sort options when clearing search
  const sortOptions = yield select(searchAssetsSelectors.sortOptions);

  if (action.payload.executeSearch) {
    yield put(baseSearch({ searchParams: null, sortOptions }));
  }
}

export function* baseClearSearchEffects() {
  yield takeEvery(baseClearSearch, handler);
}
