import { AuthError } from './error';
import { UserPermissions } from '@smartsheet/content-library-state-service';

export interface Identity {
  token: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface AuthenticationResponse {
  jwt: { token: string };
  workspace: {
    user: UserPermissions;
  }
}

// API key for now, but will most likely add other methods in the near future
export type Credentials = string | unknown;

export const AUTHENTICATE_SERVICE_TOKEN = 'AUTHENTICATE_SERVICE_TOKEN';
export interface IAuthenticateService<T = Credentials> {
  authenticate: (credentials: T) => Promise<Identity | AuthError>;
  isAuthenticated: (token: string) => Promise<boolean>;
  refreshAuthentication?: () => Promise<AuthenticationResponse>;
}
