import { createSelector } from 'reselect';
import { default as reducer } from './reducer';
import * as selectors from './selectors';
import { PermissionLevels } from '@integration-frontends/integration/core/model';

export * from './app-actions';
export { clear, setIdentity } from './actions';

export function getAuthRootReducer() {
  return {
    auth: reducer,
  };
}

const selectAuthState = ({ auth }) => auth;
export const selectIdentity = createSelector(selectAuthState, selectors.selectIdentity);
export const selectRefreshToken = createSelector(selectAuthState, selectors.selectRefreshToken);
export const selectRefreshLock = createSelector(selectAuthState, selectors.selectRefreshLock);
export const selectRefreshLockTimestamp = createSelector(
  selectAuthState,
  selectors.selectRefreshLockTimestamp,
);
export const selectError = createSelector(selectAuthState, selectors.selectError);
export const selectPermissionLevel = createSelector(
  selectAuthState,
  selectors.selectPermissionLevel,
);
// this implementation will change when we start directly getting SMAR permission levels
export const selectHasEditPermission = createSelector(
  selectPermissionLevel,
  (permissionLevel) => permissionLevel && permissionLevel !== PermissionLevels.Guest,
);
