import { takeEvery } from 'redux-saga/effects';
import { put } from 'typed-redux-saga';
import { bulkRemoveAssetsFromCollectionsSuccess } from '../actions';
import { deselectAllAssets } from '../../../select-assets';
import { assetEntityActions } from '../../../common';

function* handler(action: ReturnType<typeof bulkRemoveAssetsFromCollectionsSuccess>) {
  const { assetIds } = action.payload;
  yield put(assetEntityActions.assetsRemoved(assetIds));
  yield put(deselectAllAssets());
}

export function* bulkRemoveAssetsFromCollectionsSucessEffects() {
  yield takeEvery(bulkRemoveAssetsFromCollectionsSuccess, handler);
}
