import { put, takeEvery } from 'redux-saga/effects';
import { uploadAssetFailure } from '../actions';
import { createNotification, NotificationType } from '@integration-frontends/common/notifications';

const handler = function* (action: ReturnType<typeof uploadAssetFailure>) {
  const { error } = action.payload;
  new Error(error);
  yield put(
    createNotification({ message: error, location: 'topLevel', type: NotificationType.Error }),
  );
};

export function* uploadFailureEffects() {
  yield takeEvery(uploadAssetFailure, handler);
}
