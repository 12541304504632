import { DI_CONTAINER } from '@integration-frontends/core';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import {
  Asset,
  Attachment,
  IMediaTypeSupportService,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
} from '@integration-frontends/integration/core/model';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AttachmentDragAndDrop } from '../../../../common/attachment-drag-and-drop';
import { AttachmentOptionsMenu } from '../../attachment-options-menu';
import { ThumbnailOverlay } from '../../thumbnail-overlay';
import { AttachmentThumbnailBase } from './attachment-thumbnail-base';
import { AttachmentThumbnailProps } from './attachment-thumbnail-props';

export function DraggableAttachmentThumbnail(props: AttachmentThumbnailProps) {
  const mediaTypeSupportService = DI_CONTAINER.get<IMediaTypeSupportService>(
    MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  );

  const attachmentThumbnailElement = (
    <AttachmentThumbnailBase {...props} overlay={<Overlay {...props} />} />
  );

  return mediaTypeSupportService.isSupported(props.attachment?.mediaType) ? (
    <AttachmentDragAndDrop
      attachment={props.attachment}
      style={{ padding: 15, margin: '0 -10px 0 -10px' }}
    >
      {attachmentThumbnailElement}
    </AttachmentDragAndDrop>
  ) : (
    <div style={{ padding: 15, margin: '-10px 0 -10px -10px' }}>{attachmentThumbnailElement}</div>
  );
}

interface OverlayProps {
  asset: Asset;
  attachment: Attachment;
  containerId: string;
}

function Overlay({ asset, attachment, containerId }: OverlayProps) {
  const container = useSelector((state) => containerSelectors.selectById(state, containerId));
  const [menuFocus, setMenuFocus] = useState(false);

  return (
    <ThumbnailOverlay
      className={`asset-overlay ${menuFocus && 'show'}`}
      topElement={
        <AttachmentOptionsMenu
          asset={asset}
          attachment={attachment}
          container={container}
          onFocus={() => setMenuFocus(true)}
          onBlur={() => setMenuFocus(false)}
        />
      }
    />
  );
}
