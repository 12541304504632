import {
  DatePickerDate,
  DateRangePickerInput,
  DateRangePickerInputProps,
} from '@smartsheet/lodestar-core';
import React, { FC } from 'react';
import { BFStandardDaterangePickerProps } from './bf-standard-daterange-picker';

export const BFStandardDaterangePickerSmartsheet: FC<BFStandardDaterangePickerProps> = (props) => {
  const { endDateValue, locale, onSelection, startDateValue, onDatePickerCleared } = props;

  const handleOnChange: DateRangePickerInputProps['onChange'] = (start, end) => {
    const startDate = mapDatePickerDateToDate(start);
    const endDate = mapDatePickerDateToDate(end);

    if (startDate && endDate) {
      onSelection(mapDatePickerDateToDate(start), mapDatePickerDateToDate(end));
    } else {
      onDatePickerCleared();
    }
  };

  return (
    <DateRangePickerInput
      endValue={mapDateToDatePickerDate(endDateValue)}
      format="mm/dd/yyyy"
      locale={locale || 'en'}
      startValue={mapDateToDatePickerDate(startDateValue)}
      onChange={handleOnChange}
    />
  );
};

function mapDateToDatePickerDate(date: Date): DatePickerDate {
  if (!date) return null;

  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  };
}

function mapDatePickerDateToDate(datePickerDate: DatePickerDate): Date {
  if (!datePickerDate) return null;
  return new Date(datePickerDate.year, datePickerDate.month, datePickerDate.day);
}
