import React, { HTMLAttributes } from 'react';

export function ThumbnailContainer({
  responsive,
  ...restProps
}: HTMLAttributes<HTMLElement> & { responsive: boolean }) {
  return <div style={{ width: !responsive && 150 }} {...restProps} />;
}

export function ThumbnailLabelContainer(props: HTMLAttributes<HTMLElement>) {
  return <div className="flex flex-col text-primary leading-normal" {...props} />;
}
