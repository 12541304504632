import {
  SearchAssetsSelectors,
  SelectAttachmentSelectors,
} from '@integration-frontends/integration/core/application';
import { all, fork } from 'redux-saga/effects';
import { enteredShowPageEffects } from './entered-show-page';
import { showPageSearchEffects } from './search';

export function* showPageEffects(
  selectAttachmentSelectors: SelectAttachmentSelectors,
  searchAssetsSelectors: SearchAssetsSelectors,
) {
  yield all([
    fork(enteredShowPageEffects, selectAttachmentSelectors, searchAssetsSelectors),
    fork(showPageSearchEffects),
  ]);
}
