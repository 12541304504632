import { ContainerCustomField } from '@integration-frontends/integration/core/model';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const containerCustomFieldValueAdapter = createEntityAdapter<any>();

export const containerCustomFieldValueSlice = createSlice({
  initialState: containerCustomFieldValueAdapter.getInitialState(),
  name: 'containerCustomFieldValue',
  reducers: {
    containerCustomFieldValueReceived: containerCustomFieldValueAdapter.upsertOne,
    containerCustomFieldValuesReceived: containerCustomFieldValueAdapter.addMany,
  },
});
