import { StateSelector } from '@integration-frontends/common/utils/redux';
import { createSelector } from 'reselect';
import { containerCustomFieldValueAdapter } from '../entities';
import { EntityState } from '@reduxjs/toolkit';
import { ContainerCustomFieldValue } from '@integration-frontends/integration/core/model';

export function createContainerCustomFieldValueSelectors(
  stateSelector: StateSelector<EntityState<ContainerCustomFieldValue>>,
) {
  const baseSelectors = containerCustomFieldValueAdapter.getSelectors(stateSelector);
  return {
    ...baseSelectors,
    selectValuesByCustomFieldId: (customFieldKey) =>
      createSelector(baseSelectors.selectAll, (customFieldValues) =>
        customFieldValues.filter((value) => value.customFieldKey === customFieldKey),
      ),
  };
}
