import { all, call } from 'redux-saga/effects';
import { selectAllAssetsInitEffects } from './select-all-assets-init';
import { selectAllAssetsErrorEffects } from './select-all-assets-error';
import { shiftSelectAssetsEffects } from './shift-select-assets';
import { selectAllAssetsSuccessEffects } from './select-all-assets-success';
import { toggleAssetSelectionEffects } from './toggle-asset-selection';
import { showMaxSelectableAssetsToastEffects } from './show-max-selectable-assets-toast';

export function* selectAssetEffects() {
  yield all([
    call(toggleAssetSelectionEffects),
    call(selectAllAssetsInitEffects),
    call(selectAllAssetsSuccessEffects),
    call(selectAllAssetsErrorEffects),
    call(shiftSelectAssetsEffects),
    call(showMaxSelectableAssetsToastEffects),
  ]);
}
