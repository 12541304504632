import {
  Asset,
  Attachment,
  Container,
  IDownloadService,
  ResourceType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';

// TODO: get this from environment
const BRANDFOLDER_URL = 'https://brandfolder.com';

@injectable()
export class DownloadService implements IDownloadService {
  getAssetDownloadLink = (container: Container, asset: Asset) => {
    return `${BRANDFOLDER_URL}/${container.slug.toLowerCase()}/zip/asset/${asset.id}?resource_key=${
      container.id
    }&resource_type=${container.type === ResourceType.BRANDFOLDER ? 'Brandfolder' : 'Collection'}`;
  };

  getAttachmentDownloadLink(container: Container, attachment: Attachment): string {
    return `${BRANDFOLDER_URL}/${container.name.toLowerCase()}/attachments/${
      attachment.id
    }?dl=true`;
  }
}
