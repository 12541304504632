import { getResponseListDataOrDefault } from '@integration-frontends/common/brandfolder-api';
import { IOrganizationRepo, Organization } from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { map } from 'ramda';
import { RepoBase } from './repo-base';
import { mapOrganization } from './model';

@injectable()
export class OrganizationRepo extends RepoBase implements IOrganizationRepo {
  listOrganizations = async (): Promise<Organization[]> => {
    return this.brandfolderApi
      .listOrganizations(await this.getApiKey())
      .then((response) => getResponseListDataOrDefault(response))
      .then(map(mapOrganization));
  };
}
