import styled from '@emotion/styled';
import { useTranslate } from '@integration-frontends/core/translation/use-translate';
import React, { FunctionComponent } from 'react';
import { RibbonContainerBase, ThumbnailStatusRibbonProps } from './common';

export const ThumbnailStatusRibbonBrandfolder: FunctionComponent<ThumbnailStatusRibbonProps> = ({
  asset,
}) => {
  const { translate } = useTranslate();

  switch (asset?.availability) {
    case 'expired':
      return <RibbonContainer>{translate('Expired')}</RibbonContainer>;
    case 'unpublished':
      return <RibbonContainer>{translate('Draft')}</RibbonContainer>;
    case 'pending_approval':
      return <RibbonContainer>{translate('Pending')}</RibbonContainer>;
    default:
      return null;
  }
};

const RibbonContainer = styled(RibbonContainerBase)`
  color: #f8fafc;
  border-bottom-color: #f64e63;
`;
