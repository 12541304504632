import { Attachment, AttachmentTransforms, AttachmentVariant } from './attachment';

export const ATTACHMENT_TRANSFORM_SERVICE_TOKEN = 'ATTACHMENT_TRANSFORM_SERVICE';
export interface IAttachmentTransformService {
  transform(
    attachment: Attachment | AttachmentVariant,
    transforms: AttachmentTransforms,
  ): Promise<AttachmentVariant>;
  supportsCropping(attachment: Attachment | AttachmentVariant): boolean;
}
