import { createSelector } from 'reselect';
import { UploadTagsState } from './reducer';

const selectLoading = (state: UploadTagsState) => state.loading;
const selectTagsCount = (state: UploadTagsState) => state.tagsCount;
const selectTotalAssets = (state: UploadTagsState) => state.totalAssets;
const selectUploadTagsComplete = (state: UploadTagsState) => state.uploadTagsComplete;
const selectUploadTagsErrors = (state: UploadTagsState) => state.uploadStatusErrorCount;
const selectUploadTagsSuccess = (state: UploadTagsState) => state.uploadTagsSuccesCount;

export function createUploadTagsSelectors(stateSelector: (rootState) => UploadTagsState) {
  const baseSelectors = {
    loading: createSelector(stateSelector, selectLoading),
    tagsCount: createSelector(stateSelector, selectTagsCount),
    totalAssets: createSelector(stateSelector, selectTotalAssets),
    uploaTagsComplete: createSelector(stateSelector, selectUploadTagsComplete),
    selectUploadTagsErrors: createSelector(stateSelector, selectUploadTagsErrors),
    selectUploadTagsSuccess: createSelector(stateSelector, selectUploadTagsSuccess),
  };

  return {
    ...baseSelectors,
  };
}
