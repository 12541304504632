import { Environment } from '@integration-frontends/apps/common/utils/environment-types';

export const environment = {
  production: true,
  bfBaseUrl: 'https://integration-contentsync.brandfolder-svc.com/api/v1/contentsync/bf-api-proxy',
  bfWebsiteBaseUrl: 'https://brandfolder.com',
  bfStaticAssetsBaseUrl: 'https://static-fast.brandfolder.com',
  segmentWriteKey: '3D2vwp7cRNdeQQAR0agYzuEH3oAi3XWA',
  environment: Environment.Stage,
};
