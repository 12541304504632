import { useComponentResolver } from '@integration-frontends/core';
import { useDependencyInjector } from '@integration-frontends/core/di-container/react';
import React, { FunctionComponent } from 'react';
import {
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  AttachmentSelectorFeature,
  IAttachmentSelectorFeaturesService,
} from '../../../../features';
import { AttachmentThumbnail } from '../attachment-thumbnail/attachment-thumbnail';
import { AssetThumbnailProps } from './asset-thumbnail-props';
import { DraggableAssetThumbnail } from './draggable-asset-thumbnail';
import { NavigatableAssetThumbnail } from './navigatable-asset-thumbnail';

export const ASSET_THUMBNAIL_COMPONENT_TOKEN = 'ASSET_THUMBNAIL_COMPONENT_TOKEN';

export function AssetThumbnail(props: AssetThumbnailProps) {
  const featureService: IAttachmentSelectorFeaturesService = useDependencyInjector(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );
  const AssetThumbnailComponent: FunctionComponent<AssetThumbnailProps> =
    useComponentResolver(ASSET_THUMBNAIL_COMPONENT_TOKEN) ||
    (featureService.hasFeature(AttachmentSelectorFeature.DragAndDrop) &&
      props.asset?.availability !== 'view_only')
      ? DraggableAssetThumbnail
      : NavigatableAssetThumbnail;

  return props.asset.attachmentCount > 1 ? (
    <AssetThumbnailComponent {...props} />
  ) : (
    <AttachmentThumbnail
      asset={props.asset}
      attachment={props.attachments[0]}
      containerId={props.containerId}
      responsive={props.responsive}
      useAssetName={true}
    />
  );
}
