import { DI_CONTAINER } from '@integration-frontends/core';
import { ASSET_REPO_TOKEN, IAssetRepo } from '@integration-frontends/integration/core/model';
import { call, put, select } from 'redux-saga/effects';
import { takeEvery } from 'typed-redux-saga';
import {
  addAssetTagFailure,
  addAssetTagSuccess,
  addAssetTagsComplete,
  addAssetTagsInit,
} from '../actions';
import { AddAssetTagsResponseData } from '@integration-frontends/common/brandfolder-api';
import { uploadTagsSelectors } from '../../../selectors';

export function* handler(action: ReturnType<typeof addAssetTagsInit>) {
  const { assetIds, tags, onAddAssetTagError } = action.payload;
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const totalAssets = yield select(uploadTagsSelectors.totalAssets);
  let addTagsCount = 0;

  while (addTagsCount < totalAssets) {
    const assetId = assetIds[addTagsCount];
    try {
      const response: AddAssetTagsResponseData = yield call(assetRepo.addAssetTags, assetId, tags);
      const result = response?.data?.find((assetTag) => {
        return tags.includes(assetTag.attributes.name);
      });
      result
        ? yield put(addAssetTagSuccess({ assetIds: [assetId] }))
        : yield put(addAssetTagFailure({ assetId }));
    } catch (e) {
      if (e.length && e.some((err) => err.detail === 'Tag limit exceeded')) {
        onAddAssetTagError('createTagsExceedLength');
      } else {
        onAddAssetTagError('createTags');
      }
    }

    addTagsCount++;

    if (addTagsCount >= totalAssets) {
      break;
    }
  }

  yield put(addAssetTagsComplete());
}

export function* addAssetTagsInitEffects() {
  yield takeEvery(addAssetTagsInit, handler);
}
