import { MenuPopout } from '../../../../libs/integration/ui/src';
import { PanelHeader } from '../../../../libs/common/ui/src';
import * as React from 'react';

export interface HeaderProps {
  assetId?: string;
  containerId?: string;
}

export function Header({ assetId, containerId }: HeaderProps) {
  const menuAction = <MenuPopout assetId={assetId} containerId={containerId} />;

  return <PanelHeader actions={[menuAction]} />;
}
