import { BFLabel } from '@integration-frontends/common/ui';
import React, { HTMLAttributes } from 'react';
import { FlexSpacer } from './flex-spacer';

export interface FormControlProps {
  label?: string;
  labelFor?: string;
}

export function FormControl({
  children,
  label,
  labelFor,
  ...restProps
}: FormControlProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <FlexSpacer direction={'vertical'} size={'xs'} {...restProps}>
      {label && <BFLabel htmlFor={labelFor}>{label}</BFLabel>}
      {children}
    </FlexSpacer>
  );
}
